import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, TextField, IconButton, Tooltip, Select, MenuItem, FormControl, InputLabel, Button, Grid, TablePagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';
import { API_BASE_URL } from '../services/apiService';

const AssignTaskOrderTable = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [emailSelection, setEmailSelection] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Sorting state
  const [sortBy, setSortBy] = useState('Case#'); // Default sorting by Case#
  const [sortDirection, setSortDirection] = useState('asc'); // Default sorting in ascending order

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const onMenuClick = (menuItem) => {
    if (menuItem === 'Dashboard') {
      navigate('/admin');
    } else if (menuItem === 'Assign Task Order') {
      navigate('/assign-task-order-table');
    }
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/tasks`);
        setTasks(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setError('Error fetching tasks. Please try again later.');
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.trim());
  };

  const handlePriorityChange = (event) => {
    setPriorityFilter(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleEmailChange = (event, index) => {
    setEmailSelection({
      ...emailSelection,
      [index]: event.target.value,
    });
  };

  const handleSendEmail = async (task, index) => {
    const selectedEmailType = emailSelection[index];

    if (selectedEmailType) {
      const emailData = {
        email: task.Email,
        caseNumber: task["Case#"],
        msnNumber: task["MSN#"],
        firstName: task["Customer Name"].split(' ')[0],
        description: task["Problem Description"],
        contactNumbers: task["Contact Number"],
        address: task["Customer Address"],
        emailType: selectedEmailType,
      };

      try {
        const response = await axios.post(`${API_BASE_URL}/api/send-email`, emailData);
        console.log(response.data);
        alert('Email sent successfully!');
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to send email. Please try again.');
      }
    }
  };

  const filteredTasks = tasks.filter((task) => {
    const toLowerCaseSafe = (value) => {
      return String(value).toLowerCase();
    };
  
    const lowerCaseSearchTerm = String(searchTerm).toLowerCase();  // Convert search term to lowercase
  
    const isMatching =
      toLowerCaseSafe(task["Customer Name"]).includes(lowerCaseSearchTerm) ||
      toLowerCaseSafe(task["Case#"]).includes(lowerCaseSearchTerm) ||
      toLowerCaseSafe(task["MSN#"]).includes(lowerCaseSearchTerm) ||
      toLowerCaseSafe(task["WO#"]).includes(lowerCaseSearchTerm);
  
    return isMatching;
  });

  // Sorting function
  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortBy(column);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  // Apply sorting
  const sortedTasks = [...filteredTasks].sort((a, b) => {
    if (sortDirection === 'asc') {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    } else {
      return a[sortBy] < b[sortBy] ? 1 : -1;
    }
  });

  // Pagination handling
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 2, backgroundColor: '#f0f4f8', minHeight: '100vh', overflowX: 'hidden', paddingTop: '80px' }}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} onMenuClick={onMenuClick} />
      <Box component="main" sx={{ p: 3, marginLeft: drawerOpen ? '240px' : '60px', transition: 'margin 0.3s ease', overflowX: 'hidden' }}>
        <Grid container spacing={2} alignItems="center" mb={2}>
     

          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Search Case/WO/MSN..."
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{
                    '& .MuiOutlinedInput-root': { borderRadius: '25px', backgroundColor: '#fff', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', fontFamily: 'Open Sans, sans-serif' },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setSearchTerm(searchTerm)}>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>

              {/* <Grid item xs={6} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>Priority</InputLabel>
                  <Select
                    value={priorityFilter}
                    onChange={handlePriorityChange}
                    label="Priority"
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="Premier">Premier</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}

              {/* <Grid item xs={6} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={handleStatusChange}
                    label="Status"
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="Accepted">Accepted</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Fixed">Fixed</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : sortedTasks.length === 0 ? (
          <Typography>No tasks found.</Typography>
        ) : (
          <TableContainer component={Paper} sx={{
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)', borderRadius: '15px', backgroundColor: '#fff', maxHeight: 'calc(100vh - 180px)', overflowY: 'auto',
          }}>
            <Table stickyHeader sx={{ minWidth: 750 }}>
              <TableHead sx={{ background: '#f8f9fa', color: '#333', fontFamily: 'Roboto, sans-serif', fontSize: '14px' }}>
                <TableRow>
                  <TableCell onClick={() => handleSort('Case#')} sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}>
                    Case {sortBy === 'Case#' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('WO#')} sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}>
                    WO {sortBy === 'WO#' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell onClick={() => handleSort('MSN#')} sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px', cursor: 'pointer' }}>
                    MSN {sortBy === 'MSN#' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>City</TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Customer Name</TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Customer Number</TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Customer Address</TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Shipped/Delivered</TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Cust Req App Date</TableCell>

                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Part Status</TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Service Completion Date</TableCell>


                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Email</TableCell>
                  <TableCell sx={{ color: '#333', fontWeight: 'bold', fontSize: '14px' }}>Send</TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ fontFamily: 'Open Sans, sans-serif', fontSize: '14px' }}>
                {sortedTasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((task, index) => (
                  <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f1f9ff' }, transition: 'background-color 0.3s ease' }}>
                  
                    <TableCell>{task["Case#"]}</TableCell>
                    <TableCell>{task["WO#"]}</TableCell>
                    <TableCell>{task["MSN#"]}</TableCell>
                    <TableCell>{task.City}</TableCell>
                    <TableCell>{task["Customer Name"]}</TableCell>
                    <TableCell>{task["Contact Number"]}</TableCell>
                    <TableCell>{task["Customer Address"]}</TableCell>
                    <TableCell>{new Date(task["Part Shipped/Delivered Date"]).toLocaleString()}</TableCell>
                    <TableCell>{new Date(task["Customer Requested Appointment Date"]).toLocaleString()}</TableCell>

                    <TableCell>{task["Part Status"]}</TableCell>
                    <TableCell>{task["Status"]}</TableCell>
                    <TableCell>{new Date(task['Service Completion Date']).toLocaleString()}</TableCell>


                    <TableCell>
                      <FormControl fullWidth size="small">
                        <Select
                          value={emailSelection[index] || ''}
                          onChange={(e) => handleEmailChange(e, index)}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          <MenuItem value="Follow up E-mail">Follow up E-mail</MenuItem>
                          <MenuItem value="Gentle Reminder">Gentle Reminder</MenuItem>
                          <MenuItem value="Send New Request">Send New Request</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleSendEmail(task, index)}
                        variant="contained"
                        sx={{
                          backgroundColor: '#28a745', color: 'white', borderRadius: '5px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                          '&:hover': { backgroundColor: '#218838' },
                        }}
                      >
                        Send
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filteredTasks.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};

export default AssignTaskOrderTable;
