import React, { useState } from 'react';
import { Box, Button, TextField, Typography,Collapse, MenuItem, Snackbar, FormControl,Link, InputLabel, Select, Grid, Paper, IconButton, InputAdornment, Chip} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // to handle API requests
import logo from '../assets/logo.png'; // Replace with your logo path
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { API_BASE_URL } from '../services/apiService';// Import Material UI Icons
import DeleteIcon from '@mui/icons-material/Delete';
import OutlinedInput from "@mui/material/OutlinedInput";
 
 
const Register = () => {
  const navigate = useNavigate();
  const [userType, setUserType] = useState(''); // Store selected user type
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword:'',
    contactNumber: '',
    firstname:'',
    surename: '',
    "PUDO ID": '',
    postalCode: '',// Single For Admin
    postalCodes: [], // Multiple inputs for Field Engineer
    proficiency: [],
    state:'',
    city: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState('');//Add error
 
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
 
    const capitalizedFields = ['firstname', 'surename', 'state', 'city'];
    const newValue = capitalizedFields.includes(name) ? capitalizeFirstLetter(value) : value;
 
    setFormData({ ...formData, [name]: newValue });
    setError("");
  };
 
  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
     // Reset postal code fields when role changes
     setFormData({
      ...formData,
      postalCode: "",
      postalCodes: [],
    });
  };
 
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
 
  // Handle proficiency selection and split to an array if necessary
  const handleProficiencyChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      proficiency: value,  // Directly set the value (an array)
    });
  };
  // Handle removing a proficiency from the selection
  const handleRemoveProficiency = (proficiencyToRemove) => {
    setFormData({
      ...formData,
      proficiency: formData.proficiency.filter((proficiency) => proficiency !== proficiencyToRemove),
    });
  };
 
  const handlePostalCodeChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      postalCodes: value.split(',').map(code => code.trim()) // Split by commas and trim spaces
    }));
  };
 
  const handleRemovePostalCode = (index) => {
    setFormData((prevData) => {
      const updatedPostalCodes = prevData.postalCodes.filter((_, i) => i !== index);
      return { ...prevData, postalCodes: updatedPostalCodes };
    });
  };
 
  const handlePudoIdChange = (e) => {
    const { value } = e.target;
 
    console.log("PUDO ID entered:", value); // Log the input value for debugging
 
    // Regular expression to match the 'JT03' format (two letters followed by two digits)
    const pudoIdPattern = /^[A-Z]{2}\d{2}$/;
 
    // List of invalid PUDO IDs
    const invalidPudoIds = ["1234", "0000", "4321"];
 
    // Validation checks
    if (!pudoIdPattern.test(value)) {
      console.error("Invalid PUDO ID format. Must be two letters followed by two digits.");
    } else if (invalidPudoIds.includes(value)) {
      console.error(`Invalid PUDO ID: '${value}' is not allowed.`);
    } else {
      console.log(`Valid PUDO ID: '${value}'`);
    }
 
    // Update form data with the correct field name
    setFormData((prevFormData) => ({
      ...prevFormData,
      PUDO_ID: value.trim(), // Correct key: PUDO_ID (not "PUDO ID")
    }));
  };
 
  const getStyles = () => ({
    backgroundColor: '#fafafa',
    borderRadius: '12px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E0E0', // Light border color
      },
      '&:hover fieldset': {
        borderColor: '#2ebf91',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2ebf91', // Green border when focused
      },
    },
    '& .MuiInputLabel-root': {
      fontWeight: 100,
      color: '#808080',
    },
  });
   
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Basic validation
    const requiredFields = [
      "username",
      "email",
      "password",
      "confirmPassword",
      "firstname",
      "surename",
      "contactNumber",
      "state",
      "city",
      "PUDO_ID"
    ];  
 
    for (let field of requiredFields) {
      if (!formData[field]) {
        setError(`All fields are required!`);
        return;
      }
    }
 
     // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setError("Invalid email format");
      return;
    }
 
    // Contact number validation
    const contactNumber = formData.contactNumber.trim();
 
    // Indian contact number validation
    const contactNumberRegex = /^[1-9]{1}[0-9]{9}$/;
    const sequentialAsc = '0123456789';
    const sequentialDesc = '9876543210';
    const repetitiveNumbers = /^(.)\1{9}$/;  // checks for repeated numbers like 1111111111
   
    if (!contactNumberRegex.test(contactNumber)) {
      setError("Invalid contact number. It must be a 10-digit number starting from 1-9.");
      return;
    }
    // Disallow sequential or repetitive numbers
    if (
      contactNumber === sequentialAsc ||
      contactNumber === sequentialDesc ||
      repetitiveNumbers.test(contactNumber)
    ) {
      setError("Sequential or repetitive contact numbers are not allowed.");
      return;
    }
    if (!/^\d{10}$/.test(contactNumber)) {
      setError("Contact number must be exactly 10 digits with no letters or special characters.");
      return;
    }
 
    // Postal code validation (exactly 6 digits)
    const postalCode = formData.postalCode.trim();
    const postalCodeRegex = /^[0-9]{6}$/;
    if (!postalCodeRegex.test(postalCode) || postalCode === '000000') {
       setError({message: 'Postal code must be exactly 6 digits.'});
    }
 
    // Password validation
    if(formData.password !== formData.confirmPassword){
      setError("Passwords do not match!");
      return;
    }
    if (formData.password.length < 8) {
      setError("Password must be at least 8 characters!");
      return;
    }
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharPattern.test(formData.password)) {
      setError("Password must contain at least one special character!");
      return;
    }
    // Check for at least one alphanumeric or special character (any allowed character)
    const anyCharPattern = /[A-Za-z0-9!@#$%^&*(),.?":{}|<>]/;
    if (!anyCharPattern.test(formData.password)) {
      setError("Password must contain at least one character!");
      return;
    }
    const password = formData.password;
    const isValidPassword = /^(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,12}$/;
    if (!isValidPassword.test(password)) {
      setError("Password must be 8-12 characters long, contain at least one number, and one special character!");
      return;
    }
 
    // First name validation (only letters)
    const namePattern = /^[A-Za-z]+$/;
    if (!namePattern.test(formData.firstname)) {
      setError("First name must contain only letters.");
      return;
    }
 
    // Surname validation (only letters)
    if (!namePattern.test(formData.surename)) {
      setError("Last name must contain only letters.");
      return;
    }
 
    // State validation (only letters and spaces)
    const statePattern = /^[A-Za-z\s]+$/;
    if (!statePattern.test(formData.state)) {
      setError("State must contain only letters.");
      return;
    }
 
    // Validate city (only letters and spaces)
    const cityPattern = /^[A-Za-z\s]+$/;
    if (!cityPattern.test(formData.city)) {
      setError("City must contain only letters.");
      return;
    }
 
     // Postal Code Validation
     if (userType === "admin") {
      if (!/^\d{6}$/.test(formData.postalCode)) {
        setError("Postal code must be exactly 6 digits for Admin.");
        return;
      }
    } else if (userType === "field-engineer") {
      if (!formData.postalCodes.length || !formData.postalCodes.every((code) => /^\d{6}$/.test(code))) {
        setError(
          "All postal codes for Field Engineer must be exactly 6 digits."
        );
        return;
      }
    }
 
    const handleSubmit = async (e) => {
      e.preventDefault();
    }
 
    setError("");
 
    const payload = {
      ...formData,
      role: userType,
      // Send array for validation if Field Engineer, else string for admin
      postalCode: userType === "admin" ? formData.postalCode : formData.postalCodes,
    };
   
    try {
      const response = await axios.post(`${API_BASE_URL}/api/register`, payload);
   
      if (response.status === 201 && response.data?.message === "User registered successfully") {
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          navigate("/login");
        }, 2000);
      } else {
        setError(response.data?.message || "Registration failed");
      }
    } catch (error) {
      console.error("Registration Failed:", error.response ? error.response.data : error.message);
   
      if (error.response) {
        const { field, message } = error.response.data;
   
        switch (field) {
          case "email":
            setError("The email you entered already exists.");
            break;
          case "contactNumber":
            setError("The contact number you entered already exists.");
            break;
          default:
            setError(message || "An error occurred during registration.");
            break;
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
};
 
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  minHeight: "100vh", p: 4, bgcolor:'#f0f2f5'}}>
      <Paper elevation={6} sx={{ padding: '35px', borderRadius: '15px', width: '100%', maxWidth: '550px',bgcolor: '#ffffff' }}>
        <Box sx={{ mb: .5, textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: '60px', height: '60px' }} />
        </Box>
        <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>Register</Typography>
 
        {error && <Typography sx={{ color: 'red',fontWeight:"300", textAlign: 'center', mt: 3, mb:2 }}>{error}</Typography>}
 
        <Grid container spacing={2.6}>
          <Grid item xs={12} sm={6}>
          <TextField
            label="Username"
            name="username"
            variant="outlined"
            size="small"
            fullWidth
            value={formData.username}
            onChange={handleInputChange}
            sx={getStyles()}
          />
          </Grid>
 
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.email}
              onChange={handleInputChange}
              sx={getStyles()}
            />
          </Grid>
 
          <Grid item xs={12} sm={6}>
            <TextField
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              size="small"
              fullWidth
              value={formData.password}
              onChange={handleInputChange}
              sx={getStyles()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
 
          <Grid item xs={12} sm={6}>
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              size="small"
              fullWidth
              sx={getStyles()}
              value={formData.confirmPassword}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
 
          <Grid item xs={12}>
            <TextField
              select
              label="Register as"
              value={userType}
              size="small"
              fullWidth
              onChange={handleUserTypeChange}
              sx={getStyles()}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="field-engineer">Field Engineer</MenuItem>
            </TextField>
          </Grid>
        </Grid>
 
        {/* Conditional rendering for Admin fields */}
        <Box sx={{ width: '100%', mt: 2 }}>
          <Collapse in={userType === 'admin'} timeout="auto" unmountOnExit>
            <Grid container spacing={2.5}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstname"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.firstname}
                onChange={handleInputChange}
                sx={getStyles()}
              />
            </Grid>
 
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="surename"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.surename}
                onChange={handleInputChange}
                sx={getStyles()}
              />
            </Grid>
 
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact Number"
                name="contactNumber"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.contactNumber}
                onChange={handleInputChange}
                inputProps={{ maxLength: 10, pattern: "\\d*" }}
                sx={getStyles()}
              />
            </Grid>
 
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                name="state"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.state}
                onChange={handleInputChange}
                sx={getStyles()}
              />
            </Grid>
 
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="city"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.city}
                onChange={handleInputChange}
                sx={getStyles()}
              />
            </Grid>
 
            <Grid item xs={12} sm={6}>
              <TextField
                label="Postal Code"
                name="postalCode"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.postalCode}
                onChange={handleInputChange}
                inputProps={{ maxLength: 6, minLength: 6 }}
                sx={getStyles()}
              />
            </Grid>
 
            <Grid item xs={12} sm={12}>
              <TextField
                label="PUDO ID"
                name="PUDO_ID" // Match this with the formData key (no spaces)
                variant="outlined"
                size="small"
                fullWidth
                value={formData.PUDO_ID} // Reference the correct key here
                onChange={handlePudoIdChange}
                inputProps={{ maxLength: 4, minLength: 4 }}
                sx={getStyles()}
              />
            </Grid>
            </Grid>
          </Collapse>
        </Box>
 
        {/* Conditional rendering for Field Engineer fields */}
        <Box sx={{ width: '100%', mt: 2 }}>
          <Collapse in={userType === 'field-engineer'} timeout="auto" unmountOnExit>
            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstname"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formData.firstname}
                  onChange={handleInputChange}
                  sx={getStyles()}
                />
              </Grid>
 
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Surname"
                  name="surename"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formData.surename}
                  onChange={handleInputChange}
                  sx={getStyles()}                 />
              </Grid>
 
              <Grid item xs={12}>
                <TextField
                  label="Contact Number"
                  name="contactNumber"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formData.contactNumber}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 10, pattern: "\\d*" }}
                  sx={getStyles()}                 />
              </Grid>
 
              <Grid item xs={12} sm={6}>
                <TextField
                  label="State"
                  name="state"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formData.state}
                  onChange={handleInputChange}
                  sx={getStyles()}                 />
              </Grid>
 
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="city"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formData.city}
                  onChange={handleInputChange}
                  sx={getStyles()}                 />
              </Grid>
 
              <Grid item xs={12} sm={12}>
                <TextField
                  label="PUDO ID"
                  name="PUDO_ID" // Match this with the formData key (no spaces)
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formData.PUDO_ID} // Reference the correct key here
                  onChange={handlePudoIdChange}
                  inputProps={{ maxLength: 4, minLength: 4 }}
                  sx={getStyles()}
                />
              </Grid>
 
              <Grid item xs={12}>
                <TextField
                  label="Postal Codes"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formData.postalCodes.join(", ")}
                  onChange={handlePostalCodeChange}
                  sx={getStyles()}                 />
              </Grid>
 
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                  Added Postal Codes:
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
                  {formData.postalCodes.map((code, index) => (
                    <Chip
                      key={index}
                      label={code}
                      onDelete={() => handleRemovePostalCode(index)}
                      sx={{
                        bgcolor: '#f5f5f5', color: 'black', fontWeight: '500', borderRadius: '14px', height: '30px', fontSize: '14px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', '&:hover': { bgcolor: '#e0e0e0' }
                      }}
                    />
                  ))}
                </div>
              </Grid>
 
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel>Proficiency</InputLabel>
                  <Select
                    name="proficiency"
                    value={formData.proficiency}
                    onChange={handleProficiencyChange}
                    multiple
                    input={<OutlinedInput label="Proficiency" sx={getStyles()} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() => handleRemoveProficiency(value)}
                            sx={{
                              marginBottom: 1,
                              backgroundColor: '#f5f5f5',
                              borderRadius: '12px',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    <MenuItem value="Elite">Elite</MenuItem>
                    <MenuItem value="Core">Core</MenuItem>
                    <MenuItem value="Advance">Advance</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
 
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleSubmit} variant="contained" sx={{ width: '100%', backgroundColor: '#E52031', '&:hover': { backgroundColor: '#E53010' }, fontWeight: 'bold', p: 1 }}>
            Register
          </Button>
        </Box>
 
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ color: '#000', fontWeight: 400, fontSize: '14px', mr: 1 }}>
            Already have an account?
          </Typography>
          <Link href="/login" variant="body2" sx={{ color: '#2ebf91', fontSize: '14px', fontWeight: 400, textTransform: 'uppercase', '&:hover': { color: '#000' } }}>
            Log In
          </Link>
        </Box>
      </Paper>
 
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Registration successful"
      />
    </Box>
     );
};
 
export default Register;
 
 