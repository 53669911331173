import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Collapse,
  IconButton,
  Divider,
  TablePagination,
  Drawer,
  Card,
  Select,
  MenuItem,
  Button,
  Tooltip,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SideDrawer from '../components/Drawer';
import moment from 'moment';
import { API_BASE_URL } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


// Styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '15px',
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme, isExpired, isFixed }) => ({
  backgroundColor: isExpired ? theme.palette.error.light : isFixed ? theme.palette.success.light : theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const AssignFieldEngineer = () => {
  const [fieldEngineers, setFieldEngineers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openTickets, setOpenTickets] = useState([]);
  const [openTicketsVisible, setOpenTicketsVisible] = useState(false);
  const [openTicketsPage, setOpenTicketsPage] = useState(0);
  const [openTicketsRowsPerPage, setOpenTicketsRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [technicianAssignedCount, setTechnicianAssignedCount] = useState(0);
  const [openTicketsCount, setOpenTicketsCount] = useState(0);
  const [filterValue, setFilterValue] = useState('All');
  const [customDateRange, setCustomDateRange] = useState({ start: '', end: '' });
  const [filterOption, setFilterOption] = useState('All');

  const [filteredTickets, setFilteredTickets] = useState([]);




  const fetchFieldEngineers = async () => {
    try {
      setLoading(true);
      const [engineersRes, tasksRes, openTicketsRes] = await Promise.all([
        axios.get(`${API_BASE_URL}/api/field-engineers`),
        axios.get(`${API_BASE_URL}/api/tickets`),
        axios.get(`${API_BASE_URL}/api/open-tickets`),
      ]);

      const engineers = engineersRes.data || [];
      const tasks = tasksRes.data || [];
      const openTickets = openTicketsRes.data || [];

      const processedEngineers = engineers.map((engineer) => {
        const assignedTickets = tasks.filter((task) => task.assignedEngineerId === engineer._id);
        const ticketCount = assignedTickets.length;

        const StatusStatuses = assignedTickets.map((ticket) => ({
          woNumber: ticket['WO#'],
          Status: ticket['Status'],
          msn: ticket['MSN#'],
          assignDate: ticket['Assign Ticket Date'],
          proficiency: ticket.Proficiency,
          _id: ticket._id,
          isExpired: new Date() > new Date(ticket['Completion Deadline']) && ticket['Status'] !== 'Fixed',
          isFixed: ticket['Status'] === 'Fixed',
        }));

        return { ...engineer, ticketCount, StatusStatuses };
      });

      setFieldEngineers(processedEngineers);

        // Calculate the counts for "Technician Assigned" and "Accepted" tickets
    const technicianAssignedCount = openTickets.filter(
      (ticket) => ticket.Status && ticket.Status.trim().toLowerCase() === 'technician assigned'
    ).length;

    const acceptedTicketsCount = openTickets.filter(
      (ticket) => ticket.Status && ticket.Status.trim().toLowerCase() === 'accepted'
    ).length;
    
    setOpenTicketsCount(acceptedTicketsCount);
    
    setTechnicianAssignedCount(technicianAssignedCount);
    setOpenTicketsCount(acceptedTicketsCount);


      // Filter open tickets where no Tech Assignment is present
      const filteredOpenTickets = openTicketsRes.data.filter((ticket) => !ticket['Tech Assignment']);
      setOpenTickets(filteredOpenTickets);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

    // Handle filter option change
    const handleFilterOptionChange = (e) => {
      setFilterOption(e.target.value);
    };
  
    // Handle custom date range change
    const handleCustomDateChange = (date, name) => {
      setCustomDateRange({ ...customDateRange, [name]: date });
    };
  
    const filterByDate = () => {
      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');
      const lastWeekStart = moment().subtract(1, 'weeks').startOf('week');
      const lastWeekEnd = moment().subtract(1, 'weeks').endOf('week');
      const lastMonthStart = moment().subtract(1, 'months').startOf('month');
      const lastMonthEnd = moment().subtract(1, 'months').endOf('month');
  
      let filtered = [...fieldEngineers];
  
      switch (filterOption) {
        case 'Today':
          filtered = filtered.map((engineer) => ({
            ...engineer,
            StatusStatuses: engineer.StatusStatuses.filter((ticket) => {
              const assignDate = moment(ticket.assignDate);
              return assignDate.isSame(today, 'day');
            }),
          }));
          break;
  
        case 'Yesterday':
          filtered = filtered.map((engineer) => ({
            ...engineer,
            StatusStatuses: engineer.StatusStatuses.filter((ticket) => {
              const assignDate = moment(ticket.assignDate);
              return assignDate.isSame(yesterday, 'day'); // Ensure it's comparing only the date
            }),
          }));
          break;
  
        case 'Last Week':
          filtered = filtered.map((engineer) => ({
            ...engineer,
            StatusStatuses: engineer.StatusStatuses.filter((ticket) => {
              const assignDate = moment(ticket.assignDate);
              return assignDate.isBetween(lastWeekStart, lastWeekEnd, null, '[]');
            }),
          }));
          break;
  
        case 'Last Month':
          filtered = filtered.map((engineer) => ({
            ...engineer,
            StatusStatuses: engineer.StatusStatuses.filter((ticket) => {
              const assignDate = moment(ticket.assignDate);
              return assignDate.isBetween(lastMonthStart, lastMonthEnd, null, '[]');
            }),
          }));
          break;
  
        case 'Custom':
          if (customDateRange.start && customDateRange.end) {
            filtered = filtered.map((engineer) => ({
              ...engineer,
              StatusStatuses: engineer.StatusStatuses.filter((ticket) => {
                const assignDate = moment(ticket.assignDate);
                const startDate = moment(customDateRange.start).startOf('day');
                const endDate = moment(customDateRange.end).endOf('day');
                return assignDate.isBetween(startDate, endDate, null, '[]');
              }),
            }));
          }
          break;
  
        default:
          return filtered;
      }
  
      return filtered;
    };
  
    const filteredData = filterByDate();

  useEffect(() => {
    fetchFieldEngineers();
  }, []);



  useEffect(() => {
    filterByDate();
  }, [filterOption, customDateRange]);

  


    // Function to count "Technician Assigned" tickets on the current page
    const calculateTechnicianAssignedCount = () => {
      const displayedEngineers = fieldEngineers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
      // Count Technician Assigned tickets only for the visible engineers
      const count = displayedEngineers.reduce((total, engineer) => {
        const technicianAssignedTickets = engineer.StatusStatuses.filter(
          (status) => status.Status === 'Technician Assigned'
        );
        return total + technicianAssignedTickets.length;
      }, 0);
  
      setTechnicianAssignedCount(count);
    };
  
    useEffect(() => {
      fetchFieldEngineers();
    }, []);
  

  

    useEffect(() => {
      // Recalculate Technician Assigned count whenever the page changes
      calculateTechnicianAssignedCount();
    }, [page, rowsPerPage, fieldEngineers]); // Add fieldEngineers here to recalculate count on data change
  

  const handleToggleOpenTickets = () => {
    setOpenTicketsVisible(!openTicketsVisible);
  };

  const handleReassign = async (ticketId, newEngineerId) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/tickets/reassign-to-new/${ticketId}`, { newEngineerId });
      if (response.data.message) {
        alert('Ticket successfully reassigned.');
        fetchFieldEngineers(); // Refresh data after reassignment
      }
    } catch (error) {
      console.error('Error reassigning ticket:', error);
      alert('Failed to reassign ticket. Please try again.');
    }
  };
  
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const onMenuClick = (menuItem) => {
    if (menuItem === 'Dashboard') {
      navigate('/admin');
    } else if (menuItem === 'Assign Task Order') {
      navigate('/assign-task-order-table');
    }
  };

  const toggleRowExpansion = (engineerId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [engineerId]: !prev[engineerId],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenTicketsPageChange = (event, newPage) => {
    setOpenTicketsPage(newPage);
  };

  const handleOpenTicketsRowsPerPageChange = (event) => {
    setOpenTicketsRowsPerPage(parseInt(event.target.value, 10));
    setOpenTicketsPage(0);
  };

  const displayedEngineers = fieldEngineers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const displayedOpenTickets = openTickets.slice(
    openTicketsPage * openTicketsRowsPerPage,
    openTicketsPage * openTicketsRowsPerPage + openTicketsRowsPerPage
  );



  

  return (
    <Box sx={{ p: 2, backgroundColor: '#f0f4f8', minHeight: '100vh', overflowX: 'hidden', paddingTop: '80px' }}>
    <Header handleDrawerToggle={handleDrawerToggle} />
    <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} onMenuClick={onMenuClick} />
    <Box component="main" sx={{ p: 3, marginLeft: drawerOpen ? '240px' : '60px', transition: 'margin 0.3s ease', overflowX: 'hidden' }}>


    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
    Total Technician Assigned Tickets: {technicianAssignedCount}
  </Typography>
  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
  Total Open Tickets: {openTicketsCount}
</Typography>



<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Select
          value={filterOption}
          onChange={handleFilterOptionChange}
          displayEmpty
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Today">Today</MenuItem>
          <MenuItem value="Yesterday">Yesterday</MenuItem>
          <MenuItem value="Last Week">Last Week</MenuItem>
          <MenuItem value="Last Month">Last Month</MenuItem>
          <MenuItem value="Custom">Custom Range</MenuItem>
        </Select>

        {filterOption === 'Custom' && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={customDateRange.start}
                onChange={(date) => handleCustomDateChange(date, 'start')}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={customDateRange.end}
                onChange={(date) => handleCustomDateChange(date, 'end')}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button variant="contained" onClick={filterByDate}>
              Apply
            </Button>
          </Box>
        )}
      </Box>


</Box>


        {/* Open Tickets Toggle Card */}
        <Card
          onClick={handleToggleOpenTickets}
          sx={{
            cursor: 'pointer',
            backgroundColor: 'secondary.main',
            color: 'white',
            p: 2,
            mb: 2,
            borderRadius: '8px',
            textAlign: 'center',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'secondary.dark',
            },
          }}
        >
          <Typography variant="h6">Open Tickets</Typography>
          <Typography variant="body2">
            {openTicketsVisible ? 'Click to hide open tickets' : 'Click to view open tickets'}
          </Typography>
        </Card>

        {/* Open Tickets Table */}
        {openTicketsVisible && (
          <StyledTableContainer component={Paper} elevation={3} sx={{ mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>WO#</StyledTableCell>
                  <StyledTableCell>MSN#</StyledTableCell>
                  <StyledTableCell>Customer Postal Code</StyledTableCell>
                  <StyledTableCell>Proficiency</StyledTableCell>
                  <StyledTableCell>City</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>

                  <StyledTableCell>New Ticket Date</StyledTableCell>
                  <StyledTableCell>Reassign</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedOpenTickets.map((ticket) => (
                  <StyledTableRow key={ticket._id}>
                    <TableCell>{ticket['WO#'] || 'N/A'}</TableCell>
                    <TableCell>{ticket['MSN#'] || 'N/A'}</TableCell>
                    <TableCell>{ticket['Customer Postal Code'] || 'N/A'}</TableCell>
                    <TableCell>{ticket.Proficiency || 'N/A'}</TableCell>
                    <TableCell>{ticket.City || 'N/A'}</TableCell>
                    <TableCell>{ticket.Status || 'N/A'}</TableCell>

                    <TableCell>
                      {ticket['New Ticket Date']
                        ? moment(ticket['New Ticket Date'], 'M/D/YYYY, h:mm:ss a').format('MM/DD/YY, hh:mm A')
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                    <Select
    displayEmpty
    defaultValue=""
    onChange={(e) => handleReassign(ticket._id, e.target.value)}
    sx={{ minWidth: 150 }}
  >
    <MenuItem value="" disabled>
      Select Engineer
    </MenuItem>
    {fieldEngineers.map((engineer) => (
      <MenuItem key={engineer._id} value={engineer._id}>
        {engineer.username}
      </MenuItem>
    ))}
  </Select>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={openTickets.length}
              rowsPerPage={openTicketsRowsPerPage}
              page={openTicketsPage}
              onPageChange={handleOpenTicketsPageChange}
              onRowsPerPageChange={handleOpenTicketsRowsPerPageChange}
            />
          </StyledTableContainer>
        )}

        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center' }}>
          Field Engineers and Assigned Tickets
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <StyledTableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Contact Number</StyledTableCell>
                  <StyledTableCell>City</StyledTableCell>
                  <StyledTableCell>Postal Code</StyledTableCell>
                  <StyledTableCell>Proficiency</StyledTableCell>
                  <StyledTableCell>Proficiency</StyledTableCell>

                  <StyledTableCell>Assigned Tickets</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedEngineers.map((engineer) => (
                  <React.Fragment key={engineer._id}>
                    <StyledTableRow>
                      <TableCell>{engineer.username}</TableCell>
                      <TableCell>{engineer.email}</TableCell>
                      <TableCell>{engineer.contactNumber}</TableCell>
                      <TableCell>{engineer.city}</TableCell>
                      <TableCell>{engineer.postalCode}</TableCell>
                      <TableCell>{engineer.proficiency}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => toggleRowExpansion(engineer._id)}>
                          {expandedRows[engineer._id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        {engineer.ticketCount}
                      </TableCell>
                      <TableCell>
                        <Typography>Recommended by Lenovo</Typography>
                      </TableCell>
                    </StyledTableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedRows[engineer._id]} timeout="auto" unmountOnExit>
                          <Box margin={2} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px', p: 2 }}>
                            <Typography variant="h6" color="primary">
                              Status 
                            </Typography>
                            <Divider sx={{ my: 1 }} />
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>WO#</StyledTableCell>
                                  <StyledTableCell>Status</StyledTableCell>
                                  <StyledTableCell>MSN</StyledTableCell>
                                  <StyledTableCell>Assigned Date/Time</StyledTableCell>
                                  <StyledTableCell>Proficiency</StyledTableCell>
                                  <StyledTableCell>Customer Postal code</StyledTableCell>

                                  <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {engineer.StatusStatuses.map((Status, index) => (
                                  <StyledTableRow key={index} isExpired={Status.isExpired} isFixed={Status.isFixed}>
                                    <TableCell>{Status.woNumber}</TableCell>
                                    <TableCell>{Status.Status}</TableCell>
                                    <TableCell>{Status.msn}</TableCell>
                                    <TableCell>
                                      {Status.assignDate
                                        ? moment(Status.assignDate, 'D/M/YYYY, h:mm:ss a').format('DD/MM/YYYY, hh:mm A')
                                        : 'Date not available'}
                                    </TableCell>
                                    <TableCell>{Status.proficiency}</TableCell>
                                    <TableCell>{Status['Customer Postal Code']}</TableCell>
                                    <TableCell>
  <Select
    displayEmpty
    defaultValue=""
    onChange={(e) => handleReassign(Status._id, e.target.value)}
    sx={{ minWidth: 150 }}
  >
    <MenuItem value="" disabled>
      Select Engineer to reassign
    </MenuItem>
    {fieldEngineers.map((engineer) => (
      <MenuItem key={engineer._id} value={engineer._id}>
        {engineer.username}
      </MenuItem>
    ))}
  </Select>
</TableCell>

                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={fieldEngineers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledTableContainer>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default AssignFieldEngineer;
