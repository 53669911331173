import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  TablePagination,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { API_BASE_URL } from '../services/apiService';
import moment from 'moment';
import { motion } from 'framer-motion';
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';

// Styled Components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '15px',
  boxShadow: theme.shadows[5],
  overflow: 'hidden',
  marginTop: theme.spacing(3),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  fontSize: '0.875rem',
  boxShadow: theme.shadows[2],
  '&:hover': {
    transform: 'scale(1.05)',
    transition: 'transform 0.2s',
  },
}));

const SlaManagement = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Fetch SLA tickets
  const fetchSlaTickets = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/api/sla-tickets`);
      setTickets(response.data);
    } catch (error) {
      console.error('Error fetching SLA tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSlaTickets();
  }, []);

  // Calculate time passed since assignment and log it to the console
  const calculateTimePassed = (assignTime) => {
    if (!assignTime) return 'N/A';
  
    const assignedMoment = moment(assignTime, 'DD/MM/YYYY hh:mm A'); // Ensure the format is correct
    const currentMoment = moment();
  
    // Check if the assigned time is in the future
    if (assignedMoment.isAfter(currentMoment)) {
      return 'N/A'; // or any message to handle this case
    }
  
    const duration = moment.duration(currentMoment.diff(assignedMoment));
  
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
  
    return `${days > 0 ? `${days}d ` : ''}${hours}h ${minutes}m ${seconds}s`;
  };
  
  const handleReminder = async (ticketId, reminderType) => {
    try {
      await axios.put(`${API_BASE_URL}/api/tickets/remind/${ticketId}`, { reminderType });
      alert(`Reminder sent: ${reminderType}`);
      fetchSlaTickets();
    } catch (error) {
      console.error('Error sending reminder:', error);
      alert('Failed to send reminder');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedTickets = tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Count SLA Violations
  const criticalViolations = tickets.filter((ticket) => ticket.priority === 'Critical');
  const moderateViolations = tickets.filter((ticket) => ticket.priority === 'Moderate');
  
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Drawer */}
      <SideDrawer
        open={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        sx={{
          width: drawerOpen ? 240 : 0,
          flexShrink: 0,
          transition: 'width 0.3s ease',
        }}
      />

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: drawerOpen ? 240 : 0, // Adjust margin based on drawer state
          transition: 'margin-left 0.3s ease',
          padding: 3,
        }}
      >
        {/* Header */}
        <Header handleDrawerToggle={handleDrawerToggle} title="Admin Dashboard" />

        {/* Page Title */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: 'text.primary',
            textAlign: 'center',
            mb: 4,
          }}
        >
          SLA Management
        </Typography>

        {/* Ticket Table */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : tickets.length > 0 ? (
          <>
            <StyledTableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>WO#</StyledTableCell>
                    <StyledTableCell>Field Engineer</StyledTableCell>
                    <StyledTableCell>Contact</StyledTableCell>
                    <StyledTableCell>City</StyledTableCell>
                    <StyledTableCell>Postal Code</StyledTableCell>
                    <StyledTableCell>Proficiency</StyledTableCell>
                    <StyledTableCell>Assigned Time</StyledTableCell>
                    <StyledTableCell>Time Passed</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedTickets.map((ticket) => (
                    <StyledTableRow key={ticket._id}>
                      <TableCell align="center">{ticket['WO#'] || 'N/A'}</TableCell>
                      <TableCell align="center">{ticket['Tech Assignment'] || 'N/A'}</TableCell>
                      <TableCell align="center">{ticket.assignedEngineerId?.email || 'N/A'}</TableCell>
                      <TableCell align="center">{ticket.assignedEngineer?.city || ticket.City || 'N/A'}</TableCell>
                      <TableCell align="center">{ticket.assignedEngineer?.postalCode || ticket['Customer Postal Code'] || 'N/A'}</TableCell>
                      <TableCell align="center">{ticket.assignedEngineer?.proficiency || ticket.Proficiency || 'N/A'}</TableCell>
                      <TableCell align="center">
  {ticket['Assign Ticket Date']
    ? moment(ticket['Assign Ticket Date'], 'DD/MM/YYYY hh:mm A').format('DD/MM/YYYY hh:mm A')
    : 'N/A'}
</TableCell>

<TableCell align="center">
  {calculateTimePassed(ticket['Assign Ticket Date'])}
</TableCell>                      <TableCell align="center">{ticket['Status'] || 'N/A'}</TableCell>
                      <TableCell align="center">
                        <StyledButton
                          variant="contained"
                          color="primary"
                          onClick={() => handleReminder(ticket._id, 'Gentle Reminder')}
                        >
                          Gentle Reminder
                        </StyledButton>
                        <StyledButton
                          variant="contained"
                          color="warning"
                          onClick={() => handleReminder(ticket._id, 'Second Reminder')}
                        >
                          Second Reminder
                        </StyledButton>
                        <StyledButton
                          variant="contained"
                          color="error"
                          onClick={() => handleReminder(ticket._id, 'Final Reminder')}
                        >
                          Final Reminder
                        </StyledButton>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tickets.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <Typography variant="body1" color="textSecondary" align="center">
            No SLA violations found.
          </Typography>
        )}

        {/* Footer */}
        <Footer />
      </Box>
    </Box>
  );
};

export default SlaManagement;
