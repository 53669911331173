import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  MenuItem,
  Select,
  Toolbar,
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DatePicker,
 
  TablePagination,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx'; // Import xlsx for exporting Excel files
 
// Custom Components
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';
import isBetween from 'dayjs/plugin/isBetween';
 
// Extend dayjs with the isBetween plugin
dayjs.extend(isBetween);
 
// Example usage
const isDateInRange = dayjs('2024-12-06').isBetween('2024-12-01', '2024-12-31');
console.log(isDateInRange); // Output: true
 
// Style constants
const boxShadowStyle = '0 4px 10px rgba(0, 0, 0, 0.1)';
const headerFont = "'Roboto', sans-serif";
const primaryColor = '#1565C0';
 
// Helper functions
const formatDateTime = (dateString) => {
  if (!dateString || isNaN(new Date(dateString).getTime())) {
    return 'N/A';
  }
  const date = new Date(dateString);
  return `${date.toLocaleDateString()}, ${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })}`;
};
 
 
// Helper function to format date in UTC
const formatDateTimeUTC = (dateString) => {
  if (!dateString || isNaN(new Date(dateString).getTime())) {
    return 'N/A';
  }
  const date = new Date(dateString);
 
  return `${date.toLocaleDateString('en-US', {
    timeZone: 'UTC',
  })}, ${date.toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  })} `;
};
 
// Example usage
console.log(formatDateTimeUTC("2024-12-18T21:46:00.000+00:00"));
// Output: "12/18/2024, 09:46:00 PM UTC"
 
 
const calculateECT = (shippedDate, completionDate) => {
  if (
    !shippedDate ||
    !completionDate ||
    isNaN(new Date(shippedDate).getTime()) ||
    isNaN(new Date(completionDate).getTime())
  ) {
    return 'N/A';
  }
 
  // Ensure both dates are in UTC or the same timezone
  const shippedDateUTC = new Date(shippedDate).toISOString(); // Convert to ISO string in UTC
  const completionDateUTC = new Date(completionDate).toISOString(); // Convert to ISO string in UTC
 
  // Calculate the time difference in milliseconds
  const timeDiff = new Date(completionDateUTC) - new Date(shippedDateUTC);
 
  // Convert the difference from milliseconds to days
  return (timeDiff / (1000 * 60 * 60 * 24)).toFixed(2); // Convert ms to days
};
 
// Example usage
const shippedDate = "14-Nov-2024 04:23 PM"; // Local Time
const completionDate = "15-Nov-2024 03:19 PM"; // Local Time
 
console.log(calculateECT(shippedDate, completionDate)); // Expected Output: Approximately 0.003 days (or very close to 1 day)
 
const getECTRowColor = (ect) => {
  if (ect === 'N/A' || isNaN(ect)) return '#FFF176'; // Yellow for invalid ECT
  if (ect > 1.6) return '#EF9A9A'; // Red for above threshold
  return '#A5D6A7'; // Green for within threshold
};
 
const ECTDetails = () => {
  const location = useLocation();
  const { taskData } = location.state || {};
 
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openCity, setOpenCity] = useState({});
  const [filters, setFilters] = useState({
    city: '',
    program: '',
    color: '',
    vendorId: '',
    State: '',
    PUDO_ID: '',
    State_Location: '',
    Customer_Postal_Code: '',
    dateRange: '', // new filter for date range
    customStartDate: null, // Custom start date for range
    customEndDate: null,  // Custom end date for range
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState(''); // Initialize state for WO# search
 
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [downloadType, setDownloadType] = useState('current'); // 'current' or 'custom'
 
 
  const tableFields = [
    'WO#',
    'MSN#',
    'part Number',
    'part Desc',
    'Part Shipped/Delivered Date',
    'Service Completion Date',
    'ect',
    'ECT',
    'Program',
  ]; // Fields to include in the expor
 
 
  const handleDownloadClick = () => {
    setDownloadDialogOpen(true);
  };
 
 
  const handleDownload = () => {
    let dataToExport;
 
    if (downloadType === 'current') {
      // Combine filtered and paginated tasks into a single array
      dataToExport = paginatedCities.flatMap((city) => filteredTasksByCity[city]);
    } else if (downloadType === 'custom') {
      // Filter tasks based on custom date range
      dataToExport = paginatedCities
        .flatMap((city) => filteredTasksByCity[city])
        .filter((task) => {
          const taskDate = new Date(task['Service Completion Date']);
          return (
            customStartDate &&
            customEndDate &&
            taskDate >= new Date(customStartDate) &&
            taskDate <= new Date(customEndDate)
          );
        });
    }
 
    if (!dataToExport || dataToExport.length === 0) {
      alert('No data available for the selected range!');
      return;
    }
 
    // Restrict the data to only the fields visible in the table
    const formattedData = dataToExport.map((task) => {
      const filteredTask = {};
      tableFields.forEach((field) => {
        filteredTask[field] = task[field] || ''; // Include only the specified fields
      });
      return filteredTask;
    });
 
    // Convert the filtered data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    XLSX.writeFile(workbook, `ECT_Data_${new Date().toISOString().split('T')[0]}.xlsx`);
 
    setDownloadDialogOpen(false); // Close dialog after download
  };
 
  // Validate taskData before using it in calculations
  const tasksByCity = useMemo(() => {
    if (!Array.isArray(taskData)) return {};
 
    return taskData
      .filter(
        (task) =>
          task['Part Shipped/Delivered Date'] &&
          task['Service Completion Date'] &&
          !isNaN(new Date(task['Part Shipped/Delivered Date']).getTime()) &&
          !isNaN(new Date(task['Service Completion Date']).getTime())
      ) // Filter out tasks missing dates or invalid dates
      .sort(
        (a, b) => new Date(b['Service Completion Date']) - new Date(a['Service Completion Date'])
      ) // Sort descending
      .reduce((acc, task) => {
        const city = task.City || 'Unknown';
        const ect = calculateECT(
          task['Part Shipped/Delivered Date'],
          task['Service Completion Date']
        );
        const colorCategory = ect > 1.6 ? 'Red' : 'Green'; // Remove Yellow category
 
        if (!acc[city]) acc[city] = [];
        acc[city].push({ ...task, ect, colorCategory });
        return acc;
      }, {});
  }, [taskData]);
 
 
 
 
 
  const applyDateFilter = (taskDate) => {
    const { dateRange, customStartDate, customEndDate } = filters;
    const taskCompletionDate = new Date(taskDate);
 
    // Validate Custom Date Range
    if (dateRange === 'Custom Range' && customStartDate && customEndDate) {
      if (new Date(customStartDate) > new Date(customEndDate)) {
        // Display an error if the start date is after the end date
        alert('Start Date cannot be later than End Date');
        return false;
      }
    }
 
    switch (dateRange) {
      case 'Today':
        return dayjs().isSame(taskCompletionDate, 'day');
        case 'Yesterday':
          return dayjs().subtract(1, 'day').isSame(taskCompletionDate, 'day');
      case 'This Week':
        return dayjs().startOf('week').isBefore(taskCompletionDate);
      case 'Last Month':
        return dayjs().subtract(1, 'month').isBefore(taskCompletionDate);
      case 'Custom Range':
        if (customStartDate && customEndDate) {
          return dayjs(taskCompletionDate).isBetween(dayjs(customStartDate), dayjs(customEndDate), 'day', '[]');
        }
        return true; // If no custom range is provided, don't filter by date
      default:
        return true; // No date filter applied
    }
  };
 
 
  const filteredTasksByCity = useMemo(() => {
    return Object.keys(tasksByCity).reduce((acc, city) => {
      const cityTasks = tasksByCity[city]
        .filter((task) => {
          const matchesVendorId = filters.vendorId ? task['Vendor Id'] === filters.vendorId : true;
          const matchesPUDO_ID = filters.PUDO_ID ? task['PUDO ID'] === filters.PUDO_ID : true;
          const matchesProgram = filters.program ? task['Program'] === filters.program : true;
          const matchesColor = filters.color ? task.colorCategory === filters.color : true;
          const matchesState = filters.State ? task.State === filters.State : true;
          const matchesStateLocation = filters.State_Location
            ? task['State Location'] === filters.State_Location
            : true; // Allow all if no State_Location is selected
          const matchesCustomerPostalCode = filters.Customer_Postal_Code
            ? task['Customer Postal Code'] === filters.Customer_Postal_Code
            : true;
          const matchesDateRange = applyDateFilter(task['Service Completion Date']);
          const matchesSearchQuery =
            task['WO#'] && task['WO#'].toString().toLowerCase().includes(searchQuery.toLowerCase());
 
          return (
            matchesVendorId &&
            matchesProgram &&
            matchesColor &&
            matchesState &&
            matchesPUDO_ID &&
            matchesStateLocation &&
            matchesCustomerPostalCode &&
            matchesDateRange &&
            matchesSearchQuery
          );
        })
        .sort((a, b) => new Date(b['Service Completion Date']) - new Date(a['Service Completion Date']));
 
      if (cityTasks.length > 0) {
        acc[city] = cityTasks;
      }
      return acc;
    }, {});
  }, [tasksByCity, filters, searchQuery]);
 
 
 
  const calculateStateLocationAverages = () => {
    if (!filteredTasksByCity) return { averageECT: 'N/A', averageJetKingECT: 'N/A' };
 
    const allTasks = !filters.State_Location
      ? Object.values(filteredTasksByCity).flat()
      : Object.values(filteredTasksByCity)
          .flat()
          .filter((task) => task['State Location'] === filters.State_Location);
 
    if (allTasks.length === 0) return { averageECT: 'N/A', averageJetKingECT: 'N/A' };
 
    const totalECT = allTasks.reduce((sum, task) => {
      const ectValue = parseFloat(task.ect);
      return isNaN(ectValue) ? sum : sum + ectValue;
    }, 0);
 
    const totalJetKingECT = allTasks.reduce((sum, task) => {
      const jetKingECTValue = parseFloat(task.ECT);
      return isNaN(jetKingECTValue) ? sum : sum + jetKingECTValue;
    }, 0);
 
    return {
      averageECT: (totalECT / allTasks.length).toFixed(2),
      averageJetKingECT: (totalJetKingECT / allTasks.length).toFixed(2),
    };
  };
 
  const { averageECT, averageJetKingECT } = calculateStateLocationAverages();
 
 
 
  const calculatePudoIdAverageECT = () => {
    if (!filters.PUDO_ID || !filteredTasksByCity) return 'N/A';
 
    const tasksInPudoId = Object.values(filteredTasksByCity)
      .flat()
      .filter(task => task['PUDO ID'] === filters.PUDO_ID);
 
    if (tasksInPudoId.length === 0) return 'N/A';
 
    const totalECT = tasksInPudoId.reduce((sum, task) => {
      const ectValue = parseFloat(task.ect);
      return isNaN(ectValue) ? sum : sum + ectValue;
    }, 0);
 
    return (totalECT / tasksInPudoId.length).toFixed(2); // Average ECT
  };
 
 
 
 
  const handleRowClick = (city) => {
    setOpenCity((prevState) => ({
      ...prevState,
      [city]: !prevState[city],
    }));
  };
 
  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };
 
  const handleChangePage = (event, newPage) => setPage(newPage);
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  const paginatedCities = useMemo(() => {
    const cities = Object.keys(filteredTasksByCity);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return cities.slice(startIndex, endIndex);
  }, [filteredTasksByCity, page, rowsPerPage]);
 
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header handleDrawerToggle={handleDrawerToggle} title="City-wise ECT Details" />
 
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
 
        <Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    backgroundColor: '#f4f6f8',
    paddingTop: '64px',
    borderRadius: '8px',
    boxShadow: boxShadowStyle,
  }}
>
  <Toolbar />
 
  {/* Header Section with Heading and Download Button */}
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 2, // Add some margin below
    }}
  >
    <Typography
      variant="h4"
      sx={{ fontFamily: headerFont, color: primaryColor, fontWeight: 'bold' }}
    >
      City-wise ECT (Average)
    </Typography>
 
    {/* Download Button */}
    <Tooltip title="Download Data">
      <Button
        variant="contained"
        startIcon={<DownloadIcon />}
        onClick={handleDownloadClick}
        sx={{
          backgroundColor: '#1565C0',
          color: '#fff',
          fontWeight: 'bold',
          textTransform: 'none',
          borderRadius: '8px',
          padding: '8px 16px',
          ':hover': {
            backgroundColor: '#004ba0',
          },
        }}
      >
        Download Data
      </Button>
    </Tooltip>
  </Box>
 
  {/* Download Dialog */}
  <Dialog open={downloadDialogOpen} onClose={() => setDownloadDialogOpen(false)}>
    <DialogTitle>Download Data</DialogTitle>
    <DialogContent>
      <Typography sx={{ mb: 2 }}>
        Select the type of data you want to download:
      </Typography>
 
      <Select
        value={downloadType}
        onChange={(e) => setDownloadType(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      >
        <MenuItem value="current">Current Page Data</MenuItem>
        <MenuItem value="custom">Custom Date Range</MenuItem>
      </Select>
 
      {downloadType === 'custom' && (
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={customStartDate}
            onChange={(e) => setCustomStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="date"
            fullWidth
            value={customEndDate}
            onChange={(e) => setCustomEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      )}
    </DialogContent>
    <DialogActions>
    <Button onClick={() => setDownloadDialogOpen(false)}>Cancel</Button>
 
      <Button
        onClick={handleDownload}
        variant="contained"
        sx={{
          backgroundColor: '#1565C0',
          color: '#fff',
          fontWeight: 'bold',
          textTransform: 'none',
          borderRadius: '8px',
          ':hover': {
            backgroundColor: '#004ba0',
          },
        }}
      >
        Download
      </Button>
    </DialogActions>
  </Dialog>
 
 
 
          {/* Filters */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
          <TextField
              label="Search by WO#"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: '250px' }}
            />
 
<Select
              value={filters.State}
              onChange={(e) => handleFilterChange('State', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All States</MenuItem>
              {[...new Set(taskData.map((task) => task.State))].map((state, index) => (
                <MenuItem key={index} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
 
            <Select
              value={filters.State_Location}
              onChange={(e) => handleFilterChange('State_Location', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All State Locations</MenuItem>
              {[...new Set(taskData.map((task) => task['State Location']))].map((location, index) => (
                <MenuItem key={index} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>
 
            <Select
              value={filters.vendorId}
              onChange={(e) => handleFilterChange('vendorId', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All Vendor IDs</MenuItem>
              {[...new Set(taskData.map((task) => task['Vendor Id']))].map((vendorId, index) => (
                <MenuItem key={index} value={vendorId}>
                  {vendorId}
                </MenuItem>
              ))}
            </Select>
 
            <Select
              value={filters.PUDO_ID}
              onChange={(e) => handleFilterChange('PUDO_ID', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All PUDO IDs</MenuItem>
              {[...new Set(taskData.map((task) => task['PUDO ID']))].map((pudo, index) => (
                <MenuItem key={index} value={pudo}>
                  {pudo}
                </MenuItem>
              ))}
            </Select>
 
 
            <Select
              value={filters.Customer_Postal_Code}
              onChange={(e) => handleFilterChange('Customer_Postal_Code', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All Postal Codes</MenuItem>
              {[...new Set(taskData.map((task) => task['Customer Postal Code']))].map((code, index) => (
                <MenuItem key={index} value={code}>
                  {code}
                </MenuItem>
              ))}
            </Select>
 
 
            <Select
              value={filters.program}
              onChange={(e) => handleFilterChange('program', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All Programs</MenuItem>
              {[...new Set(taskData.map((task) => task['Program']))].map((program, index) => (
                <MenuItem key={index} value={program}>
                  {program}
                </MenuItem>
              ))}
            </Select>
     
       
       
            <Select
  value={filters.dateRange}
  onChange={(e) => handleFilterChange('dateRange', e.target.value)}
  displayEmpty
  size="small"
  variant="outlined"
  sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
>
  <MenuItem value="" disabled>
    Select Date Range
  </MenuItem>
  <MenuItem value="Today">Today</MenuItem>
  <MenuItem value="Yesterday">Yesterday</MenuItem>
  <MenuItem value="This Week">This Week</MenuItem>
  <MenuItem value="Last Month">Last Month</MenuItem>
  <MenuItem value="Custom Range">Custom Range</MenuItem>
</Select>
 
                        {/* Custom Date Range */}
                        {filters.dateRange === 'Custom Range' && (
              <>
                <TextField
                  label="Start Date"
                  type="date"
                  value={filters.customStartDate}
                  onChange={(e) => handleFilterChange('customStartDate', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ minWidth: '180px' }}
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={filters.customEndDate}
                  onChange={(e) => handleFilterChange('customEndDate', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ minWidth: '180px' }}
                />
              </>
            )}
 
 
       {/* Color Filters as Boxes */}
           
                <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              {/* Above Threshold */}
              <MenuItem value="">Color Maping :-</MenuItem>
 
              <Tooltip title="Above Threshold">
                <Box
                  onClick={() => handleFilterChange('color', 'Red')}
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: '#EF9A9A',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    boxShadow: filters.color === 'Red' ? '0 0 6px 2px rgba(0, 0, 0, 0.2)' : 'none',
                  }}
                />
              </Tooltip>
 
              {/* Within Threshold */}
              <Tooltip title="Within Threshold">
                <Box
                  onClick={() => handleFilterChange('color', 'Green')}
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: '#A5D6A7',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    boxShadow: filters.color === 'Green' ? '0 0 6px 2px rgba(0, 0, 0, 0.2)' : 'none',
                  }}
                />
              </Tooltip>
 
             
  <Typography
    variant="body1"
    sx={{
      color: '#333', // Neutral dark text color
      mb: 0.5,
    }}
  >
    Average ECT: <strong>{averageECT} Days</strong>
  </Typography>
  <Typography
    variant="body1"
    sx={{
      color: '#333',
    }}
  >
    Average JetKing ECT: <strong>{averageJetKingECT} Days</strong>
  </Typography>
 
            </Box>
          </Box>
 
 
 
 
{filters.PUDO_ID && (
  <Box
    sx={{
      mt: 2,
      mb: 3,
      backgroundColor: '#f9f9f9',
      p: 2,
      borderRadius: '8px',
      boxShadow: boxShadowStyle,
    }}
  >
    <Typography
      variant="h6"
      sx={{ fontFamily: headerFont, fontWeight: 'bold', color: primaryColor }}
    >
      Average ECT for PUDO ID {filters.PUDO_ID}: {calculatePudoIdAverageECT()} Days
    </Typography>
  </Box>
)}
 
 
 
 
 
       
          {/* Table */}
          <TableContainer component={Paper} sx={{ background: '#fff', borderRadius: '8px', boxShadow: boxShadowStyle }}>
            <Table>
              <TableHead>
                <TableRow sx={{ background: primaryColor }}>
                  <TableCell sx={{ color: '#fff' }}>Expand</TableCell>
                  <TableCell sx={{ color: '#fff' }}>City</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Average ECT (Days)</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Program</TableCell>
                  <TableCell sx={{ color: '#fff' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               
                {paginatedCities.map((city, index) => {
                  const cityTasks = filteredTasksByCity[city];
                  const averageECTDays =
                    cityTasks.reduce((sum, task) => sum + (isNaN(task.ect) ? 0 : parseFloat(task.ect)), 0) /
                    cityTasks.length;
 
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>
                          <IconButton size="small" onClick={() => handleRowClick(city)}>
                            {openCity[city] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell>{city}</TableCell>
                        <TableCell>{averageECTDays.toFixed(2)}</TableCell>
                        <TableCell>{cityTasks[0]['Program'] || 'N/A'}</TableCell>
                        <TableCell>{/* Color Box */}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <Collapse in={openCity[city]} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <Typography variant="h6" gutterBottom component="div">
                                Tasks for {city}
                              </Typography>
                              <Table size="small" aria-label="tasks">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Task WO#</TableCell>
                                    <TableCell>MSN#</TableCell>
                                    <TableCell>part Number</TableCell>
                                    <TableCell>part Decs</TableCell>
                                    <TableCell>Part Shipped/Delivered Date</TableCell>
                                    <TableCell>Service Completion Date</TableCell>
                                    <TableCell>ECT (Days)</TableCell>
                                    <TableCell>JetKing ECT</TableCell>
                                    <TableCell>Program</TableCell>
                                    {/* <TableCell>Color</TableCell> */}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {cityTasks.map((task, taskIndex) => (
                                    <TableRow key={taskIndex} style={{ backgroundColor: getECTRowColor(parseFloat(task.ect)) }}>
                                      <TableCell>{task['WO#']}</TableCell>
                                      <TableCell>{task['MSN#']}</TableCell>
                                      <TableCell>{task['part Number']}</TableCell>
                                      <TableCell>{task['part Desc']}</TableCell>
                                      <TableCell>{formatDateTime(task['Part Shipped/Delivered Date'])}</TableCell>
                                      <TableCell>{formatDateTime(task['Service Completion Date'])}</TableCell>
                                      <TableCell>{task.ect} days</TableCell>
                                      <TableCell>{task.ECT} days</TableCell>
 
                                      {/* <TableCell>
                                        {calculateECTInHours(task['Part Shipped/Delivered Date'], task['Service Completion Date'])} hours
                                      </TableCell> */}
                                      <TableCell>{task['Program'] || 'N/A'}</TableCell>
                                      {/* <TableCell>{task.colorCategory}</TableCell> */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
 
          <TablePagination
            component="div"
            count={Object.keys(filteredTasksByCity).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Box>
      </Box>
 
      <Footer />
    </Box>
  );
};
 
export default ECTDetails;
