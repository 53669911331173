import React, { useEffect, useState } from "react";
import { Card, Table, TableBody,TablePagination, Typography, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, Select,MenuItem, FormControl, InputLabel, TextField,InputAdornment,} from "@mui/material";
import { styled } from "@mui/system";
import { CalendarToday } from "@mui/icons-material"; // Import calendar icon
import TicketDetails from "../components/TicketDetails";
import { API_BASE_URL } from "../services/apiService";
import Header from "../components/Header";
import SideDrawer from "../components/Drawer";
import Footer from "../components/Footer";
import Search from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
 
const headerColors = {
  "Technician Assigned": "#D1C4E9", // Light Purple
  "Paused": "#FFF59D", // Light Amber Yellow
  "Accepted": "#B2DFDB", // Light Teal
  "Rescheduled": "#FFCCBC", // Light Coral
  "Fixed": "#C8E6C9", // Light Green
};
 
 
const TicketManagement = () => {
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filter, setFilter] = useState("Overall");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTicket, setFilteredTicket] = useState([]);
  const [statusFilter, setStatusFilter] = useState("Overall"); // Manage status filter
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(50); // Rows per page
 
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/tickets`);
        if (!response.ok) {
          throw new Error("Failed to fetch tickets");
        }
        const data = await response.json();
        setTickets(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
 
    fetchTickets();
  }, []);
 
  useEffect(() => {
    const updatedTickets = filterTickets(tickets, filter, startDate, endDate, statusFilter);
    setFilteredTicket(updatedTickets);
  }, [filter, statusFilter, startDate, endDate, tickets]);  // Make sure dependencies include both filter types
 
 
  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilter(selectedFilter);
   
    // Reset startDate and endDate when a filter other than "Select Date Range" is selected
    if (selectedFilter !== "Select Date Range") {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };
 
    // Handle change in page
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
 
    // Handle change in rows per page
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0); // Reset to first page when rows per page change
    };
 
    const handleViewDetails = (ticket) => {
      setSelectedTicket(ticket); // Set the ticket to show details in a modal or separate section
    };
   
 
// Filter tickets based on selected status and date range
const filterTickets = (tickets, filter, startDate, endDate, statusFilter) => {
  let filteredTickets = tickets;
 
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to midnight
 
  // Helper function to get the relevant date for each ticket
  const getRelevantDate = (ticket) => ticket["New Ticket Date"];
 
  // Step 1: Apply the date filter
  switch (filter) {
    case "Today":
      filteredTickets = filteredTickets.filter((ticket) => {
        const relevantDate = getRelevantDate(ticket);
        if (relevantDate) {
          const ticketDate = new Date(relevantDate);
          ticketDate.setHours(0, 0, 0, 0); // Normalize to midnight
          return ticketDate.getTime() === today.getTime(); // Match today's date
        }
        return false;
      });
      break;
 
    case "Last 1 Day":
      const last1Day = new Date(today);
      last1Day.setDate(today.getDate() - 1);
      last1Day.setHours(0, 0, 0, 0);
      filteredTickets = filteredTickets.filter((ticket) => {
        const relevantDate = getRelevantDate(ticket);
        if (relevantDate) {
          const ticketDate = new Date(relevantDate);
          ticketDate.setHours(0, 0, 0, 0); // Normalize to midnight
          return ticketDate >= last1Day && ticketDate < today; // Match tickets from last 24 hours
        }
        return false;
      });
      break;
 
    case "Last 7 Days":
      const last7Days = new Date(today);
      last7Days.setDate(today.getDate() - 7);
      last7Days.setHours(0, 0, 0, 0);
      filteredTickets = filteredTickets.filter((ticket) => {
        const relevantDate = getRelevantDate(ticket);
        if (relevantDate) {
          const ticketDate = new Date(relevantDate);
          ticketDate.setHours(0, 0, 0, 0);
          return ticketDate >= last7Days && ticketDate <= today; // Match tickets from last 7 days
        }
        return false;
      });
      break;
 
    case "This Month":
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the month
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the month
      firstDayOfMonth.setHours(0, 0, 0, 0); // Normalize to midnight
      lastDayOfMonth.setHours(23, 59, 59, 999); // Set the last day to just before midnight
      filteredTickets = filteredTickets.filter((ticket) => {
        const relevantDate = getRelevantDate(ticket);
        if (relevantDate) {
          const ticketDate = new Date(relevantDate);
          ticketDate.setHours(0, 0, 0, 0);
          return ticketDate >= firstDayOfMonth && ticketDate <= lastDayOfMonth; // Match tickets from this month
        }
        return false;
      });
      break;
 
      case "Last Month":
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); // First day of the last month
        const firstDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1); // First day of the last month
        const lastDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0); // Last day of the last month
        firstDayOfLastMonth.setHours(0, 0, 0, 0); // Normalize to midnight
        lastDayOfLastMonth.setHours(23, 59, 59, 999); // Set to just before midnight of the last day of the month
       
        filteredTickets = filteredTickets.filter((ticket) => {
          const relevantDate = getRelevantDate(ticket);
          if (relevantDate) {
            const ticketDate = new Date(relevantDate);
            ticketDate.setHours(0, 0, 0, 0);
            return ticketDate >= firstDayOfLastMonth && ticketDate <= lastDayOfLastMonth; // Match tickets from last month
          }
          return false;
        });
        break;
   
    case "Select Date Range":
      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        filteredTickets = filteredTickets.filter((ticket) => {
          const relevantDate = getRelevantDate(ticket);
          if (relevantDate) {
            const ticketDate = new Date(relevantDate);
            ticketDate.setHours(0, 0, 0, 0);
            return ticketDate >= start && ticketDate <= end; // Match tickets within the selected date range
          }
          return false;
        });
      }
      break;
 
    default:
      break; // No date filter, return all tickets
  }
 
  // status filter after the date filter
  if (statusFilter && statusFilter !== "Overall") {
    filteredTickets = filteredTickets.filter((ticket) => ticket["Status"] === statusFilter);
  }
  return filteredTickets;
};
  // Count tickets by status (including Accepted)
  const countTicketsByStatus = (tickets, selectedOption, startDate, endDate) => {
    const filteredTickets = filterTickets(tickets, selectedOption, startDate, endDate);
 
    const counts = {
      Fixed: 0,
      Rescheduled: 0,
      "Technician Assigned": 0,
      Accepted: 0,
      "IN-Route": 0,
      Started: 0,
      Paused: 0,
    };
 
    // Debugging: Log the filtered tickets
    console.log(`Filtered Tickets for '${selectedOption}':`, filteredTickets);
 
    filteredTickets.forEach((ticket) => {
      const status = ticket["Status"];
      if (status) {
        if (status === "Accepted") counts.Accepted += 1;
        if (status === "Fixed") counts.Fixed += 1;
        if (status === "Rescheduled") counts.Rescheduled += 1;
        if (status === "Technician Assigned") counts["Technician Assigned"] += 1;
        if (status === "IN-Route") counts["IN-Route"] += 1;
        if (status === "Started") counts.Started += 1;
        if (status === "Paused") counts.Paused += 1;
      }
    });
    // Debugging: Log the final counts
    console.log("Final Ticket Counts:", counts);
    return counts;
  };
 
  const filteredTickets = filterTickets(tickets, filter, startDate, endDate)
  .filter(ticket => {
       // Search query filter
    const lowerCaseSearchTerm = searchQuery.toLowerCase();
   
    // Ensure the fields are strings and use an empty string as default if they are undefined or not a string
    return (
      (String(ticket["Customer Name"]).toLowerCase().includes(lowerCaseSearchTerm)) ||
      (String(ticket["Case#"]).toLowerCase().includes(lowerCaseSearchTerm)) ||
      (String(ticket["MSN#"]).toLowerCase().includes(lowerCaseSearchTerm)) ||
      (String(ticket["WO#"]).toLowerCase().includes(lowerCaseSearchTerm))
    );
  });
 
  const ticketCounts = countTicketsByStatus(filteredTickets);
 
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }
  const groupedTickets = filteredTickets.reduce((acc, ticket) => {
    const status = ticket["Status"];
    if (status) { // Ensure status is valid
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(ticket);
    }
    return acc;
  }, {});
 
  const handleCloseDetails = () => {
    setSelectedTicket(null);
  };
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);
 
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          width: drawerOpen ? "240px" : "0",
          flexShrink: 0,
          transition: "width 0.3s ease",
          backgroundColor: "#fff",
          boxShadow: drawerOpen ? "2px 0 5px rgba(0, 0, 0, 0.1)" : "none",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 1100,
          overflowY: "auto",
        }}
      >
        <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      </Box>
 
      {/* Main Content */}
      <Box
        sx={{
          marginLeft: drawerOpen ? "170px" : "0",
          transition: "margin-left 0.3s ease",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "64px",
            backgroundColor: "#fff",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: 1200,
          }}
        >
          <Header
            handleDrawerToggle={handleDrawerToggle}
            title="Admin Dashboard"
          />
        </Box>
        {/* Main content area */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            padding: "14px",
            backgroundColor: "#f0f0f0",
            marginTop: "64px",
            marginLeft: 6
          }}
        >
        <Box sx={{ flexGrow: 1, overflow: "auto", padding: "15px",}}>
            <Box sx={{display: "flex", marginBottom: "20px", flexWrap: "wrap", gap: 2,justifyContent: "space-between", alignItems: "center"}}>
 
            {/* Dropdown */}
            <FormControl
            sx={{
            width: "50%",
            maxWidth: "50%",
            minWidth: filter === "Select Date Range" ? "200px" : "250px",
            transition: "min-width 0.3s ease",  // Smooth transition for dropdown width change
            }}
            >
            <InputLabel>Select Date</InputLabel>
            <Select
            value={filter}
            onChange={handleFilterChange}
            label="Select Date"
            size="small"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "25px",
              padding: "1px 12px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#007bff",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#007bff",
              },
              "& .MuiSelect-icon": {
                color: "#007bff",
              },
              "& .MuiInputLabel-root": {
                fontWeight: 500,
                color: "#555",
              },
              "& .MuiSelect-root": {
                fontWeight: 600,
                color: "#333",
                fontSize: "16px",
              },
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            >
            <MenuItem value="Overall">All Tickets</MenuItem>
            <MenuItem value="Today">Today</MenuItem>
            <MenuItem value="Last 1 Day">Last 1 Day</MenuItem>
            <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
            <MenuItem value="This Month">This Month</MenuItem>
            <MenuItem value="Last Month">Last Month</MenuItem>
            <MenuItem value="Select Date Range">Custom Range</MenuItem>
            </Select>
            </FormControl>
 
            {/* Start and End Date Fields */}
            {filter === "Select Date Range" && (
            <Box
              sx={{
                display: "flex",
                marginTop:"3px",
                gap: 1,  // Space between start and end date fields
                maxWidth: "40%",
                transition: "opacity 0.3s ease, transform 0.3s ease",  // Smooth transition for appearance
                transform: "translateY(0px)",  // Default position
              }}
            >
              {/* Start Date Field */}
              <TextField
                type="date"
                value={startDate}
                placeholder="Start Date"
                onChange={handleStartDateChange}
                label="Start Date"
                variant="outlined"
                  size="small"
                sx={{
                  flex: 1,
                  maxWidth: "150px",  // Width control for both date fields
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                    backgroundColor: "#fff",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday sx={{ color: "#007bff" }} />
                    </InputAdornment>
                  ),
                }}
              />
 
              {/* End Date Field */}
              <TextField
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                label="End Date"
                variant="outlined"
                size="small"
                sx={{
                  flex: 1,
                  maxWidth: "150px",  
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                    backgroundColor: "#fff",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday sx={{ color: "#007bff" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            )}
 
              {/* Search Box at the end */}
              <TextField
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="   Enter Work Order Number"
                    sx={{
                      width: "350px",
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "25px",
                        transition: "border-color 0.3s ease",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#0056b3",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#0056b3",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#888",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#0056b3",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <Search sx={{ color: "#0056b3", }} />
                        </InputAdornment>
                      ),
                    }}
                   />
            </Box>
 
            {/* Ticket Counts */}
            <Box
              sx={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderRadius: 2,
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                flexDirection: {
                  xs: "column", // Stack items vertically on small screens
                  sm: "row", // Align items in a row for larger screens
                },
              }}
            >
                {/* Overall Count */}
                <Box sx={{ textAlign: "center", flex: 1, p: 1, mb: 1, "&:not(:last-child)": { borderRight: "1px solid #ddd" } }}>
                  <Typography sx={{ fontSize: 15, fontWeight: "bold", color: "#333" }}>Overall</Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "bold", color: "#007bff", mt: 0.5 }}>
                    {Object.values(ticketCounts).reduce((sum, count) => sum + count, 0)}
                  </Typography>
                </Box>
              {[
                { label: "Accepted Work Order", value: ticketCounts.Accepted },
                { label: "Fixed", value: ticketCounts.Fixed },
                { label: "Rescheduled", value: ticketCounts.Rescheduled },
                { label: "Technician Assigned", value: ticketCounts["Technician Assigned"] },
                { label: "IN-Route", value: ticketCounts["IN-Route"] },
                { label: "Started", value: ticketCounts.Started },
                { label: "Paused", value: ticketCounts.Paused}
              ].map((item, index) => (
                <Box key={index} sx={{ textAlign: "center", flex: 1, padding: "1px 16px", width: "100%", "&:not(:last-child)": { borderRight: "1px solid #ddd" } }}>
                      <Typography sx={{ fontSize: "15px", fontWeight: "bold", color: "#333" }}>{item.label}</Typography>
                      <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: "#007bff", marginTop: "4px" }}>{item.value}</Typography>
                    </Box>
              ))}
               {/* Status Filter */}
                <Box
                  sx={{
                    textAlign: "center",
                    flex: 1,
                    padding: "1px 16px",
                    marginBottom: "1px",
                  }}
                >
                  <Typography sx={{fontSize: "16px", fontWeight: "bold", color: "#333"}}>
                    Status
                  </Typography>
                  <Select
                    value={statusFilter}
                    onChange={handleStatusChange}
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                      backgroundColor: "#fff",
                      borderRadius: "15px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      "& .MuiSelect-root": {
                        padding: "2px",
                      },
                    }}
                  >
                    <MenuItem value="Overall">All</MenuItem>
                    <MenuItem value="Accepted">Accepted</MenuItem>
                    <MenuItem value="Rescheduled">Rescheduled</MenuItem>
                    <MenuItem value="Technician Assigned">Technician Assigned</MenuItem>
                    <MenuItem value="Fixed">Fixed</MenuItem>
                    <MenuItem value="Paused">Paused</MenuItem>
                  </Select>
                </Box>
            </Box>
 
            {/* Category-wise ticket display */}
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                height: "500px", // Set fixed height for the entire container
                overflowY: "auto", // Enable vertical scrolling if content exceeds the height
                marginBottom: "2rem",
                border: "1px solid #ddd", // Add border to container for a cleaner look
              }}
            >
              <Table sx={{ minWidth: "100%" }}>
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#f4f6f8",
                    zIndex: 1,
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Add subtle shadow for better header separation
                  }}
                >
                  <TableRow>
                    {["WO#", "Case#", "MSN#", "Status","Created Date", "Tech Assignment", "Part Number","View"].map((column) => (
                      <TableCell
                        key={column}
                        sx={{
                          fontWeight: "500",
                          backgroundColor: "#f4f6f8",
                          fontSize: "1rem",
                          color: "#333",
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTickets
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    // Filter tickets by selected status (if any)
                    .filter(ticket => statusFilter === "Overall" || ticket["Status"] === statusFilter)
                    // Sort tickets by "New Ticket Date" (latest first)
                    .sort((a, b) => {
                      const dateA = new Date(a["New Ticket Date"]);
                      const dateB = new Date(b["New Ticket Date"]);
                      return dateB - dateA; // Sort in descending order (latest first)
                    })
                    .map((ticket, index) => (
                      <TableRow
                        key={ticket._id}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#f1f1f1", // Subtle hover effect
                            cursor: "pointer",
                          },
                          transition: "background-color 0.3s ease",
                          backgroundColor:
                            headerColors[ticket["Status"]] ||
                            (index % 2 === 0 ? "#f9f9f9" : "#ffffff"),
                        }}
                        onClick={() => setSelectedTicket(ticket)}
                      >
                        <TableCell sx={{padding: "10px", fontSize: "0.8rem", color: "#333", textAlign: "center", borderBottom: "1px solid #ddd", }}>
                          {ticket["WO#"]}
                        </TableCell>
                        <TableCell sx={{padding: "10px", fontSize: "0.8rem", color: "#333", textAlign: "center", borderBottom: "1px solid #ddd", }}>
                          {ticket["Case#"]}
                        </TableCell>
                        <TableCell sx={{padding: "10px", fontSize: "0.8rem", color: "#333", textAlign: "center", borderBottom: "1px solid #ddd", }}>
                          {ticket["MSN#"]}
                        </TableCell>
                        <TableCell sx={{padding: "10px", fontSize: "0.8rem", color: "#333", textAlign: "center", borderBottom: "1px solid #ddd", }}>
                          {ticket["Status"]}
                        </TableCell>
                        <TableCell sx={{padding: "10px", fontSize: "0.8rem", color: "#333", textAlign: "center", borderBottom: "1px solid #ddd", }}>
                          {ticket["New Ticket Date"]}
                        </TableCell>
                        <TableCell sx={{padding: "10px", fontSize: "0.8rem", color: "#333", textAlign: "center", borderBottom: "1px solid #ddd", }}>
                          {ticket["Tech Assignment"]}
                        </TableCell>
                        <TableCell sx={{padding: "10px", fontSize: "0.8rem", color: "#333", textAlign: "center", borderBottom: "1px solid #ddd", }}>
                          {ticket["part Number"]}
                        </TableCell>
                        <TableCell sx={{padding: "10px", textAlign: "center", borderBottom: "1px solid #ddd", }} >
                          <IconButton
                            color="primary"
                            onClick={() => handleViewDetails(ticket)}
                          >
                            <Visibility />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
 
              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[50, 75, 100]}
                component="div"
                count={filteredTickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  "& .MuiTablePagination-select": {
                    backgroundColor: "#f4f6f8",
                    borderRadius: "4px",
                    padding: "6px 12px",
                  },
                  "& .MuiTablePagination-actions": {
                    color: "#1976d2",
                  },
                }}
              />
            </TableContainer>
 
            {selectedTicket && (
              <TicketDetails
                ticket={selectedTicket}
                onClose={handleCloseDetails}
              />
            )}
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
 
export default TicketManagement;
 
 