import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Collapse,
  Stack,
  Button,
  Paper,
} from '@mui/material';
import {
  Person,
  Computer,
  ReportProblem,
  ExpandMore,
  ExpandLess,
  ArrowBack,

} from '@mui/icons-material';
import { styled } from '@mui/system'; // For custom styling
import { format } from 'date-fns';
import Header from '../components/Header';
import FieldEngineerDrawer from '../components/FieldEngineerDrawer';
import Footer from '../components/Footer';
import WorkOrderAssignment from '../components/WorkOrderAssignment';  // Import WorkOrderAssignment component
import AssignOrderList from '../components/AssignOrderList';  // Import AssignOrderList component

// Custom styled component for the ticket information box
const TicketInfoBox = styled(Box)({
  backgroundColor: '#f5f5f5',
  padding: '12px 16px',
  borderRadius: '8px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  width: '100%', // Full width
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.15)',
  },
  marginBottom: '20px', // Adds some spacing below the box
});

// Utility function to format the date
const formatDate = (date) => {
  if (!date) return 'N/A';
  return format(new Date(date), 'dd-MMM-yyyy');
};

const TaskDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate

  const task = location.state?.task; // Retrieve the task details passed via state
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawer state for sidebar toggle
  const [expandedSection, setExpandedSection] = useState(''); // Track expanded section
  const [currentMenu, setCurrentMenu] = useState('Dashboard');  // Track the current menu
  const [selectedTask, setSelectedTask] = useState(null);  // State to store the selected task
  if (!task) {
    return <Typography variant="h5">No task details found.</Typography>;
  }

  // Split customer name into first name and last name
  const customerName = task["Customer Name"] || '';
  const [firstName, lastName] = customerName.split(' ');

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? '' : section);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleMenuClick = (menuItem) => {
    setCurrentMenu(menuItem);
  };

  const handleAcknowledgeTask = (task) => {
    setSelectedTask(task);
    setCurrentMenu("WorkOrderAssignment");
  };

 // The content rendering part remains the same as before
 const renderContent = () => {
  if (currentMenu === "WorkOrderAssignment") {
    return <WorkOrderAssignment task={selectedTask} />;
  } else if (currentMenu === "AssignOrderList") {
    return <AssignOrderList onAcknowledgeTask={handleAcknowledgeTask} />;
  } else {
  }
};
  

  const goBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
     
     <Header handleDrawerToggle={handleDrawerToggle} title="Field Engineer Dashboard" />
     
     <Box sx={{ display: 'flex', flexGrow: 1, marginTop: '70px' }}>
        {/* Field Engineer specific Drawer */}
        <FieldEngineerDrawer
          open={drawerOpen}
          handleDrawerToggle={handleDrawerToggle}
          onMenuClick={handleMenuClick}  // Pass handleMenuClick to FieldEngineerDrawer
        />

        <Stack spacing={3} sx={{ padding: '20px', width: '100%' }}>
              {/* Back Button */}
              <Box sx={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={goBack}
              sx={{ textTransform: 'none' }}
            >
              Back
            </Button>
          </Box>

          {/* Ticket Information */}
          <TicketInfoBox>
            <Typography variant="h6" fontWeight="bold" color="secondary" gutterBottom>
              Ticket Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Customer Name:</Typography>
                <Typography variant="body2" color="text.secondary">{task["Customer Name"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Case#:</Typography>
                <Typography variant="body2" color="text.secondary">{task["Case#"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">WO#:</Typography>
                <Typography variant="body2" color="text.secondary">{task["WO#"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">MSN#:</Typography>
                <Typography variant="body2" color="text.secondary">{task["MSN#"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Contact:</Typography>
                <Typography variant="body2" color="text.secondary">{task["Customer Mobile Number"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Updated By:</Typography>
                <Typography variant="body2" color="text.secondary">{task["Updated By"]}</Typography>
              </Grid>
            </Grid>
          </TicketInfoBox>

          {/* Customer Information */}
          <Paper sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <Typography variant="subtitle2" sx={{ ml: 2, fontWeight: '500', display: 'flex', alignItems: 'center' }}>
              <IconButton size="small" sx={{ backgroundColor: '#f8d7da', borderRadius: '50%', padding: '4px', marginRight: 1 }}>
                <Person sx={{ color: '#fff' }} />
              </IconButton>
              Customer Information
              <IconButton
                size="small"
                aria-label="expand"
                onClick={() => toggleSection('customer-info')}
                sx={{
                  transform: expandedSection === 'customer-info' ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              >
                {expandedSection === 'customer-info' ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>
            <Collapse in={expandedSection === 'customer-info'} timeout="auto" unmountOnExit>
              <div style={{ padding: '16px', borderRadius: '4px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">First Name:</Typography>
                    <Typography variant="body2" color="text.secondary">{firstName}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Last Name:</Typography>
                    <Typography variant="body2" color="text.secondary">{lastName}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Email:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Email"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Contact Number:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Customer Mobile Number"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Contact Address:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Customer Address"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Contact Postal Code:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Customer Postal Code"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Contact City:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["City"] || "N/A"}</Typography>
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </Paper>

          {/* Machine Information */}
          <Paper sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <Typography variant="subtitle2" sx={{ ml: 2, fontWeight: '500', display: 'flex', alignItems: 'center' }}>
              <IconButton size="small" sx={{ backgroundColor: '#7FDBB6', borderRadius: '50%', padding: '4px', marginRight: 1 }}>
                <Computer sx={{ color: '#fff' }} />
              </IconButton>
              Machine Information
              <IconButton
                size="small"
                aria-label="expand"
                onClick={() => toggleSection('machine-info')}
                sx={{
                  transform: expandedSection === 'machine-info' ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              >
                {expandedSection === 'machine-info' ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>
            <Collapse in={expandedSection === 'machine-info'} timeout="auto" unmountOnExit>
              <div style={{ padding: '16px', borderRadius: '4px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">MSN#:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["MSN#"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">MTM:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["MTM"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Machine Type:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Machine Type"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Machine Description:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Machine Description"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Warranty Start Date</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Warranty Start Date"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Warranty End Date:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Warranty End Date"] || "N/A"}</Typography>
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </Paper>

          {/* Issue Information */}
          <Paper sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <Typography variant="subtitle2" sx={{ ml: 2, fontWeight: '500', display: 'flex', alignItems: 'center' }}>
              <IconButton size="small" sx={{ backgroundColor: '#f0b429', borderRadius: '50%', padding: '4px', marginRight: 1 }}>
                <ReportProblem sx={{ color: '#fff' }} />
              </IconButton>
              Issue Information
              <IconButton
                size="small"
                aria-label="expand"
                onClick={() => toggleSection('issue-info')}
                sx={{
                  transform: expandedSection === 'issue-info' ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              >
                {expandedSection === 'issue-info' ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>
            <Collapse in={expandedSection === 'issue-info'} timeout="auto" unmountOnExit>
              <div style={{ padding: '16px', borderRadius: '4px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Problem Description:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Problem Description"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">part Number:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["part Number"]}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">part Desc:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["part Desc"] || "N/A"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="subtitle2" fontWeight="bold">Service Delivery Instruction:</Typography>
                    <Typography variant="body2" color="text.secondary">{task["Service Delivery Instruction"] || "N/A"}</Typography>
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </Paper>

        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};

export default TaskDetailsPage;
