import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  TableSortLabel,
  TextField,Select,MenuItem,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { API_BASE_URL } from "../services/apiService";
import VisibilityIcon from '@mui/icons-material/Visibility';  // Import Visibility Icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
 
const AssignOrderList = ({ onAcknowledgeTask }) => {
  const [tasks, setTasks] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [sortOption, setSortOption] = useState("WO#");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState(""); // For status dropdown
const [dateFilter, setDateFilter] = useState(""); // For date filter dropdown
const navigate = useNavigate(); // Hook to navigate between pages
 
 
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('loggedInUser'));
    if (user) {
      setLoggedInUser(user.username);
    }
  }, []);
 
  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/tasks`);
        const filteredTasks = response.data.filter(
          (task) =>
            task['Tech Assignment'] &&
            task['Tech Assignment'].trim() === loggedInUser
        );
 
        setTasks(filteredTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
      setLoading(false);
    };
 
    if (loggedInUser) {
      fetchTasks();
    }
  }, [loggedInUser]);
 
  const handleAcknowledgeTask = (task) => {
    onAcknowledgeTask(task);
  };
 
  const handleSort = (column) => {
    const isAscending = sortOption === column && sortOrder === 'asc';
    setSortOption(column);
    setSortOrder(isAscending ? 'desc' : 'asc');
  };
 
  const filteredTasks = tasks.filter((task) => {
    const searchRegex = new RegExp(searchTerm, "i");
 
    // Search filter: Check if the task matches the search term
    const matchesSearch =
      (!searchTerm ||
        (task["Case#"] && String(task["Case#"]).match(searchRegex)) ||
        (task["WO#"] && String(task["WO#"]).match(searchRegex)) ||
        (task["part Number"] && String(task["part Number"]).match(searchRegex)) ||
        (task["Customer Name"] && String(task["Customer Name"]).match(searchRegex)));
 
    // Status filter: Check if the task matches the selected status
    const matchesStatus =
      !statusFilter || statusFilter === "All Status" || task["Status"] === statusFilter;
 
    // Date filter: Check if the task matches the selected date range
    const matchesDate = (() => {
      if (!dateFilter || dateFilter === "All") return true;
 
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to midnight
      const dateFields = [
        task["Service Completion Date"] && new Date(task["Service Completion Date"]),
        task["Pause Date"] && new Date(task["Pause Date"]),
        task["Acknowledge Date"] && new Date(task["Acknowledge Date"]),
        task["Assign Ticket Date"] && (() => {
          const assignTicketDateString = task["Assign Ticket Date"];
          if (assignTicketDateString) {
            const [day, month, yearTime] = assignTicketDateString.split("/");
            const [year, time] = yearTime.split(", ");
            const [hourMinute, period] = time.split(" ");
            const [hour, minute] = hourMinute.split(":");
            return new Date(`${month}/${day}/${year} ${hour}:${minute} ${period}`);
          }
          return null;
        })(),
      ].filter(Boolean); // Filter out null/undefined values
 
      // Check if any date field matches the selected range
      return dateFields.some((date) => {
        if (!date) return false;
        switch (dateFilter) {
          case "today":
            return date.toDateString() === today.toDateString();
          case "last1":
            const last1 = new Date(today);
            last1.setDate(last1.getDate() - 1);
            return date >= last1 && date <= today;
          case "last7":
            const last7 = new Date(today);
            last7.setDate(last7.getDate() - 7);
            return date >= last7 && date <= today;
          case "last30":
            const last30 = new Date(today);
            last30.setDate(last30.getDate() - 30);
            return date >= last30 && date <= today;
          default:
            return true;
        }
      });
    })();
 
    // Combine all filters: Task must match all active filters
    return matchesSearch && matchesStatus && matchesDate;
  });
 
 
  const sortedTasks = [...filteredTasks].sort((a, b) => {
    const valueA = a[sortOption] ?? '';
    const valueB = b[sortOption] ?? '';
    const isNumeric = !isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB));
 
    if (isNumeric) {
      return sortOrder === 'asc'
        ? parseFloat(valueA) - parseFloat(valueB)
        : parseFloat(valueB) - parseFloat(valueA);
    } else {
      return sortOrder === 'asc'
        ? valueA.toString().localeCompare(valueB.toString())
        : valueB.toString().localeCompare(valueA.toString());
    }
  });
 
  // Paginate the tasks
  const paginatedTasks = sortedTasks.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
 
  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };
 
  const renderDate = (date) => {
    return date ? new Date(date).toLocaleString() : "N/A";
  };
 
  const handleSearchChange = (event) => {
    console.log("Search term:", event.target.value);
    setSearchTerm(event.target.value);
  };
 
  const handleViewTask = (task) => {
    // Navigate to the new page with task details
    navigate(`/task-details/${task["Case#"]}`, { state: { task } });
  };
 
  return (
  <Box
      sx={{
        p: 2,
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden", // Prevent horizontal overflow
        mt: -6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "linear-gradient(90deg, #e3f2fd, #bbdefb)",
          padding: "10px 15px",
          borderRadius: "10px",
          color: "#424242",
          mb: 2,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          width: "100%",
          height: "auto",
        }}
        >
     
      <Grid container spacing={2} sx={{ mb: 1}} justifyContent="center" >
        {/* Status Filter */}
        <Grid item xs={12} sm={3}>
          <Box sx={{ width: '100%' }}>
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              displayEmpty
              size="small"
              sx={{
                width: '100%',                
                backgroundColor: '#fafafa',    
                borderRadius: '10px',          
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                fontFamily: 'Roboto, sans-serif',  
                '& .MuiSelect-icon': {
                  color: '#5c6bc0',          
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#e0e0e0',    
                  },
                  '&:hover fieldset': {
                    borderColor: '#3f51b5',  
                  },
                },
              }}
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="Started">Started</MenuItem>
              <MenuItem value="Technician Assigned">Technician Assigned</MenuItem>
              <MenuItem value="Fixed">Fixed</MenuItem>
              <MenuItem value="Paused">Paused</MenuItem>
              <MenuItem value="Rescheduled">Rescheduled</MenuItem>
 
            </Select>
          </Box>
        </Grid>
        {/* Date Filter */}
        <Grid item xs={12} sm={3}>
          <Box sx={{ width: '100%' }}>
            <Select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              displayEmpty
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#fafafa',
                borderRadius: '10px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                fontFamily: 'Roboto, sans-serif',
                '& .MuiSelect-icon': {
                  color: '#5c6bc0',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#3f51b5',
                  },
                },
              }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="last1">Last 1 Day</MenuItem>
              <MenuItem value="last7">Last 7 Days</MenuItem>
              <MenuItem value="last30">Last 30 Days</MenuItem>
            </Select>
          </Box>
        </Grid>
 
        {/* Search Field */}
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search Case/WO"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "25px",
                backgroundColor: "#f5f5f5",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              },
              "& .MuiInputBase-root": {
                fontFamily: "Open Sans, sans-serif",
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => console.log(searchTerm)}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
     
     
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            fontSize: "18px",
            color: "#37474f",
          }}
        >
          Total Tickets:
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            color: "#f0f0f",
          }}
        >
          {filteredTasks.length}
        </Typography>
      </Box>
   
     
      {loading ? (
        <Typography>Loading tasks...</Typography>
      ) : filteredTasks.length > 0 ? (
        <>
          {/* Table to display tasks */}
          <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
 
                  {/* Case Number Column */}
                  <TableCell>
                    <TableSortLabel
                      active={sortOption === "Case#"}
                      direction={sortOption === "Case#" ? sortOrder : "asc"}
                      onClick={() => handleSort("Case#")}
                    >
                      Case Number
                    </TableSortLabel>
                  </TableCell>
 
                  {/* WO Number Column */}
                  <TableCell>
                    <TableSortLabel
                      active={sortOption === "WO#"}
                      direction={sortOption === "WO#" ? sortOrder : "asc"}
                      onClick={() => handleSort("WO#")}
                    >
                      WO Number
                    </TableSortLabel>
                  </TableCell>
 
                  {/* Other columns */}
                  <TableCell>Part Number</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Mobile Number</TableCell>
                  {/* <TableCell>Customer Address</TableCell> */}
                  <TableCell>Status</TableCell>
                  <TableCell>Service Completion Date</TableCell>
                  <TableCell>Assign Ticket Date</TableCell>
 
                  <TableCell>Pause Reason</TableCell>
                  <TableCell>Completion Remark</TableCell>
                  <TableCell>ECT</TableCell>
                  <TableCell>View / Acknowledge</TableCell>
                </TableRow>
              </TableHead>
 
              <TableBody>
                {/* Display each task in a table row */}
                {paginatedTasks.map((task, index) => (
                  <TableRow key={index} style={{ cursor: "pointer" }}>
                    <TableCell>{task["Case#"]}</TableCell>
                    <TableCell>{task["WO#"]}</TableCell>
                    <TableCell>{task["part Number"]}</TableCell>
                    <TableCell>{task["Customer Name"]}</TableCell>
                    <TableCell>{task["Contact Number"]}</TableCell>
                    {/* <TableCell>{task["Customer Address"]}</TableCell> */}
                    <TableCell>{task["Status"]}</TableCell>
                    <TableCell>
                      {renderDate(task["Service Completion Date"])}
                    </TableCell>
                    <TableCell>
                      {(task["Assign Ticket Date"])}
                    </TableCell>
                    <TableCell>{task["Pause Reason"] || "N/A"}</TableCell>
                    <TableCell>{task["Completion Remark"] || "N/A"}</TableCell>
                    <TableCell>{task["ECT"]}</TableCell>
 
                    {/* Acknowledge Button */}
                   <TableCell align="center">
                  <IconButton
                    onClick={() => handleViewTask(task)}
                    color="primary"
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleAcknowledgeTask(task)}
                    color="secondary"
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
 
          {/* Pagination Controls */}
          <TablePagination
  rowsPerPageOptions={[25, 50, 100]} // Updated options
  component="div"
  count={filteredTasks.length} // Total number of filtered tasks
  rowsPerPage={rowsPerPage} // Current rows per page
  page={page} // Current page number
  onPageChange={handleChangePage} // Page change handler
  onRowsPerPageChange={(event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Set rows per page
    setPage(0); // Reset to the first page
  }}
/>
 
        </>
      ) : (
        // Message when no tasks are found
        <Typography>No tasks found for {loggedInUser || "User"}.</Typography>
      )}
    </Box>
  );
};
 
export default AssignOrderList;
