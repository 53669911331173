import React, { useEffect, useState } from 'react';
import {Box,Typography,CircularProgress,Select,MenuItem,Modal,Paper,Grid,Card,Divider,CardContent} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faCheckCircle, faPauseCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { styled } from '@mui/material/styles';
import { API_BASE_URL } from '../services/apiService'; // Import Material UI Icons
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // To handle UTC time
import timezone from 'dayjs/plugin/timezone'; // To handle timezones like IST
 
 
 
dayjs.extend(utc);
dayjs.extend(timezone);
 
const localizer = momentLocalizer(moment);
 
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 50 * 6.5 + 10,
      width: 100,
      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
      borderRadius: '18px',
      overflow: 'auto',
    },
  },
 
};
 
// Styled components
const StyledCalendar = styled(Calendar)(({ theme }) => ({
  height: '650px',
  margin: '20px',
  borderRadius: '8px',
  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
  '& .rbc-event': {
    backgroundColor: '#4A90E2',
    color: '#fff',
    borderRadius: '8px',
    padding: '4px',
    fontSize: '12px',
    width:'50%',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
    }
  },
  '& .rbc-toolbar button': {
  backgroundColor: '#8AC7AD ',
  color: '#fff',
  borderRadius: '6px',
  fontWeight: '500',
  padding: '8px 12px',
  border: 'none',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
 
  '&:hover': {
    boxShadow: '0px 6px 15px #0A481E',
    transform: 'scale(1.05)',
  },
 
  '&:active': {
    backgroundColor: 'DodgerBlue',
    transform: 'scale(0.95)',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
  },
},
 
'& .rbc-toolbar button:disabled': {
  backgroundColor:'DodgerBlue',
 
  color:'#fff',
  cursor: 'not-allowed',
  boxShadow: 'none',
},
 
  '& .rbc-today': {
    backgroundColor: '#E3F2FD',
    borderRadius: '4px',
    fontWeight: 'bold',
  },
 
  '& .rbc-off-range-bg': {
    backgroundColor: '#f9f9f9',
  },
  '& .rbc-header': {
    backgroundColor: 'skyblue',
    color: '#fff',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    padding: '10px',
    borderRadius: '8px 8px 0 0',
  },
 
  // Month name styling
  '& .rbc-toolbar-label': {
    fontSize: '18px',
    fontWeight: '500',
    color: '#333',
    letterSpacing: '1px',
    textTransform: 'capitalize',
    padding: '8px',
    background: 'linear-gradient(45deg, #4A90E2, #00C4CC)',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    margin: '0 20px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
    },
  },
 
  '& .rbc-toolbar': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
 
  // Calendar cell styles
  '& .rbc-day-bg': {
    padding: '6px',
    borderRadius: '8px',
  },
 
  '& .rbc-weekend': {
    backgroundColor: '#f0f4f8',
  },
 
  // Event tooltip styling when hovering over event blocks
  '& .rbc-tooltip': {
    backgroundColor: 'red',
    color: 'red',
    borderRadius: '4px',
    padding: '5px',
    fontSize: '10px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
  },
}));
 
// Status-based color styling
const getStatusStyle = (status) => {
  switch (status) {
    case 'Fixed':
      return { backgroundColor: '#4caf50', color: 'white' }; // Green for Fixed
    case 'Paused':
      return { backgroundColor: '#ffeb3b', color: 'black' }; // Yellow for Paused
    case 'Started':
      return { backgroundColor: '#2196f3', color: 'white' }; // Blue for Started
    default:
      return { backgroundColor: 'gray', color: 'white' }; // Gray for Default
  }
};
 
const ResourceUtilization = () => {
  const [techAssignments, setTechAssignments] = useState([]);
  const [selectedTechAssignment, setSelectedTechAssignment] = useState('FSE users');
  const [taskEvents, setTaskEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({ Fixed: 0, paused: 0, started: 0 });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentView, setCurrentView] = useState('month'); // Track current view
  const [currentDate, setCurrentDate] = useState(new Date()); // Track current date range
 
  useEffect(() => {
    // Fetch tech assignments and all FSE users' tasks by default on load
    fetchTechAssignments();
    fetchAllUsersTasks();
  }, []);
 
  useEffect(() => {
    // If 'FSE users' is selected, fetch all tasks
    if (selectedTechAssignment === "FSE users" || !selectedTechAssignment) {
      fetchAllUsersTasks();  // Fetch tasks for all users
    } else {
      fetchTaskData(selectedTechAssignment);  // Fetch tasks for the selected user
    }
  }, [selectedTechAssignment]);
 
 
  useEffect(() => {
    // Update summary counts when view, date range, or task events change
    updateSummaryCounts(currentView, currentDate);
  }, [currentView, currentDate, taskEvents]); // Dependencies: When any of these change, the summary is updated
 
 
  const fetchTechAssignments = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/tech-assignments`);
      setTechAssignments(response.data || []);
      console.log('Fetched Tech Assignments:', response.data);
    } catch (error) {
      console.error('Error fetching tech assignments:', error);
    }
  };
 
  const handleTechAssignmentChange = (event) => {
    const value = event.target.value;
    console.log('Selected Tech Assignment:', value);  // Log to check the value
    setSelectedTechAssignment(value);  // Set the selected user or 'FSE users'
  };
 
  const fetchTaskData = async (assignment) => {
    setLoading(true);
    try {
      const url = assignment
        ? `${API_BASE_URL}/api/tasks?techAssignment=${assignment}`
        : `${API_BASE_URL}/api/tasks`; // Fetch all users if no assignment
      const response = await axios.get(url);
      const tasks = response.data;
 
      console.log("Fetched Tasks:", tasks);
 
      // Apply filtering for individual users
      const filteredTasks = assignment
        ? tasks.filter((task) => task["Tech Assignment"] === assignment)
        : tasks;
 
      console.log("Filtered Tasks:", filteredTasks);
 
      const events = filteredTasks
        .filter((task) => task["Service Completion Date"] || task["Pause Date"] || task["Acknowledge Date"]) // Ensure tasks have relevant dates
        .map((task) => {
          const status = task["Status"];
          const relevantDate = task["Service Completion Date"] || task["Pause Date"] || task["Acknowledge Date"];
          const relevantTime = task["Pause Time"] || task["Completion Time"] || task["Acknowledge Time"];
 
          if (!moment(relevantDate).isValid()) {
            console.warn(`Skipping task ${task["WO#"]}, invalid date: ${relevantDate}`);
            return null; // Skip invalid tasks
          }
 
          // Parse and combine date and time
          const parsedDate = moment(relevantDate);
          if (relevantTime) {
            const [hours, minutes, seconds] = relevantTime
              .split(/[: ]/)
              .map((v, i) => (i < 3 ? parseInt(v, 10) : v.toLowerCase()));
            const isPM = relevantTime.toLowerCase().includes("pm");
            const finalHours = isPM && hours !== 12 ? hours + 12 : !isPM && hours === 12 ? 0 : hours;
            parsedDate.hour(finalHours).minute(minutes).second(seconds || 0);
          }
 
          if (!parsedDate.isValid()) {
            console.warn(`Invalid combined date-time for task ${task["WO#"]}`);
            return null;
          }
 
          const taskStartDate = parsedDate.toDate();
          const taskEndDate = new Date(taskStartDate.getTime() + 60 * 60 * 1000); // Default 1-hour duration
 
          console.log("Task Start Date:", taskStartDate);
 
          return {
            id: task._id,
            title: `WO#: ${task["WO#"]} - ${status}`,
            start: taskStartDate,
            end: taskEndDate,
            taskData: task,
            style: getStatusStyle(status),
          };
        })
        .filter((event) => event !== null)
        .sort((a, b) => a.start - b.start); // Ensure chronological order
 
      console.log("Processed Events:", events);
 
      setTaskEvents(events);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      alert("Failed to fetch tasks. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
 
 
  const fetchAllUsersTasks = async () => {
    setLoading(true);
    try {
      const url = `${API_BASE_URL}/api/tasks?techAssignment=FSE users`; // Fetch tasks for all FSE users
      console.log(`Fetching all tasks for FSE users from: ${url}`);
 
      const response = await axios.get(url);
      const tasks = response.data;
 
      const events = tasks
        .filter((task) => task["Pause Date"] || task["Service Completion Date"] || task["Acknowledge Date"]) // Relevant dates
        .map((task) => {
          const { Status: status } = task;
 
          // Determine relevant date and time
          let relevantDate = task["Pause Date"] || task["Service Completion Date"] || task["Acknowledge Date"];
          const relevantTime =
            task["Pause Time"] || task["Completion Time"] || task["Acknowledge Time"];
 
          if (!relevantDate || !moment(relevantDate).isValid()) {
            console.warn(`Skipping task ${task["WO#"]}, invalid date.`);
            return null;
          }
 
          // Parse the date and time into a valid datetime object
          const parsedDate = moment(relevantDate);
          if (relevantTime) {
            const [hours, minutes, seconds] = relevantTime
              .split(/[: ]/)
              .map((v, i) => (i < 3 ? parseInt(v, 10) : v.toLowerCase())); // Extract hours, minutes, seconds
            const isPM = relevantTime.includes("pm");
            const finalHours = isPM && hours !== 12 ? hours + 12 : !isPM && hours === 12 ? 0 : hours; // Adjust for 12-hour clock
            parsedDate.hour(finalHours).minute(minutes).second(seconds || 0);
          }
 
          const taskStartDate = parsedDate.toDate(); // Get as JavaScript Date
 
          if (!taskStartDate) {
            console.warn(`Skipping task ${task["WO#"]}, invalid start datetime.`);
            return null;
          }
 
          // Example: Default 1-hour duration
          const taskEndDate = new Date(taskStartDate.getTime() + 60 * 60 * 1000);
 
          return {
            id: task._id,
            title: `WO#: ${task["WO#"]} - ${status}`,
            start: taskStartDate,
            end: taskEndDate, // Use calculated end time
            taskData: task,
            style: getStatusStyle(status),
          };
        })
        .filter((event) => event !== null); // Remove invalid events
 
      // Log the processed events to ensure they are correctly mapped
      console.log("Updated Task Events:", events);
 
      // If no events are available, log that no tasks were found
      if (events.length === 0) {
        console.log("No events found for the selected user.");
      }
 
      setTaskEvents(events); // Update the state with the events
 
    } catch (error) {
      console.error("Error fetching tasks for all users:", error);
      alert("Failed to fetch tasks. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false once the fetch is complete
    }
  };
 
 
  const updateSummaryCounts = (view, date) => {
  const startOfView = moment(date).startOf(view).toDate();
  const endOfView = moment(date).endOf(view).toDate();
 
  // Ensure taskEvents is defined
  if (!taskEvents || taskEvents.length === 0) {
    setSummary({ Fixed: 0, paused: 0, started: 0 });
    return;
  }
 
  const filteredTasks = taskEvents.filter((event) => {
    const eventDate = moment(event.start).toDate();
    return eventDate >= startOfView && eventDate <= endOfView;
  });
 
  const FixedCount = filteredTasks.filter(
    (event) => event.taskData["Status"] === "Fixed"
  ).length;
  const pausedCount = filteredTasks.filter(
    (event) => event.taskData["Status"] === "Paused"
  ).length;
  const startedCount = filteredTasks.filter(
    (event) => event.taskData["Status"] === "Started"
  ).length;
 
  setSummary({ Fixed: FixedCount, paused: pausedCount, started: startedCount });
};
useEffect(() => {
  console.log("Updated taskEvents:", taskEvents);
}, [taskEvents]);
 
const handleViewChange = (view) => {
  console.log("View changed to:", view);
  setCurrentView(view);
};
 
const handleNavigate = (date) => {
  // Ensure date is valid
  if (!moment(date).isValid()) {
    console.error("Invalid date passed for navigation");
    return;
  }
  setCurrentDate(date);
};
 
const handleEventSelect = (event) => {
  console.log("Selected event:", event);
  setSelectedEvent(event);
  setModalOpen(true);
};
 
const handleCloseModal = () => {
  setModalOpen(false);
  setSelectedEvent(null);
};
 
const eventPropGetter = (event) => ({
  style: event.style,
});
 
const handleDrawerToggle = () => {
  setDrawerOpen(!drawerOpen);
};
 
 
  return (
<Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden', position: 'relative'  }}>
  {/* SideDrawer */}
  <Box
    sx={{
      width: drawerOpen ? '240px' : '0', // Adjust width dynamically
      flexShrink: 0,
      transition: 'width 0.3s ease',
      backgroundColor: '#fff',
      boxShadow: drawerOpen ? '2px 0 5px rgba(0, 0, 0, 0.1)' : 'none',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1100,
      overflowY: 'auto',
    }}>
    <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
  </Box>
 
  {/* Main Content Wrapper */}
  <Box sx={{
            marginLeft: drawerOpen ? '180px' : '0', // Adjust for sidebar width
            transition: 'margin-left 0.3s ease',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}>
    {/* Header */}
    <Box sx={{ position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '64px',
              backgroundColor: '#fff',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              zIndex: 1200,}}>
      <Header handleDrawerToggle={handleDrawerToggle} title="Admin Dashboard" />
    </Box>
 
    {/* Main Content Area */}
    <Box sx={{flexGrow: 1,overflow: 'auto',padding: '20px',backgroundColor: '#f9f9f9',marginTop: '64px',marginLeft:7,}}>
       <Box sx={{backgroundColor: 'secondary.main',padding: '10px 20px', borderRadius: '8px', marginBottom: '20px', }}>
        <Typography variant="h6"sx={{fontWeight: '400',
            color: '#fff',
            letterSpacing: '5px',
            textAlign:'center',
          }}
        >
           Resource Utilization Dashboard
        </Typography>
      </Box>
 
      {/* Select Dropdown */}
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: 4, mt:5}}>
        <Grid item xs={6} md={6}>
          <Select
            value={selectedTechAssignment}
            onChange={handleTechAssignmentChange}
            fullWidth
            sx={{
              backgroundColor: '#f9f9f9',
              borderRadius: '50px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'DodgerBlue',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'DodgerBlue',
              },
              '& .MuiSelect-icon': {
                color: '#2196f3',
              },
            }}
            MenuProps={MenuProps} // Ensure this is properly defined if needed
          >
            {/* Menu item for "All FSE Users" */}
            <MenuItem value="FSE users">
              <FontAwesomeIcon icon={faUser} style={{ marginLeft: '10px', marginRight: '12px', fontSize: '18px', color: '#2196f3' }} />
              <em>All FSE USERS</em>
            </MenuItem>
 
            {/* Render other assignments dynamically */}
            {techAssignments.map((assignment) => (
              <MenuItem
                key={assignment} // If 'assignment' is a string or has a unique ID, use that key
                value={assignment} // Assuming 'assignment' is a string, adjust if it's an object
                sx={{
                  '&:hover': {
                    backgroundColor: 'gray',
                    color: 'white',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'gray',
                    color: 'white',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: 'silver',
                  },
                }}
              >
                {assignment}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
 
      {/* Summary Boxes */}
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          {/* Fixed */}
            <Grid item>
              <Box
                sx={{
                  backgroundColor: '#4caf50',
                  p: 2,
                  borderRadius: '12px',
                  color: 'white',
                  fontWeight: '400',
                  width: '130px',
                  display: 'flex', // Flex layout
                  alignItems: 'center', // Vertically center
                  gap: 1, // Space between items
                  justifyContent: 'space-between', // Space out the items
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: 'uppercase',fontSize: '0.85rem',   fontWeight: '450',                
                  }}
                >
                  Fixed
                </Typography>
                <Typography variant="h6"sx={{fontSize: '0.85rem',fontWeight: '450',}}>
                  {summary.Fixed}
                </Typography>
              </Box>
            </Grid>
 
            {/* Paused */}
            <Grid item>
              <Box
                sx={{
                  backgroundColor: '#ffeb3b',
                  p: 2,
                  borderRadius: '8px',
                  color: 'black',
                  fontWeight: '400',
                  width: '110px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  justifyContent: 'space-between',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                <FontAwesomeIcon icon={faPauseCircle} size="1x" />
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '0.85rem',
                    fontWeight: '500',
                  }}
                >
                  Paused
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '0.85rem',
                    fontWeight: 'bold',
                  }}
                >
                  {summary.paused}
                </Typography>
              </Box>
            </Grid>
 
            {/* Started */}
            <Grid item>
              <Box
                sx={{
                  backgroundColor: '#2196f3',
                  p: 2,
                  borderRadius: '12px',
                  color: 'white',
                  fontWeight: '500',
                  width: '110px',
                  display: 'flex', // Flex layout
                  alignItems: 'center', // Vertically center
                  gap: 1, // Space between items
                  justifyContent: 'space-between', // Space out the items
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                <FontAwesomeIcon icon={faPlayCircle} size="1x" />
                <Typography variant="h6" sx={{ textTransform: 'uppercase',fontSize: '0.85rem',fontWeight: '500',}}>
                  Started
                </Typography>
                <Typography variant="h6" sx={{fontSize: '0.85rem', fontWeight: 'bold',}}>
                  {summary.started}
                </Typography>
              </Box>
            </Grid>
          </Grid>
      </Box>
 
      {/* Calendar or Loading Indicator */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <StyledCalendar
          localizer={localizer}
          // key={selectedTechAssignment}
          events={taskEvents}
          onSelectEvent={handleEventSelect}
          startAccessor="start"
          endAccessor="end"
          views={['month', 'day', 'agenda']}
          defaultView="month"
          eventPropGetter={eventPropGetter}
          onView={handleViewChange} // Called when the view changes
          onNavigate={handleNavigate} // Called when the date range changes
          style={{
            borderRadius: '12px',
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)',
            background: 'linear-gradient(to top right, #ffffff, #f0f4f8)',
            padding: '35px',
            transition: 'all 0.3s ease-in-out',
            border: '1px solid #2196F3',
          }}
        />
      )}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          component={Paper}
          sx={{
            width: '400px',
            p: 2,
            borderRadius: '8px',
            margin: 'auto',
            mt: '12%',
            backgroundColor: '#f0f0f0',
          }}
        >
            {selectedEvent && (
              <Card
                elevation={3}
                sx={{
                  padding: 0,
                  backgroundColor: '#f9f9f9',
                  borderRadius: 2,
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  maxWidth: 500,
                  margin: '0 auto',
                  justifyContent:'center',
                  alignItems:'center',
                }}
              >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', marginBottom: 1, textAlign: 'center', color: 'purple' }}
                  gutterBottom
                >
                  Task Details
                </Typography>
 
                <Divider sx={{ marginBottom: 2, color: 'purple' }} />
 
                <Grid container spacing={1}>
                  {/* WO# */}
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>WO#:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{selectedEvent.taskData["WO#"]}</Typography>
                  </Grid>
 
                  {/* Status */}
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Status:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: selectedEvent.style.color,
                        backgroundColor: selectedEvent.style.backgroundColor,
                        padding: '4px 8px',
                        borderRadius: 1,
                        display: 'inline-block',
                      }}
                    >
                      {selectedEvent.taskData["Status"]}
                    </Typography>
                  </Grid>
 
                  {/* Conditionally render based on status */}
 
                  {/* If the status is "Fixed" */}
                  {selectedEvent.taskData["Status"] === "Fixed" && (
                    <>
                      {/* Service Completion Date */}
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Service Completion Date:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {selectedEvent.taskData["Service Completion Date"]
                            ? selectedEvent.taskData["Service Completion Date"]
                            : "Not Available"}
                        </Typography>
                      </Grid>
 
                      {/* Time Stamp */}
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Time Stamp:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {selectedEvent.taskData["Time Stamp"]
                            ? selectedEvent.taskData["Time Stamp"]
                            : "Not Available"}
                        </Typography>
                      </Grid>
                    </>
                  )}
 
                  {/* If the status is "Paused" */}
                  {selectedEvent.taskData["Status"] === "Paused" && (
                    <>
                      {/* Pause Date */}
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Pause Date:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {selectedEvent.taskData["Pause Date"]
                            ? new Date(selectedEvent.taskData["Pause Date"]).toLocaleDateString("en-IN")
                            : "Not Available"}
                        </Typography>
                      </Grid>
 
                      {/* Pause Time */}
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Pause Time:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {selectedEvent.taskData["Pause Time"]
                            ? selectedEvent.taskData["Pause Time"]
                            : "Not Available"}
                        </Typography>
                      </Grid>
 
                      {/* Time Stamp for Paused */}
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Time Stamp:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {selectedEvent.taskData["Time Stamp"]
                            ? selectedEvent.taskData["Time Stamp"]
                            : "Not Available"}
                        </Typography>
                      </Grid>
                    </>
                  )}
 
                  {/* If the status is "Started" */}
                    {selectedEvent.taskData["Status"] === "Started" && (
                      <>
                        {/* Debugging log */}
                        <div>{console.log(selectedEvent.taskData)}</div>
 
                        {/* Acknowledge Date */}
                        <Grid item xs={6}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Acknowledge Date:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            {selectedEvent.taskData["Acknowledge Date"]
                              ? new Date(selectedEvent.taskData["Acknowledge Date"]).toLocaleDateString("en-IN")
                              : "Not Available"}
                          </Typography>
                        </Grid>
 
                        {/* Acknowledge Time */}
                        <Grid item xs={6}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Acknowledge Time:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            {selectedEvent.taskData["Acknowledge Time"]
                              ? selectedEvent.taskData["Acknowledge Time"]
                              : "Not Available"}
                          </Typography>
                        </Grid>
 
                        {/* Time Stamp for Started */}
                        <Grid item xs={6}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Time Stamp:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            {selectedEvent.taskData["Time Stamp"]
                              ? selectedEvent.taskData["Time Stamp"]
                              : "Not Available"}
                          </Typography>
                        </Grid>
                      </>
                    )}
                </Grid>
              </CardContent>
              </Card>
            )}
 
        </Box>
      </Modal>
 
    </Box>
 
    {/* Footer */}
    <Box
          sx={{
            flexShrink: 0,
            height: '40px', // Fixed footer height
            backgroundColor: '#fff',
            boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Footer />
        </Box>
  </Box>
</Box>
 
   
  );
};
 
export default ResourceUtilization;
 
 