import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Toolbar, Grid, Card, CardContent,MenuItem, Select,TextField, FormControl, InputLabel, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, BarChart,Label, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';
import AssignTaskOrderTable from './AssignTaskOrderTable';
import axios from 'axios';
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { API_BASE_URL } from '../services/apiService';// Import Material UI Icons
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AlarmIcon from '@mui/icons-material/Alarm';
import BuildIcon from '@mui/icons-material/Build';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RepeatIcon from '@mui/icons-material/Repeat';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import BusinessIcon from '@mui/icons-material/Business';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SecurityIcon from '@mui/icons-material/Security';
import PolicyIcon from '@mui/icons-material/Policy';
import VerifiedIcon from '@mui/icons-material/Verified';
import LayersIcon from '@mui/icons-material/Layers';
import PowerIcon from '@mui/icons-material/Power';
import WarningIcon from '@mui/icons-material/Warning'; // Make sure this is imported
 
// Define new fonts
const titleFont = "'Roboto', sans-serif";
const countFont = "'Open Sans', sans-serif";
 
// Colors for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];
 
const AdminDashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showTaskOrder, setShowTaskOrder] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [t3bData, setT3bData] = useState([]); // State for T3B data
  const navigate = useNavigate(); // Correctly use useNavigate
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs().startOf("month"), dayjs().endOf("month")]);
  const [quickRange, setQuickRange] = useState("thisMonth"); // Default set to "This Month"
  const [rrrValue, setRRRValue] = useState(0); // State for RRR value
  const [filter, setFilter] = useState('Overall');
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [ProgramCounts, setProgramCounts] = useState([]);
 
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
 
  const handleMenuClick = (menuItem) => {
    if (menuItem === 'Assign Task Order') {
      setShowTaskOrder(true);  
    } else {
      setShowTaskOrder(false);
       
    }
  };
 
  const handleCardClick = (filterKey) => {
    if (filterKey === 'T3B') {
      navigate('/t3b-details', { state: { t3bData }, replace: true });
    } else if (filterKey === 'ECT') {
      navigate('/ectdetail', { state: { taskData }, replace: true });
    } else if (filterKey === 'RRR') {
      const rrrData = taskData.filter((task) => task.Code === 'RRR'); // Filter RRR data
      navigate('/rrr-page', { state: { rrrData }, replace: true }); // Navigate with data
    }
  };
 
  const handleQuickRangeChange = (event) => {
    const value = event.target.value;
    setQuickRange(value);
 
    const now = dayjs(); // Ensure consistent dayjs instance
    let newRange = [null, null]; // Default range
 
    switch (value) {
      case "today":
        newRange = [now.startOf("day"), now.endOf("day")];
        break;
      case "last1Day":
        newRange = [now.subtract(1, "day").startOf("day"), now.subtract(1, "day").endOf("day")];
        break;
      case "last7Days":
        newRange = [now.subtract(7, "days").startOf("day"), now.endOf("day")];
        break;
      case "lastWeek":
        newRange = [now.subtract(1, "week").startOf("week"), now.subtract(1, "week").endOf("week")];
        break;
      case "thisMonth":
        newRange = [now.startOf("month"), now.endOf("month")];
        break;
      case "lastMonth":
        newRange = [now.subtract(1, "month").startOf("month"), now.subtract(1, "month").endOf("month")];
        break;
      case "All Tickets":
        newRange = [null, null];
        setFilteredTaskData(taskData); // Show all tickets
        break;
      default:
        newRange = [null, null]; // Reset
    }
 
    setDateRange(newRange); // Update the date range
  };
 
  useEffect(() => {
    console.log("Filtered Task Data:", filteredTaskData); // Debugging purpose
  }, [filteredTaskData]);
 
  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/tasks`);
        const data = response.data; // Define 'data' here for use later
        setTaskData(data);
 
        // Calculate RRR
        const repeatData = data.filter((task, index, arr) =>
          arr.some(
            (otherTask) =>
              task['MSN#'] === otherTask['MSN#'] &&
              task['part Number'] === otherTask['part Number'] &&
              task['WO#'] !== otherTask['WO#']
          )
        );
 
        const repeatCount = repeatData.length;
        const totalCount = data.length;
        const calculatedRRR = totalCount > 0 ? ((repeatCount / totalCount) * 100).toFixed(2) : 0;
 
        setRRRValue(calculatedRRR);
      } catch (error) {
        console.error('Error fetching task data:', error);
      }
    };
 
    const fetchT3BData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/t3b-data`);
        console.log('T3B Data:', response.data); // Check the response data
        setT3bData(response.data);
      } catch (error) {
        console.error('Error fetching T3B data:', error);
      }
    };
 
    // Call both fetch functions
    fetchTaskData();
    fetchT3BData();
  }, []);
 
 
 
  // Calculate different metrics
  // Filter function to return tasks based on the selected date and status
  const filterByDateAndStatus = (taskData, selectedDate, statusField, statusValue) => {
    if (!Array.isArray(taskData)) return []; // Ensure taskData is an array
 
    return taskData.filter(task => {
      const taskDate = task["New Ticket Date"] ? dayjs(task["New Ticket Date"]) : null; // Parse the date safely
      const isDateInRange =
        selectedDate[0] && selectedDate[1]
          ? taskDate && taskDate.isValid() && taskDate.isBetween(selectedDate[0], selectedDate[1], null, '[]')
          : true; // If no date range, include all dates
 
      return task[statusField] === statusValue && isDateInRange; // Match status and date range
    });
  };
 
 
  useEffect(() => {
    // Filter the task data based on the selected date range and part status
    if (dateRange[0] && dateRange[1]) {
      const filteredData = taskData.filter(task => {
        const taskDate = dayjs(task["New Ticket Date"]); // Ensure date is valid
        const partStatus = task["Part Status"]; // Get the part status (e.g., "Shipped" or "Labor Only")
 
        // Check if the task date is within the selected range and if part status matches
        return (
          taskDate.isValid() &&
          taskDate.isBetween(dateRange[0], dateRange[1], null, '[]') && // Check date range
          (partStatus === "Shipped" || partStatus === "Labor Only" || partStatus === "Delivered" || partStatus === "Pending") // Check part status
        );
      });
 
      setFilteredTaskData(filteredData); // Update filtered data
    } else {
      setFilteredTaskData(taskData); // No date range, show all tasks
    }
  }, [dateRange, taskData]);
 
  useEffect(() => {
    const allowedPrograms = [
      "Standard Commercial",
      "Premium Care",
      "Premier Support",
      "Standard Consumer",
      "Smart Managed Solutions",
    ];
 
    const startDate = selectedDate[0] ? dayjs(selectedDate[0]) : null;
    const endDate = selectedDate[1] ? dayjs(selectedDate[1]) : null;
 
    // Step 1: Filter task data based on the selected date range and program status
    const filteredData = taskData.filter(task => {
      const taskDate = task["New Ticket Date"] ? dayjs(task["New Ticket Date"]) : null; // Safely parse task date
 
      // If a date range is selected, filter based on "New Ticket Date"
      const isDateInRange = startDate && endDate
        ? taskDate && taskDate.isValid() && taskDate.isBetween(startDate, endDate, null, '[]') // Date within range
        : true; // No date range: include all tasks
 
      const isProgramValid = allowedPrograms.includes(task["Program"]); // Filter by program
 
      return (isDateInRange && isProgramValid); // Return true if both date and program match
    });
 
    // Step 2: Calculate counts for each program based on filtered data (consider date range and program)
    const programCounts = allowedPrograms.map(program => ({
      name: program,
      count: filteredData.reduce((count, task) => {
        return task.Program === program ? count + 1 : count;
      }, 0), // Count tasks for each program
    }));
 
    // Step 3: Set filtered data and program counts to update the chart and display
    setFilteredTaskData(filteredData); // Set filtered data (if needed elsewhere)
    setProgramCounts(programCounts); // Set program counts for the chart
 
  }, [taskData, selectedDate]); // Re-run when taskData or selectedDate changes
 
 
 
  const programCounts = [
    { name: "Standard Commercial", count: filteredTaskData.filter(task => task.Program === "Standard Commercial").length },
    { name: "Premium Care", count: filteredTaskData.filter(task => task.Program === "Premium Care").length },
    { name: "Premier Support", count: filteredTaskData.filter(task => task.Program === "Premier Support").length },
    { name: "Standard Consumer", count: filteredTaskData.filter(task => task.Program === "Standard Consumer").length },
    { name: "Smart Managed Solutions", count: filteredTaskData.filter(task => task.Program === "Smart Managed Solutions").length }
  ];
 
 
 
  const shippedCount = filteredTaskData.filter(task => task["Part Status"] === "Shipped").length;
  const laborOnlyCount = filteredTaskData.filter(task => task["Part Status"] === "Labor Only").length;
  const DeliveredCount = filteredTaskData.filter(task => task["Part Status"] === "Delivered").length;
  const PendingCount = filteredTaskData.filter(task => task["Part Status"] === "Pending").length;
  const PickPackCount = filteredTaskData.filter(task => task["Part Status"] === "Pick - Pack").length;
 
  const acceptedCount = filterByDateAndStatus(taskData, selectedDate, 'Status', 'Accepted').length;
 
 
// Total Work Orders count
const totalWorkOrders = taskData.filter(task => {
  const taskDate = dayjs(task["New Ticket Date"]);
 
  // Check if a date range is set
  const isDateInRange =
    dateRange[0] && dateRange[1]
      ? taskDate.isBetween(dateRange[0], dateRange[1], null, '[]') // Check if the task date is within the selected range
      : true; // If no date range, include all tasks
 
  // If 'All Tickets' is selected, include all tasks (even those without "New Ticket Date")
  if (quickRange === 'All Tickets') {
    return true;
  }
 
  // If there is a valid "New Ticket Date", check if it's within the date range
  return task["New Ticket Date"] && taskDate.isValid() && isDateInRange;
}).length;
 
const rescheduledWOCount = filterByDateAndStatus(taskData, dateRange, "Status", "Rescheduled").length;
 
const fixedWOCount = filteredTaskData.filter(task => task["Status"] === "Fixed").length;
 
const nonStartedCount = filterByDateAndStatus(taskData, selectedDate, 'Status', 'Technician Assigned').length;
 
const WORepeatCount = filterByDateAndStatus(taskData, selectedDate, 'WO Repeat', 'WO Repeat').length;
 
const satellitePerformanceCount = filterByDateAndStatus(taskData, selectedDate, 'Satellite Partner Performance', 'Good').length;
 
const RMA = filterByDateAndStatus(taskData, selectedDate, 'Category', 'RMA').length;
 
const FSE = filterByDateAndStatus(taskData, selectedDate, 'Category', 'FSE').length;
 
const PPSN_U = filterByDateAndStatus(taskData, selectedDate, 'Category', 'PPSN-U').length;
 
 
const SLA = filterByDateAndStatus(taskData, selectedDate, 'SLA_Breach', true).map(task => ({
  status: task["SLA_Breach"],
  newTicketDate: task["New Ticket Date"]
})).length;
 
const LACBarcode = filterByDateAndStatus(taskData, selectedDate, 'Barcode', 'LAC').length;
 
 
const T3B = filterByDateAndStatus(taskData, selectedDate, 'Tier', 'T3B').map(task => ({
  status: task["Tier"],
  newTicketDate: task["New Ticket Date"]
})).length;
 
const RRR = filterByDateAndStatus(taskData, selectedDate, 'Code', 'RRR').map(task => ({
  status: task["Code"],
  newTicketDate: task["New Ticket Date"]
})).length;
 
const RPL = filterByDateAndStatus(taskData, selectedDate, 'Code', 'RPL').length;
 
const QMS = filterByDateAndStatus(taskData, selectedDate, 'System', 'QMS').length;
 
const PPSN_UHV = filterByDateAndStatus(taskData, selectedDate, 'System', 'PPSN-UHV').length;
 
 
  // Calculate RRR using updated logic
const repeatCount = taskData.filter(task => task["WO Repeat"] > 0).length;
const totalCount = taskData.length;
const WORepeatRatio = totalWorkOrders > 0 ? ((WORepeatCount / totalWorkOrders) * 100).toFixed(2) : 0;
 
// Updated function to calculate ECT dynamically based on filteredTaskData
const calculateECT = (tasks) => {
  if (!tasks || tasks.length === 0) return 'N/A'; // Handle empty task data gracefully
 
  const totalECT = tasks.reduce((sum, task) => {
    const shippedDate = task["Part Shipped/Delivered Date"];
    const completionDate = task["Service Completion Date"];
 
    // Check for invalid or missing dates
    if (!shippedDate || !completionDate || isNaN(new Date(shippedDate).getTime()) || isNaN(new Date(completionDate).getTime())) {
      return sum; // Skip invalid entries
    }
 
    const timeDiff = new Date(completionDate) - new Date(shippedDate);
    const ectInDays = timeDiff / (1000 * 60 * 60 * 24); // Convert time difference to days
    return sum + ectInDays;
  }, 0);
 
  // Calculate average ECT
  const validTasksCount = tasks.filter(
    task =>
      task["Part Shipped/Delivered Date"] &&
      task["Service Completion Date"] &&
      !isNaN(new Date(task["Part Shipped/Delivered Date"]).getTime()) &&
      !isNaN(new Date(task["Service Completion Date"]).getTime())
  ).length;
 
  return validTasksCount > 0 ? `${(totalECT / validTasksCount).toFixed(2)} days` : 'N/A'; // Avoid division by zero
};
 
const calculateCountByDateRange = () => {
  if (!dateRange[0] || !dateRange[1]) {
    return taskData.length; // Show all tickets if no date range is set
  }
 
  // Filter tickets based on the date range
  return taskData.filter(task => {
    const taskDate = dayjs(task.date); // Replace 'date' with the appropriate field in your data
    return taskDate.isBetween(dateRange[0], dateRange[1], null, '[]'); // Inclusive range
  }).length;
};
 
// In your component:
const count = calculateCountByDateRange();
 
// Update averageECT dynamically based on filtered data
const averageECT = calculateECT(filteredTaskData);
 
 
  const statusData = [
    // { name: 'Completed', value: taskData.filter(task => task["WO Track"] === 'Completed').length },
    { name: 'Fixed', value: taskData.filter(task => task.Status === 'Fixed').length },
    { name: 'Rescheduled', value: taskData.filter(task => task.Status === 'Rescheduled').length },
    // { name: 'Technician Assigned', value: taskData.filter(task => task.Status === 'Technician Assigned').length },
  ];
 
 
  const ProgramDatas = [
    { name: 'Standard Commercial', count: taskData.filter(task => task.Program === 'Standard Commercial').length },
    { name: 'Premium Care', count: taskData.filter(task => task.Program === 'Premium Care').length },
    { name: 'Premier Support', count: taskData.filter(task => task.Program === 'Premier Support').length },
    { name: 'Standard Consumer', count: taskData.filter(task => task.Program === 'Standard Consumer').length },
    { name: 'Smart Managed Solutions', count: taskData.filter(task => task.Program === 'Smart Managed Solutions').length },
  ];
 
 
   // Calculate Average T3B
   const calculateT3BAverage = (data) => {
    if (data.length === 0) return 0; // Handle case of no data
 
    // Sum of values between 8 and 10
    const sum8to10 = data.reduce((acc, value) => {
      const satisfactionValue = parseFloat(value.Overall_Satisfaction_ops);
      if (satisfactionValue >= 8 && satisfactionValue <= 10) {
        return acc + satisfactionValue;
      }
      return acc;
    }, 0);
 
    // Total sum of values between 0 and 10
    const totalSum0to10 = data.reduce((acc, value) => {
      const satisfactionValue = parseFloat(value.Overall_Satisfaction_ops);
      if (satisfactionValue >= 0 && satisfactionValue <= 10) {
        return acc + satisfactionValue;
      }
      return acc;
    }, 0);
 
    // Calculate average and ensure we avoid division by zero
    if (totalSum0to10 > 0) {
      return ((sum8to10 / totalSum0to10) * 100).toFixed(2); // Return average as percentage
    } else {
      return 0; // If total sum is 0, return 0
    }
  };
 
  const averageT3B = calculateT3BAverage(t3bData); // Calculate average T3B
 
  useEffect(() => {
    if (!dateRange[0] && !dateRange[1]) {
      // If no date range is selected, show all tasks
      setFilteredTaskData(taskData);
    } else {
      // Filter tasks based on the date range
      const filtered = taskData.filter(task => {
        const taskDate = task["New Ticket Date"] ? dayjs(task["New Ticket Date"]) : null; // Safely parse the date
        const isDateInRange =
          taskDate && taskDate.isValid() && taskDate.isBetween(dateRange[0], dateRange[1], null, '[]');
        return isDateInRange; // Include tasks within the date range
      });
 
      setFilteredTaskData(filtered);
    }
  }, [taskData, dateRange]);
 
  const calculateCounts = (statusKey, statusValue) => {
    return filterByDateAndStatus(taskData, dateRange, statusKey, statusValue).length;
  };
 
 
 
  const topCards = [
    {
      title: 'Total Work Orders',
      count: totalWorkOrders, // Display the correct count after filtering
      icon: <AssignmentLateIcon />,
      tooltip: filteredTaskData.map(task => `Date: ${task["New Ticket Date"]}`).join('\n'), // Show the task dates as tooltip
    },  { title: 'Part Status', content: `S: ${shippedCount}, L: ${laborOnlyCount},  D: ${DeliveredCount},  P: ${PendingCount},  PP: ${PickPackCount} `, icon: <AssignmentLateIcon /> },
    { title: 'Accepted WO', count: calculateCounts('Status', 'Accepted'), icon: <WorkOutlineIcon /> },
    { title: 'Fixed WO', count: calculateCounts('Status', 'Fixed'), icon: <BuildIcon /> },
    { title: 'Rescheduled WO', count: rescheduledWOCount, icon: <AutorenewIcon /> },
    { title: 'ECT', content: averageECT, icon: <AlarmIcon />, filterKey: 'ECT' },
    { title: 'RRR', content: rrrValue, icon: <PolicyIcon />, filterKey: 'RRR' },
    { title: 'Repeat WO', count: WORepeatCount, icon: <RepeatIcon /> },
    { title: 'Average T3B', count: `${averageT3B}%`, icon: <BusinessIcon />, filterKey: 'T3B' },
    { title: 'RMA', count: RMA, icon: <SettingsBackupRestoreIcon /> },
    { title: 'Non-Started WO', count: calculateCounts('Status', 'Technician Assigned'), icon: <AssignmentLateIcon /> },
    { title: 'FSE Performance', count: FSE, icon: <BusinessIcon /> },
  ];
 
 
 
  const bottomCards = [
    { title: 'LAC Barcode', count: LACBarcode, icon: <QrCodeIcon /> },
    { title: 'PPSN-U', count: PPSN_U, icon: <SecurityIcon /> },
    { title: 'SLA Breaches', count: SLA, icon: <WarningIcon /> },
    { title: 'RPL', count: RPL, icon: <VerifiedIcon /> },
    { title: 'QMS', count: QMS, icon: <LayersIcon /> },
    { title: 'PPSN-UHV', count: PPSN_UHV, icon: <PowerIcon /> },
    { title: 'Satellite Partner Performance', count: satellitePerformanceCount, icon: <SatelliteAltIcon /> },
 
  ];
 
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', fontFamily: titleFont }}>
      <CssBaseline />
 
      {/* Header */}
      <Header handleDrawerToggle={handleDrawerToggle} title="Admin Dashboard" />
 
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {/* Drawer */}
        <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} onMenuClick={handleMenuClick} />
 
 
 
        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            transition: 'margin 0.3s ease-out',
            backgroundColor: '#f9f9f9',
            paddingTop: '64px',  // Offset for the fixed header
            paddingBottom: '80px',  // Add padding to the bottom for the footer
          }}
        >
          <Toolbar />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ mb: 4 }}>
              {/* Quick Range and DateRangePicker in a single line */}
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                {/* Quick Range selection */}
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel>Select Date</InputLabel>
                  <Select
                    value={quickRange}
                    onChange={handleQuickRangeChange}
                    label="Select Date"
                  >
                    <MenuItem value="All Tickets">All Tickets</MenuItem>
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="last1Day">Last 1 Day</MenuItem>
                    <MenuItem value="last7Days">Last 7 Days</MenuItem>
                    <MenuItem value="lastWeek">Last Week</MenuItem>
                    <MenuItem value="thisMonth">This Month</MenuItem>
                    <MenuItem value="lastMonth">Last Month</MenuItem>
                  </Select>
                </FormControl>
 
                {/* DateRangePicker in a single line */}
                <DateRangePicker
                  startText="Start Date"
                  endText="End Date"
                  value={dateRange}
                  onChange={(newValue) => setDateRange(newValue)}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField {...startProps} sx={{ flex: 1 }} size="small" />
                      <TextField {...endProps} sx={{ flex: 1 }} size="small" />
                    </>
                  )}
                />
              </Box>
            </Box>
          </LocalizationProvider>
 
            {/* Show DateRangePicker for Custom Range */}
            {filter === "Select Date Range" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    Select Date Range
                  </Typography>
                  <DateRangePicker
                    startText="Start Date"
                    endText="End Date"
                    value={dateRange}
                    onChange={(newRange) => setDateRange(newRange)}
                    renderInput={(startProps, endProps) => (
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <TextField {...startProps} fullWidth />
                        <TextField {...endProps} fullWidth />
                      </Box>
                    )}
                  />
                </Box>
              </LocalizationProvider>
            )}
 
          {/* Conditionally render the AssignTaskOrderTable */}
          {showTaskOrder ? (
            <AssignTaskOrderTable />  // Show table if showTaskOrder is true
          ) : (
            <>
              {/* Top Cards */}
              <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
                {topCards.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                    <Card
                      sx={{
                        background: '#ffffff',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                        borderRadius: '10px',
                        padding: '15px',
                        height: '130px',
                        transition: 'transform 0.2s ease-in-out',
                        textAlign: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                          transform: 'scale(1.03)',
                          boxShadow: '0 6px 18px rgba(0,0,0,0.1)',
                        },
                      }}
                      onClick={() => handleCardClick(card.filterKey)}
                    >
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                          {card.icon}
                        </Box>
                        <Typography variant="h6" sx={{ fontFamily: titleFont, fontWeight: '600', fontSize: '1rem', color: '#4B79A1' }}>
                          {card.title}
                        </Typography>
                        {card.count !== undefined ? (
                          <Typography variant="h6" sx={{ fontFamily: countFont, fontWeight: '600', fontSize: '1rem', color: '#283E51', mt: 1 }}>
                            {card.count}
                          </Typography>
                        ) : (
                          <Typography variant="h6" sx={{ fontFamily: countFont, fontWeight: '600', fontSize: '1rem', color: '#283E51', mt: 1 }}>
                            {card.content}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
 
              {/* Charts Section */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, fontFamily: titleFont }}>
                  Work Order Analytics
                </Typography>
 
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                  <Card sx={{ boxShadow: 3, borderRadius: '12px', height: '300px', padding: '15px', position: 'relative' }}>
                      <CardContent>
                        <Typography variant="h6" sx={{ mb: 2, fontSize: '0.9rem', fontFamily: titleFont, color: '#555' }}>
                          Work Order Status
                        </Typography>
                        <ResponsiveContainer width="100%" height={200}>
                          <PieChart>
                            <Pie
                              data={statusData}
                              cx="50%"
                              cy="50%"
                              innerRadius={50}
                              outerRadius={70}
                              fill="#8884d8"
                              paddingAngle={5}
                              dataKey="value"
                            >
                              {statusData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </ResponsiveContainer>
                        <div style={{ position: 'absolute', top: '32px', right: '30px', fontFamily: titleFont,fontSize: '12.5px', fontWeight: '500', color: '#4B79A1', }}>
                          <div style={{ marginBottom: '5px' }}>
                            Fixed  :   {fixedWOCount}
                          </div>
                          <div style={{ marginBottom: '5px' }}>
                            Rescheduled  : {rescheduledWOCount}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
 
                  <Grid item xs={12} sm={6}>
                    <Card sx={{ boxShadow: 3, borderRadius: '12px', height: '300px', padding: '10px' }}>
                      <CardContent>
                        <Typography variant="h6" sx={{ mb: 2, fontSize: '0.9rem', fontFamily: titleFont, color: '#555' }}>
                          Work Order Priority Distribution
                        </Typography>
                        <ResponsiveContainer width="100%" height={200}>
                          <BarChart layout="vertical" data={programCounts}>
                            <XAxis type="number" tick={{ fontSize: 12, fontFamily: titleFont }} />
                            <YAxis dataKey="name" type="category" tick={{ fontSize: 12, fontFamily: titleFont }} />
                            <Tooltip />
                            <Bar dataKey="count" fill="#607D8B" />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
 
          {/* Only render bottom cards if not showing AssignTaskOrderTable */}
          {!showTaskOrder && (
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
              {bottomCards.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                  <Card
                    sx={{
                      background: '#ffffff',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                      borderRadius: '10px',
                      padding: '15px',
                      height: '130px',
                      transition: 'transform 0.2s ease-in-out',
                      textAlign: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.03)',
                        boxShadow: '0 6px 18px rgba(0,0,0,0.1)',
                      },
                    }}
                    onClick={() => handleCardClick(card.filterKey)}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                        {card.icon}
                      </Box>
                      <Typography variant="h6" sx={{ fontFamily: titleFont, fontWeight: '600', fontSize: '1rem', color: '#4B79A1' }}>
                        {card.title}
                      </Typography>
                      {card.count !== undefined ? (
                        <Typography variant="h6" sx={{ fontFamily: countFont, fontWeight: '600', fontSize: '1rem', color: '#283E51', mt: 1 }}>
                          {card.count}
                        </Typography>
                      ) : (
                        <Typography variant="h6" sx={{ fontFamily: countFont, fontWeight: '600', fontSize: '1rem', color: '#283E51', mt: 1 }}>
                          {card.content}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
 
      <Footer />
    </Box>
  );
 
};
 
export default AdminDashboard;
