import React, { useState, useEffect } from "react";
import {
  Box,
  CssBaseline,
  Toolbar,
  Grid,
  Paper,
  Typography,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  IconButton,
} from "@mui/material";
import Header from "../components/Header";
import FieldEngineerDrawer from "../components/FieldEngineerDrawer";
import Footer from "../components/Footer";
import WorkOrderAssignment from "../components/WorkOrderAssignment";
import AssignOrderList from "../components/AssignOrderList";
import axios from "axios";
import { API_BASE_URL } from "../services/apiService";
 
const FieldEngineerDashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState("Dashboard");
  const [selectedTask, setSelectedTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [dateFilter, setDateFilter] = useState("All");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [filter, setFilter] = useState("All Tickets");
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("loggedInUser"));
    if (user) {
      setLoggedInUser(user.username);
    }
  }, []);
 
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/tasks`);
        setTasks(response.data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };
 
    if (loggedInUser) {
      fetchTasks();
    }
  }, [loggedInUser]);
 
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleMenuClick = (menuItem) => {
    setCurrentMenu(menuItem);
  };
 
  const handleAcknowledgeTask = (task) => {
    setSelectedTask(task);
    setCurrentMenu("WorkOrderAssignment");
  };
 
  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
    setCustomStartDate("");
    setCustomEndDate("");
  };
 
  // Filter tasks for the current user
  const userTasks = tasks.filter(
    (task) =>
      task["Tech Assignment"] && task["Tech Assignment"].trim() === loggedInUser
  );
 
// Apply date filter for both Service Completion Date and Assign Ticket Date
const filteredTasks = userTasks.filter((task) => {
  const serviceCompletionDate = task["Service Completion Date"]
    ? new Date(task["Service Completion Date"])
    : null;
 
  // Convert 'Assign Ticket Date' to a Date object
  let assignTicketDate = task["Assign Ticket Date"];
  if (assignTicketDate && typeof assignTicketDate === "string") {
    try {
      assignTicketDate = new Date(
        assignTicketDate.replace(
          /(\d{2})\/(\d{2})\/(\d{4}), (\d{1,2}:\d{2}:\d{2} [apm]{2})/,
          "$2/$1/$3 $4"
        )
      );
    } catch (error) {
      console.error(`Error parsing Assign Ticket Date:`, assignTicketDate);
      assignTicketDate = null;
    }
  } else {
    assignTicketDate = null; // Ensure invalid data is handled
  }
  // Corrected for Pause Date field
  let pauseDate = task["Pause Date"];
  if (pauseDate) {
    pauseDate = new Date(pauseDate);
  }
 
  const today = new Date();
  today.setHours(0, 0, 0, 0);
 
  // Helper function to check if a date falls within a range
  const isDateInRange = (date, startDate, endDate) =>
    date && date >= startDate && date <= endDate;
 
  if (dateFilter === "All") {
    // For "All", only filter based on status
    return true;
  }
 
  if (dateFilter === "Today") {
    const startOfDay = new Date(today);
    const endOfDay = new Date(today);
    endOfDay.setHours(23, 59, 59, 999);
    return (
      isDateInRange(serviceCompletionDate, startOfDay, endOfDay) ||
      isDateInRange(assignTicketDate, startOfDay, endOfDay) ||
      isDateInRange(pauseDate, startOfDay, endOfDay)
    );
  }
 
  if (dateFilter === "Last 1 Day") {
    const last1Day = new Date(today);
    last1Day.setDate(today.getDate() - 1);
    return (
      isDateInRange(serviceCompletionDate, last1Day, today) ||
      isDateInRange(assignTicketDate, last1Day, today) ||
      isDateInRange(pauseDate, last1Day, today)
    );
  }
 
  if (dateFilter === "Last 7 Days") {
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);
    return (
      isDateInRange(serviceCompletionDate, last7Days, today) ||
      isDateInRange(assignTicketDate, last7Days, today) ||
      isDateInRange(pauseDate, last7Days, today)
    );
  }
 
  if (dateFilter === "Last 30 Days") {
    const last30Days = new Date(today);
    last30Days.setDate(today.getDate() - 30);
    return (
      isDateInRange(serviceCompletionDate, last30Days, today) ||
      isDateInRange(assignTicketDate, last30Days, today) ||
      isDateInRange(pauseDate, last30Days, today)
    );
  }
 
  if (dateFilter === "Custom") {
    const startDate = new Date(customStartDate);
    const endDate = new Date(customEndDate);
    return (
      isDateInRange(serviceCompletionDate, startDate, endDate) ||
      isDateInRange(assignTicketDate, startDate, endDate) ||
      isDateInRange(pauseDate, startDate, endDate)
    );
  }
 
  return false;
});
 
 // Statistics
const totalTickets = filteredTasks.length;
const fixedTickets = filteredTasks.filter((task) => task.Status === "Fixed").length;
const pausedTickets = filteredTasks.filter((task) => task.Status === "Paused").length;
const openTickets = filteredTasks.filter(
  (task) => task.Status === "Technician Assigned" && task["Assign Ticket Date"] !== ""
).length;
const cancelledTickets = filteredTasks.filter((task) => task.Status === "Cancelled").length;
 
  // Calculate average fix percentage
  const averageFixPercentage = totalTickets > 0 ? ((fixedTickets / totalTickets) * 100).toFixed(2) : "0.00";
 
  // Log the statistics to verify
  console.log({totalTickets,fixedTickets,openTickets,pausedTickets,averageFixPercentage,});
 
  // The content rendering part remains the same as before
  const renderContent = () => {
    if (currentMenu === "WorkOrderAssignment") {
      return <WorkOrderAssignment task={selectedTask} />;
    } else if (currentMenu === "AssignOrderList") {
      return <AssignOrderList onAcknowledgeTask={handleAcknowledgeTask} />;
    } else {
      return (
        <Box
          sx={{
            p: 3,
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            marginTop:-4,
          }}
        >
          <Typography
            variant="h3"
            fontWeight="500"
            sx={{
              color: "#2d3e50",
              marginBottom: "3rem",
              fontFamily: '"Helvetica Neue", sans-serif',
              textTransform: "none",
              fontSize: "1.9rem",
              lineHeight: "1.2",
              textAlign: "left",
              marginLeft: "1.5rem",
            }}
          >
            Welcome to the Field Engineer Dashboard
          </Typography>
 
          {/* Date Filter */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <FormControl
              sx={{
                width: "50%",
                maxWidth: "500px",
                minWidth: "250px",
                transition: "min-width 0.3s ease",
                padding: "10px",
              }}
            >
              <Select
                value={dateFilter}
                onChange={handleDateFilterChange}
                size="small"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "33px",
                  padding: "3px 16px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#007bff",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#007bff",
                  },
                  "& .MuiSelect-icon": {
                    color: "#007bff",
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: 400,
                    color: "#555",
                  },
                  "& .MuiSelect-root": {
                    fontWeight: 600,
                    color: "#333",
                    fontSize: "16px",
                  },
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                <MenuItem
                  value="All"
                  sx={{
                    fontWeight: 500,
                    color: "#333",
                    transition: "all 0.3s ease",
                    "&:hover": { backgroundColor: "#f0f0f0", color: "#007bff" },
                  }}
                >
                  All Tickets
                </MenuItem>
                <MenuItem
                  value="Today"
                  sx={{
                    fontWeight: 300,
                    color: "#333",
                    transition: "all 0.3s ease",
                    "&:hover": { backgroundColor: "#f0f0f0", color: "#007bff" },
                  }}
                >
                  Today
                </MenuItem>
                <MenuItem
                  value="Last 1 Day"
                  sx={{
                    fontWeight: 300,
                    color: "#333",
                    transition: "all 0.3s ease",
                    "&:hover": { backgroundColor: "#f0f0f0", color: "#007bff" },
                  }}
                >
                  Last 1 day
                </MenuItem>
                <MenuItem
                  value="Last 7 Days"
                  sx={{
                    fontWeight: 300,
                    color: "#333",
                    transition: "all 0.3s ease",
                    "&:hover": { backgroundColor: "#f0f0f0", color: "#007bff" },
                  }}
                >
                  Last 7 Days
                </MenuItem>
                <MenuItem
                  value="Last 30 Days"
                  sx={{
                    fontWeight: 300,
                    color: "#333",
                    transition: "all 0.3s ease",
                    "&:hover": { backgroundColor: "#f0f0f0", color: "#007bff" },
                  }}
                >
                  Last 30 Days
                </MenuItem>
                <MenuItem
                  value="Custom"
                  sx={{
                    fontWeight: 300,
                    color: "#333",
                    transition: "all 0.3s ease",
                    "&:hover": { backgroundColor: "#f0f0f0", color: "#007bff" },
                  }}
                >
                  Custom Range
                </MenuItem>
              </Select>
            </FormControl>
            {dateFilter === "Custom" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  marginLeft: "10px",
                  padding: "12px",
                  borderRadius: 1,
                  transition: "all 0.3s ease",
                }}
              >
                <TextField
                  label="Start Date"
                  type="date"
                  value={customStartDate}
                  size="small"
                  onChange={(e) => setCustomStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={customEndDate}
                  size="small"
                  onChange={(e) => setCustomEndDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            )}
          </Box>
 
          {/* Statistics Cards */}
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Grid
              container
              spacing={4}
              sx={{ mb: 4, mt: 3, width: "100%", justifyContent: "center" }}
            >
              {/* Total Tickets Card */}
              <Grid item xs={12} sm={6} md={2.3}>
                <Card
                  sx={{
                    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    backgroundColor: "#f0f4c3",
                    "&:hover": {
                      boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
                      transform: "scale(1.05)",
                      transition: "all 0.3s ease-in-out",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  <CardHeader
                    title="Total Tickets"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#5c6bc0",
                      color: "white",
                      fontSize: "0.5rem",
                      fontWeight: "500",
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontWeight: "500", color: "#333" }}
                    >
                      {totalTickets}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
 
              {/* Fixed Tickets Card */}
              <Grid item xs={12} sm={6} md={2.3}>
                <Card
                  sx={{
                    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                    borderRadius: "12px",
                    backgroundColor: "#c8e6c9", // Lighter background for Fixed Tickets
                    "&:hover": {
                      boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
                      transform: "scale(1.05)",
                      transition: "all 0.3s ease-in-out",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  <CardHeader
                    title="Fixed Tickets"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#66bb6a", // Lighter green
                      color: "white",
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontWeight: "500", color: "#333" }}
                    >
                      {fixedTickets}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
 
              {/* Open Tickets Card */}
              <Grid item xs={12} sm={6} md={2.3}>
                <Card
                  sx={{
                    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                    borderRadius: "12px",
                    backgroundColor: "#f3e5f5", // Lighter background for Open Tickets
                    "&:hover": {
                      boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
                      transform: "scale(1.05)",
                      transition: "all 0.3s ease-in-out",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  <CardHeader
                    title="Open Tickets"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#9575cd", // Lighter purple shade
                      color: "white",
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontWeight: "500", color: "#333" }}
                    >
                      {openTickets}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
 
              {/* Paused Tickets Card */}
              <Grid item xs={12} sm={6} md={2.3}>
                <Card
                  sx={{
                    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                    borderRadius: "12px",
                    backgroundColor: "#fff8e1", // Lighter background for Paused Tickets
                    "&:hover": {
                      boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
                      transform: "scale(1.05)",
                      transition: "all 0.3s ease-in-out",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  <CardHeader
                    title="Paused Tickets"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#ffeb3b", // Lighter yellow shade
                      color: "white",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontWeight: "500", color: "#333" }}
                    >
                      {pausedTickets}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
 
              {/* Cancel Tickets Card */}
              <Grid item xs={12} sm={6} md={2.3}>
                <Card
                  sx={{
                    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                    borderRadius: "12px",
                    backgroundColor: "#fce4ec", // Lighter pinkish background for Cancelled Tickets
                    "&:hover": {
                      boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
                      transform: "scale(1.05)",
                      transition: "all 0.3s ease-in-out",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  <CardHeader
                    title="Cancelled Tickets"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#f06292", // Lighter pinkish shade
                      color: "white",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontWeight: "500", color: "#333" }}
                    >
                      {cancelledTickets}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
 
          <Grid container justifyContent="center" sx={{ mb: 4, mt: 4 }}>
            <Grid item xs={12} sm={10} md={8}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    marginRight: 3,
                    letterSpacing: "0.5px",
                    color: "#495057",
                    textTransform: "uppercase",
                    fontSize: "1.1rem",
                  }}
                >
                  Average Fixed Tickets :
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "500",
                    fontSize: "2rem",
                    color: averageFixPercentage > 75 ? "#4caf50" : "#ff9800",
                    marginRight: 3,
                  }}
                >
                  {averageFixPercentage}%
                </Typography>
                <Box
                  sx={{
                    fontSize: "1rem",
                    color: averageFixPercentage > 75 ? "#4caf50" : "#ff9800",
                  }}
                >
                  <i className="fas fa-check-circle" />
                </Box>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  opacity: 0.7,
                  fontSize: "1rem",
                  marginTop: 1,
                  color: "#6c757d",
                }}
              >
                Based on {totalTickets} Total Tickets
              </Typography>
            </Grid>
          </Grid>
 
        </Box>
      );
    }
  };
 
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        title="Field Engineer Dashboard"
      />
 
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <FieldEngineerDrawer
          open={drawerOpen}
          handleDrawerToggle={handleDrawerToggle}
          onMenuClick={handleMenuClick}
        />
 
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            transition: "margin 0.3s ease-out",
            backgroundColor: "#f4f6f8",
            paddingTop: "64px",
          }}
        >
          <Toolbar />
          {renderContent()}
        </Box>
      </Box>
 
      <Footer />
    </Box>
  );
};
 
export default FieldEngineerDashboard;
