import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Toolbar,
  Button,
  Modal,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../services/apiService';
// Custom Components
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as XLSX from 'xlsx';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
 
 
 
const RRRPage = () => {
  const [rrrData, setRrrData] = useState([]); // All data from Task collection
  const [filteredData, setFilteredData] = useState([]); // Filtered data based on search term
  const [actualRepeatCount, setActualRepeatCount] = useState(0); // Actual Repeat WO count
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawer state for sidebar toggle
  const [historyModalOpen, setHistoryModalOpen] = useState(false); // Modal for WO history
  const [workOrderHistory, setWorkOrderHistory] = useState([]); // Old Work Order History
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [loading, setLoading] = useState(true);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [downloadType, setDownloadType] = useState('entire'); // 'entire' or 'date-wise'
 
 
  const openDownloadModal = () => setDownloadModalOpen(true);
  const closeDownloadModal = () => setDownloadModalOpen(false);
 
 
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
 
  const openHistoryModal = (woNumber, msn, partNumber) => {
    const history = rrrData.filter(
      (task) =>
        task['MSN#'] === msn &&
        task['part Number'] === partNumber &&
        task['WO#'] !== woNumber // Exclude the current WO
    );
    setWorkOrderHistory(history);
    setHistoryModalOpen(true);
  };
 
  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
    setWorkOrderHistory([]);
  };
 
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
 
  useEffect(() => {
    const fetchRRRData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/tasks`);
        const data = response.data;
 
        // Filter for Actual Repeat Work Orders (Repeat work orders)
        const repeatData = data.filter((task, index, arr) => {
          if (!task['WO#'] || !task['MSN#'] || !task['part Number']) return false;
 
          return arr.some(
            (otherTask) =>
              task['MSN#'] === otherTask['MSN#'] &&
              task['part Number'] === otherTask['part Number'] &&
              task['WO#'] !== otherTask['WO#']
          );
        });
 
        // Sort by Service Completion Date in descending order (Newest first)
        repeatData.sort((a, b) => {
          const dateA = new Date(a['New Ticket Date']);
          const dateB = new Date(b['New Ticket Date']);
 
          if (isNaN(dateA)) return 1;  // Invalid date, push to the bottom
          if (isNaN(dateB)) return -1; // Invalid date, push to the bottom
 
          return dateB - dateA; // Newest entries first
        });
 
        setRrrData(repeatData);
        setFilteredData(repeatData); // Show only Actual Repeat WO
        setActualRepeatCount(repeatData.length); // Update Actual Repeat WO count
      } catch (error) {
        console.error('Error fetching RRR data:', error);
      }
    };
 
    fetchRRRData();
  }, []);
 
  // Filter the data based on the search term
  const filteredResults = filteredData.filter((task) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
   
    // Safely get string values for comparison
    const wo = String(task['WO#'] || "").toLowerCase();
    const msn = String(task['MSN#'] || "").toLowerCase();
    const partNumber = String(task['part Number'] || "").toLowerCase();
 
    return (
      wo.includes(lowerSearchTerm) ||
      msn.includes(lowerSearchTerm) ||
      partNumber.includes(lowerSearchTerm)
    );
  });
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };
 
  const handleDownload = () => {
    const tableFields = [
      'Case#',
      'WO#',
      'MSN#',
      'New Ticket Date',
      'Assign Ticket Date',
      'Service Completion Date',
      'Cancellation Date',
      'part Number',
      'Status',
      'Pause Reason',
      'Cancellation Reason',
      'Completion Remark',
    ];
 
    const formatData = (data) =>
      data.map((task) =>
        tableFields.reduce((obj, key) => {
          if (key === 'part Number' && Array.isArray(task[key])) {
            // If 'Part Number' is an array, join it into a string
            obj[key] = task[key].join(', ');
          } else {
            obj[key] = task[key] || ''; // Default to an empty string for missing fields
          }
          return obj;
        }, {})
      );
   
 
    let dataToDownload = [];
 
    if (downloadType === 'entire') {
      const mainTableData = formatData(filteredResults);
 
      const historyData = rrrData.flatMap((task) => {
        const history = rrrData.filter(
          (entry) =>
            entry['MSN#'] === task['MSN#'] &&
            entry['Part Number'] === task['Part Number'] &&
            entry['WO#'] !== task['WO#']
        );
 
        return formatData(history);
      });
 
      dataToDownload = [...mainTableData, ...historyData];
    } else if (downloadType === 'date-wise' && selectedStartDate && selectedEndDate) {
      const startDate = new Date(selectedStartDate);
      const endDate = new Date(selectedEndDate);
   
      // Set the endDate to include the entire day
      endDate.setHours(23, 59, 59, 999);
   
      const filteredData = filteredResults.filter((task) => {
        const newTicketDate = task['New Ticket Date'] ? new Date(task['New Ticket Date']) : null;
   
        // Ensure the task date falls inclusively within the range
        return (
          newTicketDate &&
          newTicketDate >= startDate &&
          newTicketDate <= endDate
        );
      });
   
      dataToDownload = formatData(filteredData);
    }
   
    if (dataToDownload.length > 0) {
      try {
        const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFile(workbook, 'RRR_Data.xlsx');
        alert('Download successful!');
      } catch (error) {
        console.error('Error creating Excel file:', error);
        alert('An error occurred while creating the file.');
      }
    } else {
      alert('No data available for download.');
    }
 
    closeDownloadModal();
  };
 
 
 
 
  const paginatedData = filteredResults.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
 
  // Define theme
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#FF4081',
      },
      background: {
        default: '#f4f6f8',
        paper: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      h4: {
        fontWeight: 600,
        fontSize: '2.25rem',
      },
      h6: {
        fontWeight: 500,
        fontSize: '1rem',
      },
      body1: {
        fontSize: '1rem',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
            padding: '8px 24px',
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#1565c0',
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            },
          },
        },
      },
    },
  });
 
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header handleDrawerToggle={handleDrawerToggle} title="RRR Details" />
 
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
 
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              backgroundColor: '#f4f6f8',
              paddingTop: '6px',
              borderRadius: '8px',
            }}
          >
            <Toolbar />
 
           
            <Box
  component="main"
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    p: 3,
    backgroundColor: '#f4f6f8',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  }}
>
  {/* Title */}
  <Typography
    variant="h5"
    sx={{
      fontWeight: 'bold',
      color: '#333',
      whiteSpace: 'nowrap',
    }}
  >
    RRR Details
  </Typography>
 
  {/* Search Bar */}
  <TextField
    label="Search by WO#, MSN#, Part Number"
    variant="outlined"
    value={searchTerm}
    onChange={handleSearchChange}
    size="small"
    sx={{
      flex: 1,
      mx: 2,
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        backgroundColor: '#fff',
        '&:hover fieldset': {
          borderColor: '#777',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#333',
        },
      },
    }}
  />
 
  {/* RRR Count */}
  <Typography
    variant="h6"
    sx={{
      color: '#555',
      fontWeight: 500,
      mx: 2,
      whiteSpace: 'nowrap',
    }}
  >
    Repeat WOs: {actualRepeatCount}
  </Typography>
 
  {/* Download Button */}
  <Button
    variant="contained"
    onClick={openDownloadModal}
    sx={{
      textTransform: 'none',
      backgroundColor: '#4CAF50',
      color: '#fff',
      borderRadius: '8px',
      padding: '6px 16px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: '#45a049',
      },
    }}
  >
    Download Data
  </Button>
</Box>
 
 
<Modal open={downloadModalOpen} onClose={closeDownloadModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    }}
  >
    <Typography variant="h6" gutterBottom>
      Download Data
    </Typography>
 
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Button
        variant={downloadType === 'entire' ? 'contained' : 'outlined'}
        onClick={() => setDownloadType('entire')}
      >
        Entire Data
      </Button>
      <Button
        variant={downloadType === 'date-wise' ? 'contained' : 'outlined'}
        onClick={() => setDownloadType('date-wise')}
      >
        Date-wise
      </Button>
    </Box>
 
    {downloadType === 'date-wise' && (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <DatePicker
            label="Start Date"
            value={selectedStartDate}
            onChange={(newValue) => setSelectedStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={selectedEndDate}
            onChange={(newValue) => setSelectedEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </LocalizationProvider>
    )}
 
    <Button
      variant="contained"
      sx={{ mt: 3 }}
      onClick={handleDownload}
      disabled={downloadType === 'date-wise' && (!selectedStartDate || !selectedEndDate)}
    >
      Download
    </Button>
  </Box>
</Modal>
 
 
            {/* Table */}
            {filteredResults.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Case Number</TableCell>
                      <TableCell>WO Number</TableCell>
                      <TableCell>MSN#</TableCell>
                      <TableCell>New Ticket Date</TableCell>
                      <TableCell>Assign Ticket Date</TableCell>
                      <TableCell>Service Completion Date</TableCell>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Hold reason</TableCell>
                      <TableCell>Cancellation reason</TableCell>
                      <TableCell>Remark</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((task, index) => (
                      <TableRow key={index}>
                        <TableCell>{task['Case#']}</TableCell>
                        <TableCell>{task['WO#']}</TableCell>
                        <TableCell>{task['MSN#']}</TableCell>
                        <TableCell>{new Date(task['New Ticket Date']).toLocaleString()}</TableCell>
                        <TableCell>{new Date(task['Assign Ticket Date']).toLocaleString()}</TableCell>
                        <TableCell>{new Date(task['Service Completion Date']).toLocaleString()}</TableCell>
                        <TableCell>{task['part Number']}</TableCell>
                        <TableCell>{task['Status']}</TableCell>
                        <TableCell>{task['Pause Reason']}</TableCell>
                        <TableCell>{task['Cancellation Reason']}</TableCell>
                        <TableCell>{task['Completion Remark']}</TableCell>
 
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() =>
                              openHistoryModal(task['WO#'], task['MSN#'], task['part Number'])
                            }
                          >
                            View History
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
 
                {/* Pagination */}
                <TablePagination
                  component="div"
                  count={filteredResults.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[25, 50, 100]}
                />
              </TableContainer>
            ) : (
              <Typography sx={{ color: '#555' }}>
                No Actual Repeat Work Orders available.
              </Typography>
            )}
          </Box>
        </Box>
 
        {/* Modal for Work Order History */}
        <Modal open={historyModalOpen} onClose={closeHistoryModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Work Order History
            </Typography>
            {workOrderHistory.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>WO Number</TableCell>
                      <TableCell>New Ticket Date</TableCell>
                      <TableCell>Assign Ticket Date</TableCell>
                      <TableCell>Service Completion Date</TableCell>
                      <TableCell>Cancellation Date</TableCell>
                      <TableCell>MSN#</TableCell>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Hold reason</TableCell>
                      <TableCell>Cancellation reason</TableCell>
                      <TableCell>Remark</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workOrderHistory.map((task, index) => (
                      <TableRow key={index}>
                        <TableCell>{task['WO#']}</TableCell>
                        <TableCell>{new Date(task['New Ticket Date']).toLocaleString()}</TableCell>
                        <TableCell>{new Date(task['Assign Ticket Date']).toLocaleString()}</TableCell>
                        <TableCell>{new Date(task['Service Completion Date']).toLocaleString()}</TableCell>
                        <TableCell>{new Date(task['Cancellation DateTime']).toLocaleString()}</TableCell>
                        <TableCell>{task['MSN#']}</TableCell>
                        <TableCell>{task['part Number']}</TableCell>
                        <TableCell>{task['Status']}</TableCell>
                        <TableCell>{task['Pause Reason']}</TableCell>
                        <TableCell>{task['Cancellation Reason']}</TableCell>
                        <TableCell>{task['Completion Remark']}</TableCell>
 
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No history found for this Work Order.</Typography>
            )}
            <Button variant="contained" onClick={closeHistoryModal} sx={{ mt: 2 }}>
              Close
            </Button>
          </Box>
        </Modal>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};
 
export default RRRPage;
 
 
