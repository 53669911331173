import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Stack,
  Grid,
  IconButton,
  Collapse,
  Divider,
  Box,
} from "@mui/material";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore"; // Correct import
import ExpandLess from "@mui/icons-material/ExpandLess";
import L from "leaflet";
import initialLocationIconImage from "../assets/pngegg.png";
import {AccessTime,LocalShipping,HourglassEmpty,CheckCircle,DoneAll,LocationOn, PauseCircleFilled} from "@mui/icons-material";
import { green, blue, orange, grey,purple, red,yellow,  } from "@mui/material/colors";

import { Person } from "@mui/icons-material";
import { Computer } from "@mui/icons-material";
import { ReportProblem } from "@mui/icons-material";
import { ShoppingCart } from "@mui/icons-material";
import { Build } from "@mui/icons-material";
import { CalendarToday } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

const TicketDetails = ({ ticket, onClose }) => {
  const [customerLocation, setCustomerLocation] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null); // Correctly initialize currentLocation
  const [fseLocation, setFseLocation] = useState(null);
  const [fseAddress, setFseAddress] = useState("");
  const [initialLocation, setInitialLocation] = useState(null);
  const [initialAddress, setInitialAddress] = useState("");
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // Set Current Location if available
    const currentLoc = ticket["Current Location"];
    if (currentLoc?.latitude && currentLoc?.longitude) {
      setCurrentLocation([currentLoc.latitude, currentLoc.longitude]);
      setFseAddress(currentLoc.address || "Address not available");
    }

    // Set Initial Location if available
    const initialLoc = ticket["initial Location"];
    if (initialLoc?.latitude && initialLoc?.longitude) {
      setInitialLocation([initialLoc.latitude, initialLoc.longitude]);
      setInitialAddress(initialLoc.address || "Address not available");
    }
  }, [ticket]);

  // Determine map center and conditionally render the map
  const mapCenter = initialLocation || currentLocation; // Center the map between the initial and current locations if available
  const showMap = mapCenter !== null; // Show map only if a location is available

  const initialLocationIcon = new L.Icon({
    iconUrl: initialLocationIconImage, // Use the imported image here
    iconSize: [30, 48], // Adjust icon size
    iconAnchor: [15, 35], // Anchor point for the initial location marker
    popupAnchor: [0, -48], // Popup anchor point
  });

  // Create custom marker icon with static size and fixed color for FSE
  const markerIcon = new L.Icon({
    iconUrl:
      "https://jetking.techjardemo.in/static/media/logo1.c347f8c5deb243b85451.png",
    iconSize: [45, 50], // Icon size for FSE
    iconAnchor: [20, 32], // Icon anchor point adjusted for larger size
    popupAnchor: [1, -60], // Popup anchor point adjusted for larger icon
  });

  const calculateDistance = (start, end) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in km
    const lat1 = start[0];
    const lon1 = start[1];
    const lat2 = end[0];
    const lon2 = end[1];

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (R * c).toFixed(2); // Distance in km rounded to 2 decimal places
  };

  const [expanded, setExpanded] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const toggleSection = (section) => {
    setExpandedSection((prev) => (prev === section ? null : section)); // Toggle between expanded and collapsed
  };

  // Define the handleClose function
  const handleClose = () => {
    setOpen(false); // Close the dialog by setting `open` to false
  };

  const statusLog = [
    { status: "Accepted", date: ticket["Release Date"], icon: <AccessTime />, color: blue[200] },
    { status: "Technician Assigned", date: ticket["Assign Ticket Date"], icon: <LocalShipping />, color: purple[200] },
    { status: "In-Route", date: ticket["Check-In Date"],time: ticket["Check-In Time"], icon: <HourglassEmpty />, color: grey[200] },
    { status: "On Site", date: ticket["Acknowledge Date"], time: ticket["Acknowledge Time"], icon: <LocationOn />, color: yellow[500] },
    { status: "Paused", date: ticket["Pause Date"], time: ticket["Pause Time"], icon: <PauseCircleFilled />, color: red[200] },
    { status: "Fixed", date: ticket["Service Completion Date"],icon: <DoneAll />, color: green[200] },
  ];
  // To show status based on the sequence and to hide "Fixed" until Paused is resolved
  const filteredStatusLog = statusLog.filter((log, index) => {
    // If "Paused", don't show "Fixed" yet
    if (log.status === "Paused") {
      return true; // Show Paused
    }

    // If the status is "Fixed", show only if "Paused" isn't present or has been resolved
    if (log.status === "Fixed" && !statusLog.some(s => s.status === "Paused")) {
      return true;
    }

    // Show all other statuses
    return true;
  });
  const parseCustomDate = (dateStr) => {
    if (!dateStr) {
      console.error("Date string is empty or invalid.");
      return null;
    }
  
    try {
      // Split the date and time parts
      const [datePart, timePart] = dateStr.split(", ");
      if (!datePart || !timePart) {
        console.error("Invalid date format:", dateStr);
        return null;
      }
  
      // Split the date part into day, month, and year
      const [day, month, year] = datePart.split("/").map(Number);
  
      // Split the time part into hours, minutes, seconds, and AM/PM
      const [time, ampm] = timePart.split(" ");
      if (!time || !ampm) {
        console.error("Invalid time format:", dateStr);
        return null;
      }
  
      const [hour, minute, second] = time.split(":").map(Number);
  
      // Convert to 24-hour format
      let hour24 = hour;
      if (ampm.toLowerCase() === "pm" && hour !== 12) {
        hour24 += 12;
      } else if (ampm.toLowerCase() === "am" && hour === 12) {
        hour24 = 0;
      }
  
      // Return the correct Date object
      return new Date(year, month - 1, day, hour24, minute, second);
    } catch (error) {
      console.error("Failed to parse date string:", dateStr, error);
      return null; // Return null if parsing fails
    }
  };
  

  return (
  <Dialog
    open={true}
    onClose={onClose}
    PaperProps={{
      style: {
        width: '80%', 
        maxWidth: '1200px', 
        overflowX: 'hidden',
      },
    }}
  >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" fontWeight="bold" color="primary">
          Ticket Details
        </Typography>
        <IconButton
                  edge="end"
                  color="inherit"
                  onClick={onClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
      </DialogTitle>
    <DialogContent>
    <Grid container spacing={3} sx={{ width: '100%'}}>
      {/* Ticket Information Section - 80% width */}
      <Grid item xs={12} sm={8}>
        <Stack spacing={3} width="100%">
          {/* Ticket Information */}
          <div
            style={{
              padding: "16px",
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6" fontWeight="bold" color="secondary" gutterBottom>
              Ticket Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Customer Name:</Typography>
                <Typography variant="body2" color="text.secondary">{ticket["Customer Name"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Case# :</Typography>
                <Typography variant="body2" color="text.secondary">{ticket["Case#"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">WO#:</Typography>
                <Typography variant="body2" color="text.secondary"> {ticket["WO#"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">MSN#:</Typography>
                <Typography variant="body2" color="text.secondary"> {ticket["MSN#"]}</Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Contact:</Typography>
                <Typography variant="body2" color="text.secondary">{ticket["Customer Mobile Number"]} </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Updated By:</Typography>
                <Typography variant="body2" color="text.secondary">{ticket["Updated By"]}</Typography>
              </Grid>
            </Grid>
          </div>
        </Stack>
            {/*All Deatils*/}
            <Stack spacing={2.5} >
              <div id="customer-info"style={{marginTop:'14px', padding: '2px', backgroundColor: '#f9f9f9',borderRadius: '6px', }}>
                <Typography variant="subtitle2" sx={{ padding:.8,ml:2, fontWeight: '500', display: 'flex', alignItems: 'center', }}>
                <IconButton size="small"sx={{backgroundColor: '#f8d7da',borderRadius: '50%',padding: '4px',marginRight: 1, }}>
                  <Person sx={{ color: '#fff' }} />
                </IconButton>
                  Customer Information
                  <IconButton size="small" aria-label="expand" onClick={() => toggleSection('customer-info')}          
                  sx={{
                      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s',
                    }}>
                      {expandedSection === 'customer-info' ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Typography>
                  {/* Conditionally render customer information based on the expanded state */}
                  <Collapse in={expandedSection === 'customer-info'} timeout="auto" unmountOnExit>
                    <div style={{ padding: '16px', borderRadius: '4px' }}>
                      <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">First Name:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Customer Name"] ? ticket["Customer Name"].split(' ')[0] : 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Last Name:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Customer Name"] ? ticket["Customer Name"].split(' ').slice(1).join(' ') : 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Email:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Email"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Contact Number:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Customer Mobile Number"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Customer Address:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Customer Address"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Customer City:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["State Location"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Customer Region:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Region"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Customer Country:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Country Name"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Customer Postal Code:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Customer Postal Code"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Case Number:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Case#"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Customer Rating:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Rating"|| "N/A"]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Customer Feedback:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["feedback"|| "N/A"]}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                    </Collapse>
              </div>

              <div id="machine-info" style={{ padding: '2px', backgroundColor: '#f9f9f9',borderRadius: '6px'}}>
                <Typography variant="subtitle2" sx={{padding:.8, ml:2, fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                <IconButton size="small"sx={{backgroundColor: '#7FDBB6',borderRadius: '50%',padding: '4px',marginRight: 1, }}>
                    <Computer sx={{ color: '#fff' }} />
                </IconButton>
                  Machine Information
                  <IconButton size="small" aria-label="expand" onClick={() => toggleSection('machine-info')}>
                  {expandedSection === 'machine-info' ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Typography>
                <Collapse in={expandedSection === 'machine-info'} timeout="auto" unmountOnExit>
                  <div style={{ padding: '16px', borderRadius: '4px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Serial No#:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["MSN#"] || "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Brand:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Brand"] || "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">MTM:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["MTM"] || "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Product Description:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Machine Description"] || "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Machine Type:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Machine Type"] || "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Repeat Repair:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Repeat Repair"] || "N/A"} </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Repeat Frequency:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Repeat Frequency"] || "N/A"} </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Premier Support:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Program"] || "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">PSPD Offering:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["PSPD Offering"] || "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Model Name:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Model Name"] || "N/A"} </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Warranty Start Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Warranty Start Date"] || "N/A"}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Warranty End Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Warranty End Date"] || "N/A"}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Collapse>
              </div>

              <div id="issue-info" style={{ padding: '2px', backgroundColor: '#f9f9f9',borderRadius: '6px'}}>
                <Typography variant="subtitle2" sx={{padding:.8, ml:2, fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                <IconButton size="small"sx={{backgroundColor: '#F8D7DA',borderRadius: '50%',padding: '4px',marginRight: 1, }}>
                  <ReportProblem sx={{ color: '#fff' }} /> 
                </IconButton>
                  Issue Information
                  <IconButton size="small" aria-label="expand" onClick={() => toggleSection("issueInfo")}>
                  {expandedSection === "issueInfo" ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Typography>
                <Collapse in={expandedSection === "issueInfo"} timeout="auto" unmountOnExit>
                  <div style={{ padding: '16px', borderRadius: '4px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="bold">CEC Instruction:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Service Delivery Instruction"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">SLA Committed Number:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["SLA Committed Number"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">External Reference Number:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["External Reference Number"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Technician Skill:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Proficiency"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Technician ID:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Tech Assignment"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Level:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Service Level"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Actual Service Type:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Service Type"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Order Type:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Order Type"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Lenovo Diagnostic Result:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Lenovo Diagnostic Result"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Work Order Status:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Status"] || 'N/A'} </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Labor Vendor SAP ID:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Labor Vendor SAP ID"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Accidental Damage Protection:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Accidental Damage Protection"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Program:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Program"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Technician Compatibility Result:</Typography>
                        <Typography variant="body2" color="text.secondary"> {ticket["Technician Compatibility Result"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">WO Priority:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Priority"] || 'N/A'} </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">WO Created Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Release Date"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Product's Previous Repair History:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Product's Previous Repair History"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Diagnostic Reason :</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Diagnostic Reason"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Problem Description :</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Problem Description"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="bold">Repair Notes : </Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Completion Date"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="bold">Diagnostic Notes : </Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Diagnostic Notes"] || 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Collapse>
              </div>

              {/* <div id="products" style={{ padding: '2px', backgroundColor: '#f9f9f9',borderRadius: '6px'}}>
                <Typography variant="subtitle2" sx={{padding:.8, ml:2, fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                <IconButton size="small"sx={{backgroundColor: '#FFDAB9',borderRadius: '50%',padding: '4px',marginRight: 1, }}>
                    <ShoppingCart sx={{ color: '#fff' }} /> 
                </IconButton>
                  Products
                  <IconButton size="small" aria-label="expand"onClick={() => toggleSection("products")}>
                  {expandedSection === "products" ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Typography>
                  <Collapse in={expandedSection === "products"} timeout="auto" unmountOnExit>
                    <div style={{ padding: '16px', borderRadius: '4px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Line Order:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Line Order"] || "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Item Line:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Item Line"] || "N/A"} </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Commodity ID:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Commodity ID"] || "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Commodity Group Name:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Commodity Group Name"] || "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Part Number:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Part Number"] || "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Product Description:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Product Description"] || "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Typography variant="subtitle2" fontWeight="bold">Accounting Indicator:</Typography>
                          <Typography variant="body2" color="text.secondary">{ticket["Accounting Indicator"] || "N/A"}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                </Collapse>
              </div>

              <div id="services" style={{ padding: '2px', backgroundColor: '#f9f9f9',borderRadius: '6px'}}>
                <Typography variant="subtitle2" sx={{padding:.8, ml:2, fontWeight: '500', display: 'flex', alignItems: 'center'}}>
                <IconButton size="small"sx={{backgroundColor: '#D8B7DD',borderRadius: '50%',padding: '4px',marginRight: 1, }}>
                  <Build sx={{ color: '#fff' }} /> 
                </IconButton>
                  Services
                  <IconButton size="small" aria-label="expand"onClick={() => toggleSection("services")}>
                  {expandedSection === "services" ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Typography>
                <Collapse in={expandedSection === "services"} timeout="auto" unmountOnExit>
                  <div style={{ padding: '16px', borderRadius: '4px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Line Order:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Line Order"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">SAP Service:</Typography>
                        <Typography variant="body2" color="text.secondary"> {ticket["SAP Service"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Product Item Type:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Product Item Type"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Accounting Indicator:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Accounting Indicator"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Description:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Description"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Work Order Service Status:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Work Order Service Status"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Closing Code:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Closing Code"] || 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Collapse>
              </div> */}
              
              <div id="key-dates" style={{ padding: '2px', backgroundColor: '#f9f9f9',borderRadius: '6px'}}>
                <Typography variant="subtitle2" sx={{padding:.8, ml:2, fontWeight: '500', display: 'flex', alignItems: 'center'}}>
                <IconButton size="small"sx={{backgroundColor: '#ADD8E6',borderRadius: '50%',padding: '4px',marginRight: 1, }}>
                    <CalendarToday sx={{ color: '#fff' }} /> 
                </IconButton>
                  Key Dates
                  <IconButton size="small" aria-label="expand"onClick={() => toggleSection("keyDates")}>
                  {expandedSection === "keyDates" ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Typography>
                <Collapse in={expandedSection === "keyDates"} timeout="auto" unmountOnExit>
                  <div style={{ padding: '16px', borderRadius: '4px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Original Committed Onsite:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Original Committed Onsite Date"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Actual Committed Onsite Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["SLA Committed Date"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Technician Assigned Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Assign Ticket Date"] || 'N/A'} </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Committed Delivery Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Committed Delivery Date"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Customer Defer Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Customer Defer Date"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Completion Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Service Completion Date"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Cancellation Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Cancellation Date"] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Release Date:</Typography>
                        <Typography variant="body2" color="text.secondary">{ticket["Release Date"] || 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Collapse>
              </div>
           </Stack>

          {/* Location Details */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '20px', // Increased padding for a more spacious look
              backgroundColor: '#f9f9f9',
              borderRadius: '12px',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
              marginBottom: '24px',
              maxWidth: '100%',
              overflow: 'hidden',
              height: showMap ? 'auto' : 'min-content', // Dynamically adjust height
              marginTop:'20px'
            }}
          >
            {/* Location Details */}
            <div style={{ width: '35%', paddingRight: '24px' }}>
              <Typography
              variant="h6" fontWeight="bold" color="secondary" marginBottom={'20px'} gutterBottom>
                Location Details
              </Typography>
          
              <Grid container spacing={2}>
                {/* Initial Location */}
                {initialLocation && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight="bold" color="text.primary">Initial Location:</Typography>
                    <Typography variant="body2" color="text.secondary"><strong>Latitude:</strong> {initialLocation[0]}</Typography>
                    <Typography variant="body2" color="text.secondary"><strong>Longitude:</strong> {initialLocation[1]}</Typography>
                    <Typography variant="body2" color="text.secondary"><strong>Address:</strong> {initialAddress || "Address not available"}</Typography>
                  </Grid>
                )}
                {/* FSE Location */}
                {currentLocation && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight="bold" color="text.primary">FSE Current Location:</Typography>
                    <Typography variant="body2" color="text.secondary"><strong>Latitude:</strong> {currentLocation[0]}</Typography>
                    <Typography variant="body2" color="text.secondary"><strong>Longitude:</strong> {currentLocation[1]}</Typography>
                    <Typography variant="body2" color="text.secondary"><strong>Address:</strong> {fseAddress || "Address not available"}</Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          
            {/* Map - 60% width */}
            <div style={{ width: '65%' }}>
              {showMap ? (
                <div>
                  <MapContainer
                    center={mapCenter}
                    zoom={15}
                    style={{
                      height: '360px',
                      width: '100%',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {/* Draw a line between initial and current locations */}
                    {initialLocation && currentLocation && (
                      <Polyline
                        positions={[initialLocation, currentLocation]}
                        pathOptions={{
                          color: '#007bff', // Professional color
                          weight: 4,
                          dashArray: '7,5',
                          lineJoin: 'round',
                          opacity: 0.7,
                        }}
                      >
                        <Tooltip direction="center" offset={[0, -10]} className="custom-tooltip">
                          Distance: {calculateDistance(initialLocation, currentLocation)} km
                        </Tooltip>
                      </Polyline>
                    )}
          
                    {initialLocation && (
                      <Marker position={initialLocation} icon={initialLocationIcon}>
                        <Popup>
                          <div style={{ fontWeight: 'bold', color: '#007bff', fontSize: '14px' }}>
                            Initial Location
                          </div>
                          <div style={{ fontSize: '12px', lineHeight: '1.4', marginTop: '5px' }}>
                            <Typography variant="body2" style={{ marginBottom: '5px' }}>
                              <div><strong>Latitude:</strong> {initialLocation[0]}</div>
                              <div><strong>Longitude:</strong> {initialLocation[1]}</div>
                              <div><strong>Address:</strong> {initialAddress || "Address not available"}</div>
                            </Typography>
                          </div>
                        </Popup>
                      </Marker>
                    )}
                    {currentLocation && (
                      <Marker position={currentLocation} icon={markerIcon}>
                        <Popup>
                          <div style={{ fontWeight: 'bold', color: '#28a745', fontSize: '14px' }}>
                            FSE Current Location
                          </div>
                          <div style={{ fontSize: '12px', lineHeight: '1.4', marginTop: '5px' }}>
                            <Typography variant="body2" style={{ marginBottom: '5px' }}>
                              <div><strong>Latitude:</strong> {currentLocation[0]}</div>
                              <div><strong>Longitude:</strong> {currentLocation[1]}</div>
                              <div><strong>Address:</strong> {fseAddress || "Address not available"}</div>
                            </Typography>
                          </div>
                        </Popup>
                      </Marker>
                    )}
                  </MapContainer>
                </div>
              ) : (
                <Typography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  style={{
                    padding: '16px',
                    backgroundColor: '#fff8f8',
                    border: '1px solid #f5c2c7',
                    borderRadius: '12px',
                    color: '#d32f2f',
                  }}
                >
                  Locations not available
                </Typography>
              )}
            </div>
          </div>
      </Grid>

      {/* Ticket Progress Section - 30% width */}
      <Grid item xs={12} sm={4}>
        <Stack spacing={2.5} width="95%" sx={{ padding: 2, borderRadius: 2, backgroundColor: "#f9f9f9",}}>
          <Typography variant="h6" fontWeight="400" gutterBottom>
          Case/WO Change Log
          <Divider sx={{ marginBottom: 1, backgroundColor: "#f9f9f9" }} />
          </Typography>

          <Box>
            {/* Order Progress */}
            <Grid container direction="column" spacing={3}>
              {filteredStatusLog
              .map((log, index) => (
                <Grid
                  item
                  key={index}
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ position: "relative", marginBottom: 2 }}
                >
                  {/* Line and Circle for Progress Tracking */}
                  <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
                    {/* Circle representing progress */}
                    <Box
                      sx={{
                        width: 33,
                        height: 33,
                        borderRadius: "50%",
                        backgroundColor: log.color,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        boxShadow: 2,
                        '@media (min-width: 600px)': {
                          width: 25,  // Smaller circle for small screens
                          height: 25,
                        },
                      }}
                    >
                      {log.icon}
                    </Box>

                    {/* Vertical line between the circles */}
                    {index < filteredStatusLog.length - 1 && (
                      <Box
                        sx={{
                          height: "7rem",
                          width: 4, 
                          backgroundColor: log.color,
                          position: "absolute",
                          top: "7.6rem",
                          left: "16%",
                          transform: "translateX(-50%)",
                          borderRadius: "2px",
                         '@media (max-width: 900px)': {
                              height: '7.5rem', 
                              top: "7.3rem",
                              left: "17%",
                            },
                            // display: { xs : "none", sm: "block" }, // Hide line on mobile view
                        }}
                      />
                    )}
                  </Grid>

                  {/* Status Card */}
                  <Grid item xs={10}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: 1,
                        backgroundColor: "#fff",
                        transition: "transform 0.3s ease-in-out, background-color 0.3s",
                        minHeight: 70, // Ensures consistent height
                        "&:hover": {
                          transform: "scale(1.05)",
                          backgroundColor: "#e0f7fa",
                        },
                      }}
                    >
                      {/* Status Text */}
                      <Box>
                        <Typography variant="body2" fontWeight="500" color="text.primary" sx={{ marginBottom: 1 }}>
                          <strong>Status: </strong> {log.status}
                        </Typography>
                        <Typography variant="caption" sx={{ display: "block", minHeight: 16, color: "Skyblue" }}>
                          {
                            log.date ? (
                              <>
                                {/* Handle 'Accepted' and 'Fixed' statuses */}
                                {["Accepted", "Fixed"].includes(log.status) ? (
                                  <>
                                    Date: {new Date(log.date).toLocaleDateString()} <br />
                                    Time: {new Date(log.date).toLocaleTimeString()}
                                  </>
                                ) : log.status === "Technician Assigned" ? (
                                  // Handle 'Technician Assigned' status with custom parser
                                  (() => {
                                    const parsedDate = parseCustomDate(log.date);
                                    if (parsedDate && parsedDate instanceof Date && !isNaN(parsedDate.getTime())) {
                                      // Format the date in MM-DD-YY
                                      const formattedDate = `${(parsedDate.getMonth() + 1).toString().padStart(2)}/${parsedDate.getDate().toString().padStart(2, "0")}/${parsedDate.getFullYear().toString().slice(-2)}`;
                                      
                                      // Format the time
                                      const formattedTime = parsedDate.toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                        hour12: true,
                                      });
                                    
                                      return (
                                        <>
                                          Date: {formattedDate} <br />
                                          Time: {formattedTime}
                                        </>
                                      );
                                    } else {
                                      return <>Date and Time: Not yet updated</>;
                                    }
                                    
                                  })()
                                ) : ["In-Route", "On Site", "Paused"].includes(log.status) ? (
                                  // Handle 'In-Route', 'On Site', 'Paused'
                                  <>
                                    Date: {log.date ? new Date(log.date).toLocaleDateString() : "Not yet updated"} <br />
                                    Time: {log.time ? log.time : "Not yet updated"}
                                  </>
                                ) : (
                                  <>Time: Not yet updated</>
                                )}
                              </>
                            ) : (
                              <>Not yet updated</>
                            )
                          }
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Grid>
    </Grid>
    </DialogContent>
  </Dialog>
  );
};

export default TicketDetails;
