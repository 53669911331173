import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogActions,   Card,
  CardContent,
  CardActions,DialogContent,Grid, DialogTitle, MenuItem, Select, InputLabel,   Divider,
  FormControl } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DoneIcon from '@mui/icons-material/Done';
import { API_BASE_URL } from '../services/apiService';// Import Material UI Icons
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import UpdateIcon from '@mui/icons-material/Update';
import { px } from 'framer-motion';
 
 
 
 
dayjs.extend(utc);
dayjs.extend(timezone);
 
 
const WorkOrderAssignment = ({ task }) => {
  const [currentTask, setCurrentTask] = useState(task);
  const [timer, setTimer] = useState(0); // Current session timer
  const [isRunning, setIsRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [pauseReason, setPauseReason] = useState(''); // Reason for pausing
  const [location, setLocation] = useState(null);
  const [initialLocation, setInitialLocation] = useState(null);
  const [lastLocationSent, setLastLocationSent] = useState(null);
  const [address, setAddress] = useState('');
  const [isLocationUpdated, setIsLocationUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [action, setAction] = useState('');
  const [initialAddress, setInitialAddress] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
    // For Completion Remark
    const [completionRemark, setCompletionRemark] = useState('');
    const [showCompletionRemarkInput, setShowCompletionRemarkInput] = useState(false);
    const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false); // Reschedule dialog visibility
    const [rescheduleDate, setRescheduleDate] = useState('');
    const [rescheduleNote, setRescheduleNote] = useState('');
    const [rescheduleReason, setRescheduleReason] = useState('');
    const [rescheduleTimeSlot, setRescheduleTimeSlot] = useState('');
 
    const [pauseReasonDialogOpen, setPauseReasonDialogOpen] = useState(false);
    const [completionRemarkDialogOpen, setCompletionRemarkDialogOpen] = useState(false);
 
    const [showRescheduleHistory, setShowRescheduleHistory] = useState(false); // Add this state
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
   
   
  const apiUrl = `${API_BASE_URL}/api/tasks`; // Your API base URL
 
 
  const calculateECT = (elapsedTimeInMillis) => {
    const hours = Math.floor(elapsedTimeInMillis / (1000 * 60 * 60)); // Convert milliseconds to hours
    const days = hours / 24; // Convert hours to days
    return days.toFixed(1); // Return the number of days with one decimal point
  };
 
  // Formatting function for dates and times
  const formatDateTime = (dateTime) => {
    return dayjs(dateTime).format('MM-DD-YYYY HH:mm '); // Format as DD-MM-YYYY HH:mm
  };
 
// Function for fetching and updating location and address during check-in
const fetchLocationAndAddress = async () => {
  try {
    if (!navigator.geolocation) {
      setErrorMessage('Geolocation is not supported by this browser.');
      return;
    }
 
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude }); // Update current location
 
        let addressData = '';
        try {
          // Fetch address from OpenStreetMap API
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          const data = await response.json();
          addressData = data?.display_name || 'Address not found';
          setAddress(addressData); // Update address for the current location
        } catch (error) {
          console.error('Error fetching address:', error);
          setErrorMessage('Failed to fetch address. Using default location.');
        }
 
        // Store initial location only once when check-in occurs
        if (!initialLocation) {
          console.log("Setting Initial Location:", { latitude, longitude, addressData });
          setInitialLocation({ latitude, longitude });
          setInitialAddress(addressData); // Set initial address
        }
 
        // Format the date and time for check-in
        const checkInDate = new Date().toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          timeZone: 'Asia/Kolkata',
        });
        const checkInTime = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
 
        const updatedTask = {
          ...currentTask,
          "initial Location": {
            latitude,
            longitude,
            address: addressData,  // Store address for initial location
          },
          "Status": "IN-Route",
          "Check-In Date": checkInDate,
          "Check-In Time": checkInTime,
        };
 
        // Update task in the database with the initial location
        try {
          console.log("Updating Task with Initial Location:", updatedTask);
          await updateTaskInDatabase(updatedTask);
          setCurrentTask(updatedTask);
          setSuccessMessage('Check-in location updated successfully!');
        } catch (error) {
          console.error('Error updating task:', error);
          setErrorMessage('Failed to update task in the database.');
        }
      },
      (error) => {
        console.error('Geolocation error:', error);
        setErrorMessage('Geolocation not allowed or unavailable.');
      }
    );
  } catch (error) {
    console.error('Error in fetchLocationAndAddress:', error);
    setErrorMessage('An unexpected error occurred.');
  }
};
 
const infoStyle = {
  display: 'inline-block',
  padding: '6px 14px',
  borderRadius: '12px',
  fontSize: '0.9rem',
  fontWeight: 600,
  marginBottom: '12px',
  marginRight: '20px',
  color: '#ffffff',
  textAlign: 'center',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
};
 
 
const timeSlots = [
  '9 AM - 11 AM',
  '11 AM - 1 PM',
  '1 PM - 3 PM',
  '3 PM - 5 PM',
];
 
const rescheduleReasons = [
  'Customer Appointment',
  'Customer Couldn’t Be Contacted',
  'Customer Reschedule',
  'Waiting for Confirmation from Customer',
  'Delay by Field Service Engineer',
  'Waiting for Lenovo Support',
  'Distance Location',
  'Pending Parts',
  'Catastrophe',
];
 
 
  const updateTaskInDatabase = async (updatedData) => {
    try {
      await fetch(`${apiUrl}/${currentTask["WO#"]}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
    } catch (error) {
      console.error('Error updating task in database:', error);
    }
  };
 
  const handleAcknowledge = async () => {
    if (!isRunning) {
      const istDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: 'Asia/Kolkata',
      });
      const istTime = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
 
      // Fetch current location and address
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
 
            let addressData = '';
            try {
              const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
              );
              const data = await response.json();
              addressData = data?.display_name || 'Address not found';
              setAddress(addressData);
            } catch (error) {
              console.error('Error fetching address:', error);
              setErrorMessage('Failed to fetch address. Using default location.');
            }
 
            // Update task with acknowledgment data for current location
            const updatedTask = {
              ...currentTask,
              "Current Location": {
                latitude,
                longitude,
                address: addressData, // Store address for current location
              },
              "Status": "Started",
              "Acknowledge Date": istDate,
              "Acknowledge Time": istTime,
            };
 
            setCurrentTask(updatedTask);
            setIsRunning(true);
            setAction('acknowledge');
 
            const start = Date.now() - timer;
            const id = setInterval(() => {
              setTimer(Date.now() - start);
            }, 1000);
            setIntervalId(id);
 
            localStorage.setItem('timerState', JSON.stringify({
              woNumber: updatedTask["WO#"],
              startTime: start,
              isRunning: true,
            }));
 
            try {
              await updateTaskInDatabase(updatedTask);
              setSuccessMessage('Acknowledgment location and task updated successfully!');
            } catch (error) {
              console.error('Error updating task:', error);
              setErrorMessage('Failed to update task in the database.');
            }
          },
          (error) => {
            console.error('Geolocation error:', error);
            setErrorMessage('Geolocation not allowed or unavailable.');
          }
        );
      } else {
        setErrorMessage('Geolocation is not supported by this browser.');
      }
    }
  };
 
 
  const handlePause = async () => {
    if (isRunning) {
      clearInterval(intervalId); // Stop the timer
      setIsRunning(false); // Update running state
      setShowReasonInput(true); // Show the reason input modal
      localStorage.removeItem('timerState'); // Clear the local storage
 
      // Calculate the elapsed time up to the pause moment
      const elapsedTime = timer;
 
      // Get the current date and time
      const pauseDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: 'Asia/Kolkata',
      });
 
      const pauseTime = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
 
      // Prepare updated task data
      const updatedTask = {
        ...currentTask,
        Status: 'Paused',
        'Elapsed Time': elapsedTime,
        'Pause Date': pauseDate,
        'Pause Time': pauseTime,
      };
 
      try {
        // Update task in the database
        await updateTaskInDatabase(updatedTask);
        setCurrentTask(updatedTask); // Sync the local task state
      } catch (error) {
        console.error('Error updating task during pause:', error);
        setErrorMessage('Failed to update the task during pause.');
      }
    }
  };
 
  const handleSubmitPauseReason = async () => {
    if (pauseReason.trim()) {
      const updatedTask = {
        ...currentTask,
        'Pause Reason': pauseReason, // Add the pause reason to the task
      };
 
      try {
        await updateTaskInDatabase(updatedTask); // Update the database
        setCurrentTask(updatedTask); // Sync the local task state
        setShowReasonInput(false); // Hide the reason input
        setPauseReason(''); // Clear the reason input
        setSuccessMessage('Pause reason submitted successfully!');
      } catch (error) {
        console.error('Error submitting pause reason:', error);
        setErrorMessage('Failed to submit the pause reason.');
      }
    }
  };
 
 
  const handleResume = async () => {
    if (!isRunning) {
      const start = Date.now() - timer; // Calculate the new start time
      const id = setInterval(() => {
        setTimer(Date.now() - start); // Update the timer
      }, 1000);
 
      setIsRunning(true); // Update running state
      setIntervalId(id); // Save the interval ID
 
      // Prepare updated task data
      const updatedTask = {
        ...currentTask,
        Status: 'Resumed',
      };
 
      // Save timer state to localStorage
      localStorage.setItem('timerState', JSON.stringify({
        woNumber: currentTask["WO#"],
        startTime: start,
        isRunning: true,
      }));
 
      try {
        // Update task in the database
        await updateTaskInDatabase(updatedTask);
        setCurrentTask(updatedTask); // Sync the local task state
        setSuccessMessage('Task successfully resumed!');
      } catch (error) {
        console.error('Error updating task during resume:', error);
        setErrorMessage('Failed to update the task during resume.');
      }
    }
  };
 
 
  const handleComplete = () => {
    if (isRunning) {
      clearInterval(intervalId);
      setIsRunning(false);
    }
 
    const finalElapsedTime = timer;
    const ect = calculateECT(timer);
 
    const istDate = new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      timeZone: 'Asia/Kolkata',
    });
 
    const istDateTime = dayjs().tz('Asia/Kolkata').format('MM-DD-YYYY hh:mm A');// This formats the date and time in IST
 
    const istTime = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
 
    const updatedTask = {
      ...currentTask,
      "Status": `Fixed`,
      "Elapsed Time": finalElapsedTime,
      "Time Stamp": formatTime(finalElapsedTime),
      "ECT": ect,
      "Service Completion Date": istDateTime, // Use formatted date-time
      "Completion Time": istTime,
      "Completion Date":istDate,
    };
 
    updateTaskInDatabase(updatedTask);
    setCurrentTask(updatedTask);
 
    setTimer(finalElapsedTime);
    clearInterval(intervalId);
    localStorage.removeItem('timerState');
 
      // Show the completion remark input after clicking complete
      setShowCompletionRemarkInput(true);
  };
 
 
  const handleSubmitCompletionRemark = async () => {
    if (completionRemark.trim()) {
      const updatedTask = {
        ...currentTask,
        "Completion Remark": completionRemark,
      };
 
      try {
        await updateTaskInDatabase(updatedTask);
        setCurrentTask(updatedTask);
        setSuccessMessage('Completion remark updated successfully!');
        setShowCompletionRemarkInput(false);
        setCompletionRemark('');
      } catch (error) {
        setErrorMessage('Failed to update task with completion remark.');
      }
    }
  };
 
 
 
 
  const handleCheckIn = async () => {
    setAction('checkin');  // Set the action to "checkin" to display the initial location only
    // Fetch initial location only if it hasn't been set already
    if (!initialLocation) {
      fetchLocationAndAddress(); // Only if the initial location hasn't been set
    }
  };
 
 
 
  const handleReschedule = () => {
    setRescheduleDialogOpen(true); // Open the reschedule dialog
  };
 
 
  const handleSubmitReschedule = async () => {
    if (!rescheduleDate || !rescheduleTimeSlot || !rescheduleReason || !rescheduleNote) {
      setErrorMessage('All fields are mandatory.');
      return;
    }
 
    const istDateTime = dayjs().tz('Asia/Kolkata').format('MM-DD-YYYY hh:mm A'); // Current IST date and time
    const rescheduleDetails = currentTask.rescheduleDetails || [];
    const newRescheduleNumber = rescheduleDetails.length + 1;
 
    const ordinalSuffix = (num) => {
      if (num % 10 === 1 && num % 100 !== 11) return `${num}st`;
      if (num % 10 === 2 && num % 100 !== 12) return `${num}nd`;
      if (num % 10 === 3 && num % 100 !== 13) return `${num}rd`;
      return `${num}th`;
    };
 
    const newRescheduleEntry = {
      rescheduleNumber: newRescheduleNumber,
      rescheduleLabel: `${ordinalSuffix(newRescheduleNumber)} Reschedule`,
      date: rescheduleDate,
      timeSlot: rescheduleTimeSlot,
      reason: rescheduleReason,
      note: rescheduleNote,
      rescheduledOn: istDateTime,
    };
 
    const updatedTask = {
      ...currentTask,
      "Status": "Rescheduled", // Update status to Rescheduled
      "SLA Committed Date": rescheduleDate, // Update SLA Committed Date
      "Reschedule Reason": rescheduleReason,
      "Rescheduled On": istDateTime,
      timeSlot: rescheduleTimeSlot,
      rescheduleDetails: [...rescheduleDetails, newRescheduleEntry],
    };
 
    if (isRunning) {
      clearInterval(intervalId); // Stop the timer
      setTimer(timer); // Maintain elapsed time
      setIsRunning(false);
      localStorage.removeItem('timerState');
    }
 
    try {
      // Save the updated task to the database
      await updateTaskInDatabase(updatedTask);
 
      // Prepare the email payload
      const emailPayload = {
        WO: currentTask["WO#"], // Work Order number
        techAssignment: currentTask["Tech Assignment"],
        rescheduleReason,
        note: rescheduleNote,
        timeSlot: rescheduleTimeSlot,
        date: rescheduleDate,
        rescheduledOn: istDateTime,
        email: currentTask["Email"], // Customer email
      };
 
      // Send email notifications
      const emailResponse = await fetch(`${API_BASE_URL}/api/sendEmail`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailPayload),
      });
 
      if (!emailResponse.ok) {
        throw new Error('Failed to send email notifications.');
      }
 
      // Update local state
      setCurrentTask(updatedTask);
      setRescheduleDialogOpen(false); // Close the dialog
      setSuccessMessage(`Rescheduled successfully (${newRescheduleEntry.rescheduleLabel})!`);
    } catch (error) {
      console.error('Error updating task or sending emails:', error);
      setErrorMessage('Failed to reschedule or send notifications.');
    }
  };
 
 
  const handleCloseRescheduleDialog = () => {
    setRescheduleDialogOpen(false);
    setErrorMessage('');
  };
 
  const handleOpenPauseReasonDialog = () => {
    setPauseReasonDialogOpen(true);
  };
 
  const handleClosePauseReasonDialog = () => {
    setPauseReasonDialogOpen(false);
  };
 
  const handleOpenCompletionRemarkDialog = () => {
    setCompletionRemarkDialogOpen(true);
  };
 
  const handleCloseCompletionRemarkDialog = () => {
    setCompletionRemarkDialogOpen(false);
  };
 
 
  const handleCancelTask = async () => {
    const cancellationDate = new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      timeZone: 'Asia/Kolkata',
    });
    const cancellationDateTime = dayjs().tz('Asia/Kolkata').format('MM-DD-YYYY hh:mm A'); // Combined date and time in IST format
 
    const updatedTask = {
      ...currentTask,
      Status: 'Cancelled', // Update status to Cancelled
      'Cancellation Reason': cancelReason, // Save the cancellation reason
      'Cancellation DateTime': cancellationDateTime, // Combined cancellation date and time
 
    };
 
    try {
      await updateTaskInDatabase(updatedTask); // Save cancellation details to the database
 
   // Prepare the email payload
   const emailPayload = {
    WO: currentTask["WO#"], // Work Order number
    techAssignment: currentTask["Tech Assignment"],
    cancelReason,
    cancellationDateTime,
    email: currentTask["Email"], // Customer email
  };
 
  // Send email notifications
  const emailResponse = await fetch(`${API_BASE_URL}/api/sendCancellationEmail`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(emailPayload),
  });
 
  if (!emailResponse.ok) {
    throw new Error('Failed to send email notifications.');
  }
 
 
      setCurrentTask(updatedTask); // Update local state
      setShowCancelDialog(false); // Close the dialog
      setSuccessMessage('Task has been cancelled successfully!');
      setCancelReason(''); // Clear the reason field
    } catch (error) {
      console.error('Error cancelling task:', error);
      setErrorMessage('Failed to cancel the task.');
    }
  };
 
 
 
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
 
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
 
 
  useEffect(() => {
    const storedTimerState = localStorage.getItem('timerState');
    if (storedTimerState) {
      const { woNumber, startTime, isRunning } = JSON.parse(storedTimerState);
      if (currentTask["WO#"] === woNumber && isRunning) {
        const start = Date.now() - (Date.now() - startTime);
        const id = setInterval(() => {
          setTimer(Date.now() - start);
        }, 1000);
        setIntervalId(id);
        setIsRunning(true);
      }
    }
  }, [currentTask]);
 
  useEffect(() => {
    if (currentTask && currentTask["Elapsed Time"]) {
      setTimer(currentTask["Elapsed Time"]);
    }
    return () => clearInterval(intervalId);
  }, [currentTask, intervalId]);
 
  return (
    <Box padding={2}>
      <Typography variant="h5" gutterBottom>
        Work Order Assignment for {currentTask["WO#"]}
      </Typography>
 
 
<Typography
  sx={{
    ...infoStyle,
    backgroundColor: '#1e88e5', // Blue
  }}
>
  Elapsed Time: {formatTime(timer)}
</Typography>
 
<Typography
  sx={{
    ...infoStyle,
    backgroundColor: '#43a047', // Green
  }}
>
  Status: {currentTask['Status']}
</Typography>
 
<Typography
  sx={{
    ...infoStyle,
    backgroundColor: '#fb8c00', // Orange
  }}
>
  ECT: {currentTask['ECT'] ? `${currentTask['ECT']} days` : 'Not Available'}
</Typography>
 
 {/* Cancel Button */}
 <Button
    variant="contained"
    color="error"
    onClick={() => setShowCancelDialog(true)}
    disabled={
      currentTask["Status"] === "Cancelled"} // Disable if cancelled
    sx={{
      position: 'absolute',
      right: 0,
      marginRight: '20px',
      fontWeight: 'bold',
      height: '40px',
    }}
  >
    Cancel
  </Button>
 
 
     
     {/* Professional Progress Bar */}
     <Box sx={{ width: '100%', mb: 3, position: 'relative', mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', width: '100%', top: '-20px' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 1 }}>Check-In</Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 1 }}>On-Site</Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 1 }}>Pause</Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 1 }}>Resume</Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 1 }}>Completion</Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', textAlign: 'center', flex: 1 }}>Reschedule</Typography>
 
          </Box>
          <Box
            sx={{
              height: '10px',
              backgroundColor: '#e0e0e0',
              borderRadius: '5px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: `${currentTask["Status"] === "Rescheduled" ? 100 :
                  currentTask["Status"] === "Fixed" ? 80 :
                  currentTask["Status"] === "Resumed" ? 60 :
                  currentTask["Status"] === "Paused" ? 40 :
                  currentTask["Status"] === "Started" ? 20 : 0}%`,
                background: `linear-gradient(to right,
                  ${currentTask["Status"] === "Rescheduled" ? '#7b1fa2' :
                  currentTask["Status"] === "Fixed" ? '#d32f2f' :
                  currentTask["Status"] === "Resumed" ? '#0288d1' :
                  currentTask["Status"] === "Paused" ? '#f57c00' :
                  currentTask["Status"] === "Started" ? '#388e3c' : '#1976d2'},
                  #ffffff)`,
                transition: 'width 0.5s ease',
              }}
            ></Box>
          </Box>
        </Box>
 
 
 
<Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'nowrap', overflowX: 'auto', padding: '10px' }}>
       
 
        {/* Check-In Card */}
        <Card
          sx={{
            minWidth: '200px',
            maxWidth: '220px',
            boxShadow: 3,
            borderRadius: '10px',
            margin: '0 8px',
            backgroundColor: currentTask["Status"] === "Pending" ? '#1976d2' : '#f8f9fa',
            color: currentTask["Status"] === "Pending" ? '#ffffff' : '#000000',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Check-In</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2">Check-In Date: {currentTask["Check-In Date"] ? dayjs(currentTask["Check-In Date"]).tz('Asia/Kolkata').format('MM-DD-YYYY') : 'Not Available'}</Typography>
            <Typography variant="body2">Check-In Time: {currentTask["Check-In Time"] || 'Not Available'}</Typography>
            <Typography variant="body2">Initial Location: {currentTask["initial Location"]?.address || 'Not Available'}</Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
          <Button
  variant="contained"
  startIcon={<CheckCircleIcon />}
  onClick={handleCheckIn}
  disabled={
    currentTask["Status"] === "Cancelled" || // Disable if cancelled
    currentTask["Status"] === "Fixed" ||
    currentTask["Status"] === "IN-Route" ||
    currentTask["Status"] === "Started" ||
    currentTask["Status"] === "Paused" ||
    currentTask["Status"] === "Resumed"
  }
  sx={{ backgroundColor: '#115293', '&:hover': { backgroundColor: '#0d3d6b' } }}
>
  Check-In
</Button>
 
          </CardActions>
        </Card>
 
        {/* On-Site Card */}
        <Card
          sx={{
            minWidth: '200px',
            maxWidth: '220px',
            boxShadow: 3,
            borderRadius: '10px',
            margin: '0 8px',
            backgroundColor: currentTask["Status"] === "Started" ? '#388e3c' : '#f8f9fa',
            color: currentTask["Status"] === "Started" ? '#ffffff' : '#000000',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>On-Site</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2">Acknowledge Date: {currentTask["Acknowledge Date"] ? dayjs(currentTask["Acknowledge Date"]).tz('Asia/Kolkata').format('MM-DD-YYYY') : 'Not Available'}</Typography>
            <Typography variant="body2">Acknowledge Time: {currentTask["Acknowledge Time"] || 'Not Available'}</Typography>
            <Typography variant="body2">Current Location: {currentTask["Current Location"]?.address || 'Not Available'}</Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
            <Button
              variant="contained"
              startIcon={<CheckCircleIcon />}
              onClick={handleAcknowledge}
              disabled={currentTask["Status"] === "Cancelled" || // Disable if cancelled
                currentTask["Status"] === "Fixed" || currentTask["Status"] === "Started" || currentTask["Status"] === "Paused" || currentTask["Status"] === "Resumed" }
              sx={{ backgroundColor: '#2e7d32', '&:hover': { backgroundColor: '#1b5e20' } }}
            >
              On-Site
            </Button>
          </CardActions>
        </Card>
 
        {/* Pause and Resume Card */}
        <Card
          sx={{
            minWidth: '200px',
            maxWidth: '220px',
            boxShadow: 3,
            borderRadius: '10px',
            margin: '0 8px',
            backgroundColor: currentTask["Status"] === "Paused" ? '#f57c00' : '#f8f9fa',
            color: currentTask["Status"] === "Paused" ? '#ffffff' : '#000000',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Pause / Resume</Typography>
            <Divider sx={{ my: 1 }} />
            {currentTask["Status"] === "Paused" ? (
              <>
                <Typography variant="body2">Resumed From: {formatTime(timer)}</Typography>
              </>
            ) : (
              <>
                <Typography variant="body2">Pause Date: {currentTask["Pause Date"] ? dayjs(currentTask["Pause Date"]).tz('Asia/Kolkata').format('MM-DD-YYYY') : 'Not Available'}</Typography>
                <Typography variant="body2">Pause Time: {currentTask["Pause Time"] || 'Not Available'}</Typography>
                <Typography variant="body2">Pause Reason: {currentTask["Pause Reason"] || 'Not Available'}</Typography>
              </>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
  {currentTask["Status"] === "Paused" ? (
    <Button
      variant="contained"
      startIcon={<PlayCircleIcon />}
      onClick={handleResume}
      disabled={currentTask["Status"] === "Cancelled" || // Disable if cancelled
        // Disable if cancelled
        currentTask["Status"] !== "Paused"}
      sx={{ backgroundColor: '#0277bd', '&:hover': { backgroundColor: '#01579b' } }}
    >
      Resume
    </Button>
  ) : (
    <Button
      variant="contained"
      startIcon={<PauseCircleIcon />}
      onClick={handlePause}
      disabled={currentTask["Status"] === "Cancelled" || // Disable if cancelled
        currentTask["Status"] === "Fixed"}
      sx={{ backgroundColor: '#e65100', '&:hover': { backgroundColor: '#bf360c' } }}
    >
      Pause
    </Button>
  )}
</CardActions>
 
        </Card>
 
        {/* Complete Card */}
        <Card
          sx={{
            minWidth: '200px',
            maxWidth: '220px',
            boxShadow: 3,
            borderRadius: '10px',
            margin: '0 8px',
            backgroundColor: currentTask["Status"] === "Fixed" ? '#d32f2f' : '#f8f9fa',
            color: currentTask["Status"] === "Fixed" ? '#ffffff' : '#000000',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Completion</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2">Time Stamp: {currentTask["Time Stamp"] || 'Not Available'}</Typography>
            <Typography variant="body2">ECT: {currentTask["ECT"] ? `${currentTask["ECT"]} days` : 'Not Available'}</Typography>
            <Typography variant="body2">Completion Date: {currentTask["Completion Date"] ? dayjs(currentTask["Completion Date"]).tz('Asia/Kolkata').format('MM-DD-YYYY') : 'Not Available'}</Typography>
            <Typography variant="body2">Completion Time: {currentTask["Completion Time"] || 'Not Available'}</Typography>
            <Typography variant="body2">Status: {currentTask["Status"]}</Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
            <Button
              variant="contained"
              startIcon={<DoneIcon />}
              onClick={handleComplete}
              disabled={currentTask["Status"] === "Cancelled" || // Disable if cancelled
                currentTask["Status"] === "Fixed"}
              sx={{ backgroundColor: '#c62828', '&:hover': { backgroundColor: '#b71c1c' } }}
            >
              Complete
            </Button>
          </CardActions>
        </Card>
 
        {/* Reschedule Card */}
        <Card
          sx={{
            minWidth: '200px',
            maxWidth: '220px',
            boxShadow: 3,
            borderRadius: '10px',
            margin: '0 8px',
            backgroundColor: currentTask["Status"] === "Rescheduled" ? '#7b1fa2' : '#f8f9fa',
            color: currentTask["Status"] === "Rescheduled" ? '#ffffff' : '#000000',
          }}
        >
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Reschedule</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body2">SLA Committed Date: {currentTask["SLA Committed Date"] || 'Not Available'}</Typography>
            <Typography variant="body2">Reschedule Reason: {currentTask["Reschedule Reason"] || 'Not Available'}</Typography>
            <Typography variant="body2">Rescheduled On: {currentTask["Rescheduled On"] || 'Not Available'}</Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
            <Button
              variant="contained"
              startIcon={<UpdateIcon />}
              onClick={handleReschedule}
              disabled={currentTask["Status"] === "Cancelled" || // Disable if cancelled
                currentTask["Status"] === "Fixed"}
              sx={{ backgroundColor: '#6a1b9a', '&:hover': { backgroundColor: '#4a148c' } }}
            >
              Reschedule
            </Button>
          </CardActions>
        </Card>
        </Grid>
 
 
   
        {/* Completion Remark Dialog */}
        <Dialog open={showCompletionRemarkInput} onClose={() => setShowCompletionRemarkInput(false)}>
          <DialogTitle>Completion Remark</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={completionRemark}
              onChange={(e) => setCompletionRemark(e.target.value)}
              variant="outlined"
              placeholder="Enter the completion remark"
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowCompletionRemarkInput(false)}
              color="secondary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitCompletionRemark}
              color="primary"
              variant="contained"
              disabled={!completionRemark.trim()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
 
        {/* Pause Reason Dialog */}
        <Dialog open={showReasonInput} onClose={() => setShowReasonInput(false)}>
          <DialogTitle>Reason for Pausing</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={pauseReason}
              onChange={(e) => setPauseReason(e.target.value)}
              variant="outlined"
              placeholder="Enter the reason for pausing the task"
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowReasonInput(false)}
              color="secondary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitPauseReason}
              color="primary"
              variant="contained"
              disabled={!pauseReason}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
 
        {/* Buttons to Trigger Popups */}
        {/* <Box mt={3} sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            onClick={() => setShowCompletionRemarkInput(true)}
            sx={{ backgroundColor: '#388e3c', '&:hover': { backgroundColor: '#2e7d32' } }}
          >
            Add Completion Remark
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowReasonInput(true)}
            sx={{ backgroundColor: '#f57c00', '&:hover': { backgroundColor: '#e65100' } }}
          >
            Add Pause Reason
          </Button>
        </Box> */}
 
       
{action === 'checkin' && initialLocation && (
          <Typography
            variant="subtitle1"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#1976d2',
              mt: 2,
              animation: 'fadeIn 1s',
            }}
          >
            🌍 Initial Location: Latitude {initialLocation.latitude}, Longitude {initialLocation.longitude}
          </Typography>
        )}
 
        {action === 'checkin' && initialLocation && address && (
          <Typography
            variant="subtitle1"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#388e3c',
              mt: 1,
              animation: 'fadeIn 1s',
            }}
          >
            📍 Address: {address}
          </Typography>
        )}
 
        {action === 'acknowledge' && location && (
          <Typography
            variant="subtitle1"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#0288d1',
              mt: 2,
              animation: 'fadeIn 1s',
            }}
          >
            🌍 Current Location: Latitude {location.latitude}, Longitude {location.longitude}
          </Typography>
        )}
 
        {action === 'acknowledge' && address && (
          <Typography
            variant="subtitle1"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#7b1fa2',
              mt: 1,
              animation: 'fadeIn 1s',
            }}
          >
            📍 Address: {address}
          </Typography>
        )}
 
{errorMessage && (
          <Box
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              backgroundColor: '#f8d7da',
              color: '#721c24',
              border: '1px solid #f5c6cb',
              borderRadius: '8px',
              padding: '10px 20px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              zIndex: 1000,
              animation: 'fadeIn 0.5s',
            }}
          >
            ❌ {errorMessage}
          </Box>
        )}
 
        {successMessage && (
          <Box
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              backgroundColor: '#d4edda',
              color: '#155724',
              border: '1px solid #c3e6cb',
              borderRadius: '8px',
              padding: '10px 20px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              zIndex: 1000,
              animation: 'fadeIn 0.5s',
            }}
          >
            ✅ {successMessage}
          </Box>
        )}
 
        <style>{`
          @keyframes fadeIn {
            0% { opacity: 0; transform: translateY(10px); }
            100% { opacity: 1; transform: translateY(0); }
          }
        `}</style>
 
 
 
 
           
                 {/* Reschedule History Lifecycle (Collapsible and Vertical) */}
        <Box flex={1} sx={{ backgroundColor: '#f5f5f5', padding: 3 }}>
          <Typography
            variant="h6"
            gutterBottom
            onClick={() => setShowRescheduleHistory((prev) => !prev)}
            sx={{ cursor: 'pointer', userSelect: 'none', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            Reschedule History
            <Box
              sx={{
                width: '24px',
                height: '24px',
                backgroundColor: '#1976d2',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                fontSize: '16px',
                ml: 1,
              }}
            >
              {showRescheduleHistory ? '-' : '+'}
            </Box>
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {showRescheduleHistory && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, overflowY: 'auto', p: 2 }}>
              {currentTask.rescheduleDetails && currentTask.rescheduleDetails.length > 0 ? (
                currentTask.rescheduleDetails.map((entry, index) => (
                  <Box
                    key={index}
                    sx={{
                      padding: 2,
                      backgroundColor: '#ffffff',
                      borderRadius: '8px',
                      boxShadow: 2,
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      {entry.rescheduleLabel}
                    </Typography>
                    <Typography variant="body2">Date: {entry.date}</Typography>
                    <Typography variant="body2">Time Slot: {entry.timeSlot}</Typography>
                    <Typography variant="body2">Reason: {entry.reason}</Typography>
                    <Typography variant="body2">Note: {entry.note}</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      Rescheduled On: {entry.rescheduledOn}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No reschedule history available.
                </Typography>
              )}
            </Box>
          )}
        </Box>
 
 
 
{/* Cancellation Reason Dialog */}
<Dialog
  open={showCancelDialog}
  onClose={() => setShowCancelDialog(false)}
>
  <DialogTitle>Cancel Task</DialogTitle>
  <DialogContent>
    <Typography variant="body1" sx={{ mb: 2 }}>
      Please provide a reason for cancellation.
    </Typography>
    <TextField
      fullWidth
      multiline
      rows={4}
      value={cancelReason}
      onChange={(e) => setCancelReason(e.target.value)}
      placeholder="Enter the reason for cancellation"
      sx={{ mb: 2 }}
    />
  </DialogContent>
  <DialogActions>
    <Button
      onClick={() => setShowCancelDialog(false)}
      color="secondary"
      variant="outlined"
    >
      Cancel
    </Button>
    <Button
      onClick={handleCancelTask}
      color="primary"
      variant="contained"
      disabled={!cancelReason.trim()}
    >
      Submit
    </Button>
  </DialogActions>
</Dialog>
 
 
 
      {/* Reschedule Dialog */}
      <Dialog open={rescheduleDialogOpen} onClose={handleCloseRescheduleDialog}>
        <DialogTitle>Reschedule Task</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Reschedule Date"
            type="date"
            value={rescheduleDate}
            onChange={(e) => setRescheduleDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Time Slot</InputLabel>
            <Select value={rescheduleTimeSlot} onChange={(e) => setRescheduleTimeSlot(e.target.value)}>
              {timeSlots.map((slot, index) => (
                <MenuItem key={index} value={slot}>
                  {slot}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Reason</InputLabel>
            <Select value={rescheduleReason} onChange={(e) => setRescheduleReason(e.target.value)}>
              {rescheduleReasons.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Reschedule Note"
            multiline
            rows={4}
            value={rescheduleNote}
            onChange={(e) => setRescheduleNote(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRescheduleDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitReschedule}
            color="primary"
            disabled={!rescheduleDate || !rescheduleTimeSlot || !rescheduleReason || !rescheduleNote}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
 
 
     
 
 
 
    </Box>
  );
};
 
export default WorkOrderAssignment;
 
