import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Box,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Typography,CircularProgress,
  Grid,Radio,RadioGroup,FormControlLabel,FormLabel,TextField,Tooltip,FormControl,InputLabel,
  MenuItem,Select,Card,CardContent,TablePagination
  // TablePagination,
} from '@mui/material';
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../services/apiService';
import { styled } from '@mui/material/styles';
 
// Styled Components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '15px',
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
}));
 
const StyledTableRow = styled(TableRow)(({ theme, highlight }) => ({
  backgroundColor: highlight ? theme.palette.success.light : theme.palette.error.light,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));
 
const StyledCard = styled(Card)(({ theme }) => ({
  background: '#ffffff',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  borderRadius: '10px',
  padding: '10px',
  textAlign: 'center',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 18px rgba(0,0,0,0.2)',
  },
}));
 
const T3BDetails = () => {
  const [t3bData, setT3bData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [satisfactionFilter, setSatisfactionFilter] = useState('all');
  const [regionFilter, setRegionFilter] = useState('all');
  const [vendorFilter, setVendorFilter] = useState('all');
  const [postalCodeFilter, setPostalCodeFilter] = useState('all');
  const [StateFilter, setStateFilter] = useState('all');
  const [uniqueVendors, setUniqueVendors] = useState([]);
  const [uniquePudoCodes, setUniquePudoCodes] = useState([]);
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [uniquePostalCodes, setUniquePostalCodes] = useState([]);
  const [uniqueStates, setUniqueStates] = useState([]);
  const [pudoFilter, setPudoFilter] = useState('all');
  const [locationFilter, setLocationFilter] = useState('all');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [regionWiseAverage, setRegionWiseAverage] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dateFilterOption, setDateFilterOption] = useState('all');
  const [page, setPage] = useState(0); // Add page state
  const [rowsPerPage, setRowsPerPage] = useState(10); // Add rowsPerPage state
 
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchT3BData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/t3b-data`);
        setT3bData(response.data);
        calculateRegionWiseAverage(response.data);
        extractUniqueFilters(response.data);
      } catch (error) {
        setError('Error fetching T3B data');
        console.error('Error fetching T3B data:', error);
      } finally {
        setLoading(false);
      }
    };
 
    fetchT3BData();
  }, []);
 
  const calculateRegionWiseAverage = (data) => {
    const regionData = {};
 
    data.forEach((item) => {
      const region = item.Indian_Region;
      const satisfactionValue = parseFloat(item.Satisfaction_Score);
 
      if (!regionData[region]) {
        regionData[region] = { sum: 0, count: 0, total: 0 };
      }
 
      if (satisfactionValue >= 0) {
        if (satisfactionValue >= 8 && satisfactionValue <= 10) {
          regionData[region].sum += satisfactionValue;
        }
        regionData[region].total += satisfactionValue;
        regionData[region].count += 1;
      }
    });
 
    const averages = {};
    for (const region in regionData) {
      const { sum, count, total } = regionData[region];
      averages[region] = count > 0 ? ((sum / total) * 100).toFixed(2) : 0;
    }
 
    setRegionWiseAverage(averages);
  };
 
  const extractUniqueFilters = (data) => {
    const vendors = [...new Set(data.map((item) => item['Vendor Id']).filter(Boolean))];
    const postalCodes = [...new Set(data.map((item) => item['Customer Postal Code']).filter(Boolean))];
    const states = [...new Set(data.map((item) => item.State).filter(Boolean))];
    const pudoCodes = [...new Set(data.map((item) => item['PUDO ID']).filter(Boolean))];
    const locations = [...new Set(data.map((item) => item['State Location']).filter(Boolean))];
 
    setUniqueVendors(vendors);
    setUniquePostalCodes(postalCodes);
    setUniqueStates(states);
    setUniquePudoCodes(pudoCodes);
    setUniqueLocations(locations);
  };
 
  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
 
  const filteredData = t3bData
    .sort((a, b) => new Date(b.Date_Received) - new Date(a.Date_Received)) // Sort latest data first
    .filter((item) => {
      const region = item["Indian Region"] ? item["Indian Region"].toLowerCase() : '';
      const satisfactionOps = item.Satisfaction_Score || 0;
      const vendorId = item["Vendor Id" ]|| '';
      const postalCode = item['Customer Postal Code'] || '';
      const receivedDate = item.Date_Received ? new Date(item.Date_Received) : null;
      const pudoId = item["PUDO ID"] || '';
      const location = item["State Location"] ? item["State Location"].toLowerCase() : '';
 
      const matchesPostalCode = postalCodeFilter === 'all' || postalCode === postalCodeFilter;
      const matchesVendor = vendorFilter === 'all' || vendorId === vendorFilter;
      const matchesPudo = pudoFilter === 'all' || pudoId.toLowerCase() === pudoFilter.toLowerCase();
      const matchesRegion = regionFilter === 'all' || region.includes(regionFilter.toLowerCase());
      const matchesLocation = locationFilter === 'all' || location === locationFilter.toLowerCase();
      const matchesSatisfaction =
        satisfactionFilter === 'all' ||
        (satisfactionFilter === 'satisfied' && satisfactionOps >= 8) ||
        (satisfactionFilter === 'unsatisfied' && satisfactionOps <= 7);
 
    let matchesDateRange = true; // Default to true for date range filtering
 
    // Handle date filtering
    if (dateFilterOption !== 'all') {
      const currentDate = new Date();
 
      if (dateFilterOption === 'last_1_day') {
        matchesDateRange = receivedDate >= new Date(currentDate.setDate(currentDate.getDate() - 1));
      } else if (dateFilterOption === 'last_7_days') {
        matchesDateRange = receivedDate >= new Date(currentDate.setDate(currentDate.getDate() - 7));
      } else if (dateFilterOption === 'last_30_days') {
        matchesDateRange = receivedDate >= new Date(currentDate.setDate(currentDate.getDate() - 30));
      } else if (dateFilterOption === 'custom') {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        matchesDateRange =
          receivedDate >= startDateObj && receivedDate <= endDateObj;
      }
    }
 
      return matchesVendor && matchesPostalCode && matchesRegion &&matchesPudo && matchesSatisfaction &matchesRegion && matchesDateRange &&matchesLocation;
    });
 
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
 
  const onMenuClick = (menuItem) => {
    if (menuItem === 'Dashboard') {
      navigate('/admin');
    } else if (menuItem === 'Assign Task Order') {
      navigate('/assign-task-order');
    }
  };
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
 
  const displayedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
 
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', fontFamily: 'Roboto, sans-serif' }}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} onMenuClick={onMenuClick} />
 
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          paddingTop: '70px',
          marginLeft: '50px',
          transition: 'margin 0.3s ease-in-out',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mt:4, mb:3, ml:1 }}>
          T3B Details
        </Typography>
 
        {/* Filters Section */}
        <Grid container spacing={2} marginBottom={3}>
        <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select State</InputLabel>
              <Select
                value={StateFilter}
                onChange={(e) => setStateFilter(e.target.value)}
                label="Select State"
                sx={{minWidth: 200, backgroundColor: '#f5f5f5', borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' },}}>
                <MenuItem value="all">All States</MenuItem>
                {uniqueStates.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
 
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Location</InputLabel>
              <Select
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value)}
                label="Location"
                sx={{minWidth: 200, backgroundColor: '#f5f5f5', borderRadius: 1,'&:hover': { backgroundColor: '#e0e0e0' },}}>
                <MenuItem value="all">All Locations</MenuItem>
                {uniqueLocations.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
 
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Vendor</InputLabel>
              <Select
                value={vendorFilter}
                onChange={(e) => setVendorFilter(e.target.value)}
                label="Vendor"
                sx={{minWidth: 200, backgroundColor: '#f5f5f5', borderRadius: 1,'&:hover': { backgroundColor: '#e0e0e0' },}}>
                <MenuItem value="all">All Vendors</MenuItem>
                {uniqueVendors.map((vendor) => (
                  <MenuItem key={vendor} value={vendor}>
                    {vendor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
 
       
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel>PUDO Code</InputLabel>
              <Select
                value={pudoFilter}
                onChange={(e) => setPudoFilter(e.target.value)}
                label="PUDO Code"
                sx={{minWidth: 200, backgroundColor: '#f5f5f5', borderRadius: 1,'&:hover': { backgroundColor: '#e0e0e0' },}}>
                <MenuItem value="all">All PUDO Codes</MenuItem>
                {uniquePudoCodes.map((pudoCode) => (
                  <MenuItem key={pudoCode} value={pudoCode}>
                    {pudoCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
 
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Postal Code</InputLabel>
              <Select
                value={postalCodeFilter}
                onChange={(e) => setPostalCodeFilter(e.target.value)}
                label="Postal Code"
                sx={{minWidth: 200, backgroundColor: '#f5f5f5', borderRadius: 1,'&:hover': { backgroundColor: '#e0e0e0' },}}>
                <MenuItem value="all">All Postal Codes</MenuItem>
                {uniquePostalCodes.map((code) => (
                  <MenuItem key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
         
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Indian Region</InputLabel>
              <Select
                value={regionFilter}
                onChange={(e) => setRegionFilter(e.target.value)}
                label="Indian Region"
                sx={{
                  minWidth: 200,
                  backgroundColor: '#f5f5f5',
                  borderRadius: 1,
                  '&:hover': { backgroundColor: '#e0e0e0' },
                }} >
                <MenuItem value="all">All Regions</MenuItem>
                <MenuItem value="south">South</MenuItem>
                <MenuItem value="north">North</MenuItem>
                <MenuItem value="west">West</MenuItem>
              </Select>
            </FormControl>
          </Grid>
 
          {/* Date Range Filters */}
          <Grid item xs={12} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Date Range</InputLabel>
              <Select
                value={dateFilterOption}
                onChange={(e) => setDateFilterOption(e.target.value)}
                label="Date Range"
                sx={{
                  minWidth: 120,
                  backgroundColor: '#f5f5f5',
                  borderRadius: 1,
                  '&:hover': { backgroundColor: '#e0e0e0' },'& .MuiOutlinedInput-notchedOutline': {borderColor: '#ccc',},
                  '&:hover .MuiOutlinedInput-notchedOutline': {borderColor: '#888',},'&.Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: '#6200ea',},
                 
                }}>
                <MenuItem value="all">All Dates</MenuItem>
                <MenuItem value="last_1_day">Last 1 Day</MenuItem>
                <MenuItem value="last_7_days">Last 7 Days</MenuItem>
                <MenuItem value="last_30_days">Last 30 Days</MenuItem>
                <MenuItem value="custom">Custom Range</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* Custom Date Range Inputs */}
          {dateFilterOption === 'custom' && (
            <>
              <Grid item xs={12} md={2}>
                <TextField label="Start Date" type="date" variant="outlined" sx={{minWidth: 100, }} value={startDate} onChange={(e) => setStartDate(e.target.value)} InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField label="End Date" type="date" variant="outlined" sx={{minWidth: 100,mr:1}} value={endDate} onChange={(e) => setEndDate(e.target.value)} InputLabelProps={{ shrink: true }}/>
              </Grid>
            </>
          )}
        </Grid>
 
        <Grid item xs={12} md={3}sx={{mb:2}}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel sx={{ fontWeight: 'bold', color: '#333' }}>Satisfaction</FormLabel>
              <RadioGroup row value={satisfactionFilter} onChange={(e) => setSatisfactionFilter(e.target.value)} sx={{display: 'flex', alignItems: 'center', '& .MuiFormControlLabel-root':{marginRight: '15px'},}}>
                <FormControlLabel value="all" control={<Radio sx={{ color: '#6200ea', '&:hover': { backgroundColor: 'transparent' } }} />} label="All" sx={{ fontWeight: '500' }}/>
                <FormControlLabel value="satisfied" control={<Radio sx={{ color: '#4caf50', '&:hover': { backgroundColor: 'transparent' } }} />} label="Satisfied" sx={{ fontWeight: '500' }}/>
                <FormControlLabel value="unsatisfied" control={<Radio sx={{ color: '#f44336', '&:hover': { backgroundColor: 'transparent' } }} />} label="Unsatisfied" sx={{ fontWeight: '500' }}/>
              </RadioGroup>
            </FormControl>
        </Grid>
 
        {/* Indian Region-wise Averages */}
        <Typography variant="h5" gutterBottom>
          Region-wise Averages
        </Typography>
        <Grid container spacing={2} marginBottom={3}>
          {Object.entries(regionWiseAverage).map(([Indian_Region, average]) => (
            <Grid item xs={6} sm={4} md={3} key={Indian_Region}>
              <StyledCard>
                <CardContent>
                  <Typography variant="h6">{`Indian Region - ${Indian_Region}`}</Typography> {/* Update region dynamically here */}
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {average}%
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        {/* Data Table */}
        {filteredData.length === 0 ? (
          <Typography>No T3B data available.</Typography>
        ) : (
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    Case No
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    WO#
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    Customer Name
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    Overall Satisfaction Score
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    Overall Satisfaction Score (%)
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    Indian Region
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    City
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    Postal Code
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    Vendor ID
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff', backgroundColor: '#3f51b5' }}>
                    Comment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item, index) => {
                 const satisfactionPercentage =
                 item.Satisfaction_Score >= 8
                   ? '100%'
                   : item.Satisfaction_Score <= 7
                   ? '0%'
                   : 'N/A';
               
 
                  return (
                    <StyledTableRow key={index} highlight={item.Satisfaction_Score >= 8}>
                      <TableCell>{item["Case#"]}</TableCell>
                      <TableCell>{item["WO#"]}</TableCell>
                      <TableCell>{item["Customer Name"]}</TableCell>
                      <TableCell>{item.Satisfaction_Score}</TableCell>
                      <TableCell>{satisfactionPercentage}</TableCell>
                      <TableCell>{item["Indian Region"] }</TableCell>
                      <TableCell>{item.City || 'N/A'}</TableCell>
                      <TableCell>{item["Customer Postal Code"] || 'N/A'}</TableCell>
                      <TableCell>{item["Vendor Id"] || 'N/A'}</TableCell>
                      <TableCell>
                        <Tooltip title={item.Satisfaction_Comment || 'No Comment'} arrow>
                          <span>{item.Satisfaction_Comment || 'N/A'}</span>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Footer />
    </Box>
  );
};
 
export default T3BDetails;
 
